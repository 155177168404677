import "./computeIntegrationModal.css";

import React, { useContext, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
} from "reactstrap";

import { yesterday } from "@joyhub-integration/shared";
import { unexpectedError } from "../../../constants";
import { Integration } from "../../../services/integrationsService";
import { computeInsights } from "../../../services/overlordService";
import PlatformContext from "../../app/PlatformContext";
import { useSetAlert } from "../../common/alert/withAlertModal";
import UncontrolledModal from "../../common/modal/UncontrolledModal";

interface SyncIntegrationModalProps {
  integration: Integration;
  onClose: () => void;
}

type RecomputeType = "un" | "un-rewrite" | "cm";

const ComputeIntegrationModal: React.FC<SyncIntegrationModalProps> = ({
  integration,
  onClose,
}) => {
  const [serverError, setServerError] = useState<string>("");
  const [insights, setInsights] = useState("");
  const [days, setDays] = useState(1);
  const [weeks, setWeeks] = useState(0);
  const [months, setMonths] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const { platform } = useContext(PlatformContext);
  const [recompute, setRecompute] = useState<RecomputeType>(
    platform!.organization.configuration.commonInsights ? "cm" : "un",
  );
  const [yday] = useState(
    yesterday().toLocaleDateString("en-US", { weekday: "long" }),
  );
  const setAlert = useSetAlert();
  // Actually computeInsights just looks for \D+ to separate the \d+ IDs, but
  // no need to expose such horrors here.
  const insightsRegex = /^(?:|financial|\d+(,\s*\d+)*|\d+(\s+\d+)*)$/i;

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setSubmitting(true);
    computeInsights(
      platform!.organization.id,
      integration.id,
      platform!.person.id,
      !days && !weeks && !months,
      days,
      weeks,
      months,
      recompute === "un-rewrite",
      recompute === "cm",
      insights || undefined,
    )
      .then(() => {
        setAlert("Compute started.", true);
        onClose();
      })
      .catch((err) => {
        setSubmitting(false);
        const response = err.response;
        if (response && response.status === 400 && response.data.message) {
          setServerError(response.data.message);
        } else {
          setServerError(unexpectedError);
        }
      });
  }

  return (
    <UncontrolledModal size="lg" onClosed={onClose} onFormSubmit={onSubmit}>
      <ModalHeader toggle={onClose}>
        <div
          className="flex-row"
          style={{ alignItems: "center", justifyContent: "center" }}
        >
          Compute Insights – {integration.name}
        </div>
      </ModalHeader>
      <ModalBody>
        {serverError ? (
          <UncontrolledAlert color="danger">{serverError}</UncontrolledAlert>
        ) : null}
        <FormGroup>
          <Label>Compute these insight IDs:</Label>
          <Label style={{ float: "right", fontStyle: "italic" }}>
            empty (default) &rArr; all, "financial" &rArr; only financials
          </Label>
          <Input
            className="placeholder-right"
            type="text"
            value={insights}
            onChange={(e) => setInsights(e.target.value)}
            placeholder="… /or/ financial /or/ 1, 2, 3 …"
            invalid={!insightsRegex.test(insights)}
          />
        </FormGroup>
        <FormGroup>
          <Label>&hellip; for this system:</Label>
          <FormGroup style={{ marginLeft: 10 }} check key="recompute-un">
            <Label check>
              <Input
                id="radio-un"
                type="radio"
                checked={recompute === "un"}
                onChange={() => setRecompute("un")}
              />
              Legacy (safe)
            </Label>
          </FormGroup>
          <FormGroup
            style={{ marginLeft: 10 }}
            check
            key="recompute-un-rewrite"
          >
            <Label check>
              <Input
                id="radio-un-rewrite"
                type="radio"
                checked={recompute === "un-rewrite"}
                onChange={() => setRecompute("un-rewrite")}
              />
              Legacy (overwrite previously-computed values)
            </Label>
          </FormGroup>
          <FormGroup style={{ marginLeft: 10 }} check key="recompute-cm">
            <Label check>
              <Input
                id="radio-cm"
                type="radio"
                checked={recompute === "cm"}
                onChange={() => setRecompute("cm")}
              />
              Common model
            </Label>
          </FormGroup>
        </FormGroup>
        <FormGroup>
          <Label>&hellip; on these dates:</Label>
          <Label style={{ float: "right" }}>
            (combines all of the below; 0/0/0 &rArr; all time)
          </Label>
          <InputGroup>
            <InputGroupText>Daily, for the last</InputGroupText>
            <Input
              type="number"
              style={{ textAlign: "right" }}
              value={days}
              onChange={(e) => setDays(e.target.valueAsNumber)}
            />
            <InputGroupText>days</InputGroupText>
          </InputGroup>
          <InputGroup>
            <InputGroupText>Every {yday}, for the last</InputGroupText>
            <Input
              type="number"
              style={{ textAlign: "right" }}
              value={weeks}
              onChange={(e) => setWeeks(e.target.valueAsNumber)}
            />
            <InputGroupText>weeks</InputGroupText>
          </InputGroup>
          <InputGroup>
            <InputGroupText>The last day of each of the last</InputGroupText>
            <Input
              type="number"
              style={{ textAlign: "right" }}
              value={months}
              onChange={(e) => setMonths(e.target.valueAsNumber)}
            />
            <InputGroupText>months</InputGroupText>
          </InputGroup>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          disabled={submitting || !insightsRegex.test(insights)}
        >
          Submit
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default ComputeIntegrationModal;
