import "./actionBar.css";

import React from "react";
import { Button, ButtonGroup, ButtonProps } from "reactstrap";

import ButtonWithIcon, { ButtonWithIconProps } from "./ButtonWithIcon";

interface ActionBarProps {
  buttonProps?: Array<ButtonWithIconProps | React.ReactElement<unknown>>;
  rightButtonProps?: Array<ButtonWithIconProps>;
  middleButtonProps?: Array<ButtonProps>;
  style?: React.CSSProperties;
}

const ActionBar: React.FC<ActionBarProps> = ({
  buttonProps,
  middleButtonProps,
  rightButtonProps,
  style,
}) => (
  <div className="flex-row jh-action-bar" style={style}>
    <ButtonGroup>
      {buttonProps?.map((p, idx) =>
        React.isValidElement(p) ? (
          <React.Fragment key={idx}>{p}</React.Fragment>
        ) : (
          <ButtonWithIcon key={idx} {...p} />
        ),
      )}
    </ButtonGroup>
    <div>
      <span className="me-3">
        {middleButtonProps && (
          <ButtonGroup>
            {middleButtonProps.map((p, idx) => (
              <Button {...p} key={idx}>
                {p.label}
              </Button>
            ))}
          </ButtonGroup>
        )}
      </span>
      {rightButtonProps && (
        <ButtonGroup>
          {rightButtonProps.map((p, idx) => (
            <ButtonWithIcon key={idx} {...p} />
          ))}
        </ButtonGroup>
      )}
    </div>
  </div>
);

export default ActionBar;
