import {
  faPencilAlt,
  faPlus,
  faShare,
  faShareAlt,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  InsightEntity,
  ignoreCaseIncludesMatcher,
} from "@joyhub-integration/shared";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
} from "reactstrap";

import {
  deleteCustomInsight,
  getCustomInsight,
  getCustomInsights,
} from "../../../services/insightsService";
import PlatformContext from "../../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ActionBar from "../../common/button/ActionBar";
import { NonBreakingSpace } from "../../common/button/ButtonWithIcon";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";
import JhCrumbar from "../../navbar/JhCrumbar";
import DeleteModal from "../common/DeleteModal";
import AddEditDerivedInsightModal from "./AddEditDerivedInsightModal";
import AddEditManualInsightModal from "./AddEditManualInsightModal";

const InsightsAdminPage: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const [insights, setInsights] = useState<Array<InsightEntity>>();
  const [selected, setSelected] = useState<InsightEntity>();
  const [insightFilter, setInsightFilter] = useState("");
  const [modal, setModal] = useState<
    "derived" | "manual" | "edit" | "delete"
  >();
  const { platform, updatePlatform } = useContext(PlatformContext);
  const { organization } = platform!;

  const fetchInsights = useCallback(
    async function () {
      return getCustomInsights()
        .then((customInsights) => {
          setInsights(customInsights);
          updatePlatform({ customInsights });
        })
        .catch(onUnexpectedError);
    },
    [onUnexpectedError, updatePlatform],
  );

  useEffect(() => {
    fetchInsights().then(() => []);
  }, [fetchInsights]);

  const filteredInsights = useMemo(() => {
    const matcher = ignoreCaseIncludesMatcher(insightFilter);
    return insights?.filter((insight) => matcher(insight.name));
  }, [insights, insightFilter]);

  const onInsightSubmitted = (insight: InsightEntity) => {
    fetchInsights().then(() => {
      const verb = modal === "edit" ? "edited" : "added";
      setAlert(`Insight ${insight.name} ${verb}`, true);
      setSelected(undefined);
      setModal(undefined);
    });
  };

  const onInsightDeleted = (insight: InsightEntity) => {
    fetchInsights().then(() => {
      setAlert(`Insight ${insight.name} deleted`, true);
      setSelected(undefined);
      setModal(undefined);
    });
  };

  const buttonProps = [
    <UncontrolledDropdown>
      <DropdownToggle className="jh-btn-primary">
        <FontAwesomeIcon icon={faPlus} />
        {NonBreakingSpace}
        Add Insight
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => setModal("derived")}>
          Derived Insight
        </DropdownItem>
        <DropdownItem onClick={() => setModal("manual")}>
          Manual Insight
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>,
    {
      icon: faPencilAlt,
      disabled: !selected,
      onClick: () => setModal("edit"),
      tooltip: "Edit Insight",
    },
    {
      icon: faTrash,
      disabled: !selected,
      onClick: () => setModal("delete"),
      tooltip: "Delete Insight",
    },
  ];

  const tableCols: Array<KeyValue<InsightEntity>> = [
    {
      key: "identifier",
      title: "Identifier",
      sortValue: (i) => i.identifier,
      toValue: (i) => (
        <>
          {i.identifier}
          {!i.shared ? null : (
            <FontAwesomeIcon
              size="sm"
              className="text-muted ms-2"
              icon={
                i.organization_id === organization.id ? faShare : faShareAlt
              }
            />
          )}
        </>
      ),
    },
    {
      key: "name",
      title: "Name",
    },
    {
      key: "description",
      title: "Description",
      sortable: false,
    },
  ];

  return (
    <LoadilyFadily loaded={insights != null} className="jh-page-layout">
      <JhCrumbar
        primary="Administration"
        primaryPath="/admin"
        secondary="Manage Insights"
      >
        <Input
          className="w-auto ms-auto rounded-pill"
          size={36}
          type="text"
          placeholder="Filter insights"
          onChange={(e) => setInsightFilter(e.target.value)}
        />
      </JhCrumbar>
      <div className="jh-page-content users-admin-page admin-page">
        <ActionBar buttonProps={buttonProps} />
        <TableWithSelection<InsightEntity>
          selected={selected}
          onSelectedChange={(selected) => setSelected(selected)}
          columns={tableCols}
          sortColumn="name"
          rows={filteredInsights}
        />
        {modal === "derived" || (modal === "edit" && !selected?.manual) ? (
          <AddEditDerivedInsightModal
            insights={insights}
            insight={modal === "edit" ? selected : undefined}
            onSubmit={onInsightSubmitted}
            onClose={() => setModal(undefined)}
          />
        ) : modal === "manual" || modal === "edit" ? (
          <AddEditManualInsightModal
            insights={insights}
            insight={modal === "edit" ? selected : undefined}
            onSubmit={onInsightSubmitted}
            onClose={() => setModal(undefined)}
          />
        ) : modal === "delete" && selected ? (
          <DeleteModal
            id={selected.id}
            entityName="Insight"
            identificationKey="name"
            getEntity={getCustomInsight}
            deleteEntity={deleteCustomInsight}
            onClose={() => setModal(undefined)}
            onSubmit={onInsightDeleted}
          />
        ) : null}
      </div>
    </LoadilyFadily>
  );
};

export default withAlertModal(InsightsAdminPage);
