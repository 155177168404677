import "./addInsightDataModal.css";

import {
  faArrowLeft,
  faCheckCircle,
  faExclamationTriangle,
  faFileExcel,
  faHandPaper,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CustomInsightOffset,
  IngestInsightDataResult,
  InsightsSelection,
  PropertiesSelection,
  asPropertyIds,
  isInsightsByIds,
  isPropertiesByIdOrIds,
  singularPlural,
} from "@joyhub-integration/shared";
import axios from "axios";
import clsx from "clsx";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import {
  Alert,
  Button,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";

import { prepareUpload } from "../../services/uploadService";
import {
  apiUrl,
  axiosBlobConfig,
  axiosConfig,
  errorMessage,
} from "../../utils/api";
import { downloadAttachment } from "../../utils/download";
import PlatformContext from "../app/PlatformContext";
import ButtonWithIcon from "../common/button/ButtonWithIcon";
import FilteringPicker from "../common/FilteringPicker";
import UncontrolledModal from "../common/modal/UncontrolledModal";

interface AddInsightDataModalProps {
  selectedProperties: PropertiesSelection;
  selectedInsights: InsightsSelection;
  selectedDate: Date;
  handleClose: () => void;
  fetchAllData: () => Promise<void>;
}

const AddInsightDataModal: React.FC<AddInsightDataModalProps> = (props) => {
  const [generatingTemplate, setGeneratingTemplate] = useState(false);
  const [ingestResult, setIngestResult] =
    useState<IngestInsightDataResult | null>(null);
  const [selectedProperties, setSelectedProperties] = useState(
    new Array<number>(),
  );
  const [selectedInsights, setSelectedInsights] = useState(new Array<number>());

  // initialize the generate-template picker selections so they are pre-selected
  // to what you already selected on the InsightDataScreen
  useEffect(() => {
    setSelectedProperties(
      isPropertiesByIdOrIds(props.selectedProperties)
        ? asPropertyIds(props.selectedProperties)
        : [],
    );
    setSelectedInsights(
      isInsightsByIds(props.selectedInsights) ? props.selectedInsights.ids : [],
    );
  }, [props.selectedProperties, props.selectedInsights]);

  return (
    <UncontrolledModal onClosed={props.handleClose}>
      {generatingTemplate ? (
        <GenerateTemplate
          handleClose={props.handleClose}
          setGeneratingTemplate={setGeneratingTemplate}
          selectedProperties={selectedProperties}
          setSelectedProperties={setSelectedProperties}
          selectedInsights={selectedInsights}
          setSelectedInsights={setSelectedInsights}
          selectedDate={props.selectedDate}
        />
      ) : ingestResult ? (
        <UploadResult
          handleClose={props.handleClose}
          ingestResult={ingestResult}
          setIngestResult={setIngestResult}
        />
      ) : (
        <UploadData
          handleClose={props.handleClose}
          fetchAllData={props.fetchAllData}
          setGeneratingTemplate={setGeneratingTemplate}
          setIngestResult={setIngestResult}
        />
      )}
    </UncontrolledModal>
  );
};

const generateTemplate = async (
  propertyIds: number[],
  insightIds: number[],
  date: Date,
) => {
  const data = { propertyIds, insightIds, date };
  const res = await axios.post(apiUrl("/data/template"), data, axiosBlobConfig);
  return downloadAttachment("Insight Data Template")(res);
};

interface GenerateTemplateProps {
  handleClose: () => void;
  setGeneratingTemplate: (generatingTemplate: boolean) => void;
  selectedProperties: number[];
  setSelectedProperties: (selectedProperties: number[]) => void;
  selectedInsights: number[];
  setSelectedInsights: (selectedInsights: number[]) => void;
  selectedDate: Date;
}

const GenerateTemplate: React.FC<GenerateTemplateProps> = (props) => {
  const platform = useContext(PlatformContext).platform!;
  const allProperties = useMemo(
    () => Object.values(platform.propertiesMap),
    [platform],
  );
  const allInsights = useMemo(
    () => Object.values(platform.insightsMap),
    [platform],
  );

  const mergedInsights = useMemo(() => {
    return allInsights
      .filter((i) => !i.common)
      .map(({ id, name }) => ({ id, name }))
      .concat(
        platform.customInsights
          .filter((i) => i.manual)
          .map(({ id, name }) => ({ id: id + CustomInsightOffset, name })),
      );
  }, [allInsights, platform]);

  const submitDisabled =
    !props.selectedProperties.length || !props.selectedInsights.length;

  const [serverError, setServerError] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (submitDisabled) return;
    setServerError("");
    try {
      await generateTemplate(
        props.selectedProperties,
        props.selectedInsights,
        props.selectedDate,
      );
      props.setGeneratingTemplate(false);
    } catch (err) {
      setServerError(errorMessage(err));
    }
  };

  return (
    <>
      <ModalHeader>
        <ButtonWithIcon
          icon={faArrowLeft}
          tooltip="Return to upload"
          color="link"
          className="py-0 px-2 me-2"
          onClick={() => props.setGeneratingTemplate(false)}
        />
        Generate Sample Input File
      </ModalHeader>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <ModalBody>
          <Alert color="danger" isOpen={!!serverError}>
            {serverError}
          </Alert>

          <p>
            Choose the properties and insights for which you want to add data.
            Open the template in Excel and enter data. Return here to upload the
            template.
          </p>

          <div className="d-flex" style={{ gap: "1rem" }}>
            <div className="w-50">
              <p>Properties</p>
              <FilteringPicker
                options={allProperties}
                label="property_name"
                selection={props.selectedProperties}
                setSelection={props.setSelectedProperties}
                maxHeight="30vh"
              />
            </div>
            <div className="w-50">
              <p>Insights</p>
              <FilteringPicker
                options={mergedInsights}
                label="name"
                selection={props.selectedInsights}
                setSelection={props.setSelectedInsights}
                maxHeight="30vh"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="align-items-start">
          <Button color="secondary" onClick={props.handleClose}>
            Cancel
          </Button>
          <Button type="submit" color="primary" disabled={submitDisabled}>
            Download Template
          </Button>
        </ModalFooter>
      </Form>
    </>
  );
};

interface UploadTemplateProps {
  handleClose: () => void;
  setGeneratingTemplate: (generatingTemplate: boolean) => void;
  setIngestResult: (result: IngestInsightDataResult) => void;
  fetchAllData: () => Promise<void>;
}

const UploadData: React.FC<UploadTemplateProps> = (props) => {
  const [uploading, setUploading] = useState(false);
  const [serverError, setServerError] = useState("");

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setServerError("");
      setUploading(true);
      if (acceptedFiles.length) {
        try {
          const file = acceptedFiles[0];
          const { key, filename } = await prepareUpload(file);

          const { data: result } = await axios.post<IngestInsightDataResult>(
            apiUrl("/data/import"),
            { key, filename },
            axiosConfig,
          );

          if (result.importedRows) {
            await props.fetchAllData();
          }

          props.setIngestResult(result);
        } catch (err) {
          setServerError(errorMessage(err));
        } finally {
          setUploading(false);
        }
      }
    },
    [props],
  );
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    disabled: uploading,
    maxFiles: 1,
    multiple: false,
  });

  return (
    <>
      <ModalHeader>Add Insight Data</ModalHeader>
      <ModalBody>
        <Alert color="danger" isOpen={!!serverError}>
          {serverError}
        </Alert>
        <div
          {...getRootProps({
            className: clsx(
              "flex-col p-5 justify-content-center align-items-center generic-drop",
              {
                isDragWaiting: !isDragActive,
                isDragActive,
                isDragAccept,
                isDragReject,
              },
            ),
          })}
        >
          <input {...getInputProps()} />

          {uploading ? (
            <>
              <p>Processing</p>
              <Spinner />
            </>
          ) : (
            <>
              <p>Drag your Excel file here, or click to select.</p>
              <FontAwesomeIcon icon={faFileExcel} size="4x" />
            </>
          )}
        </div>
        <Button
          color="link"
          size="sm"
          onClick={() => props.setGeneratingTemplate(true)}
        >
          Generate Sample Input File
        </Button>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.handleClose}>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
};

interface UploadResultProps {
  handleClose: () => void;
  ingestResult: IngestInsightDataResult;
  setIngestResult: (result: IngestInsightDataResult | null) => void;
}

const countString = (count: number | Array<any>, noun: string, total: number) =>
  singularPlural(count, noun) + ` of ${total}`;

const UploadResult: React.FC<UploadResultProps> = (props) => {
  const { importedRows, skippedRows, allRows, errors } = props.ingestResult;

  return (
    <>
      <ModalHeader>Add Insight Data</ModalHeader>
      <ModalBody>
        {errors.length === 0 && skippedRows.length === 0 ? (
          <Alert color="success">
            <div className="d-flex align-items-center" style={{ gap: "1rem" }}>
              <FontAwesomeIcon icon={faCheckCircle} size="4x" />
              <p className="h5">
                Imported {countString(importedRows, "row", allRows)}
              </p>
            </div>
          </Alert>
        ) : errors.length === 0 ? (
          <>
            <Alert color="warning">
              <div
                className="d-flex align-items-center"
                style={{ gap: "1rem" }}
              >
                <FontAwesomeIcon icon={faExclamationTriangle} size="4x" />
                <div>
                  <p className="h5">
                    Imported {countString(importedRows, "row", allRows)}
                  </p>
                  But some rows were skipped.
                </div>
              </div>
            </Alert>
            {singularPlural(skippedRows, "Skipped Row")}
            <ul className="list-box">
              {skippedRows.map((skippedRow, i) => {
                return skippedRow.errors.map((error, j) => (
                  <li
                    key={i + "-" + j}
                  >{`row ${skippedRow.rowNumber}: ${error}`}</li>
                ));
              })}
            </ul>
          </>
        ) : (
          <>
            <Alert color="danger">
              <div
                className="d-flex align-items-center"
                style={{ gap: "1rem" }}
              >
                <FontAwesomeIcon icon={faHandPaper} size="4x" />
                <p className="h5">Imported {countString(0, "row", allRows)}</p>
              </div>
            </Alert>
            {singularPlural(errors, "Error")}
            <ul className="list-box">
              {errors.map((error, i) => (
                <li key={i}>{error}</li>
              ))}
            </ul>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={props.handleClose}>
          Close
        </Button>
      </ModalFooter>
    </>
  );
};

export default AddInsightDataModal;
