import "./properties.css";

import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GenericReportData, PureDate } from "@joyhub-integration/shared";
import { debounce } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import { Input } from "reactstrap";

import { Platform } from "../../services/platformService";
import {
  PropertiesTabType,
  Property,
  getProperties,
} from "../../services/propertiesService";
import Properties from "../admin/properties/Properties";
import PlatformContext from "../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";
import RegionalDirectorDashboard from "../communityDirector/RegionalDirectorDashboard";
import JhCrumbar from "../navbar/JhCrumbar";
import PropertiesPicker from "../picker/PropertiesPicker";
import { PropertiesTabBar } from "./PropertiesTabBar";

const PropertiesContainer: React.FC<WithAlertModalProps> = ({}) => {
  const platform = useContext(PlatformContext).platform as Platform;
  const { person, propertyGroups } = platform;
  const isAdmin = platform.admin;
  const isEditor = platform.organization_role === "Editor";
  const [propertyFilter, setPropertyFilter] = useState("");
  const [date, setDate] = useState<Date | null>(null);
  const [data, setData] = useState<GenericReportData<boolean>>();
  const [feProperties, setFeProperties] = useState<Property[]>([]);
  const [key, setKey] = useState<number>(0);

  const [activeTab, setActiveTab] = useState<PropertiesTabType>(
    (sessionStorage.getItem("activeTab") as PropertiesTabType) || "dashboard",
  );

  const handleTabClick = (tab: PropertiesTabType) => {
    setActiveTab(tab);
    sessionStorage.setItem("activeTab", tab);
  };

  useEffect(() => {
    getProperties().then((properties) => {
      setFeProperties(properties.filter((p) => p.front_end));
    });
  }, [activeTab]);

  const dashboardPickers = (
    <div style={{ marginLeft: "auto" }}>
      <div className="d-flex flex-row">
        <div className="pull-right">
          <PropertiesPicker
            allProperties={feProperties}
            propertyGroups={propertyGroups}
            className="me-2"
          />
          <DatePicker
            value={PureDate.of(data?.date) ?? date}
            onChange={setDate}
            clearIcon={null}
            calendarIcon={
              <FontAwesomeIcon icon={faCalendarAlt} className="me-1" />
            }
            className="jh-date-picker btn btn-light"
          />
        </div>
      </div>
    </div>
  );

  const debouncedSetPropertyFilter = debounce(
    (value) => setPropertyFilter(value),
    300,
  );

  return (
    <div className="jh-page-layout">
      <JhCrumbar
        primary="Properties"
        primaryPath="/properties"
        noShadow
      ></JhCrumbar>
      <div className="px-4 jh-crumbar-like reports-cluster scrolly-box-shadow">
        {isAdmin || isEditor ? (
          <div className="d-flex justify-content-between">
            <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
              <PropertiesTabBar
                activeTab={activeTab}
                handleTabClick={handleTabClick}
              />
              {activeTab === "dashboard" ? (
                dashboardPickers
              ) : activeTab === "manage" ? (
                <Input
                  className="w-auto ms-auto rounded-pill"
                  size={36}
                  type="text"
                  placeholder="Filter"
                  onChange={(e) => debouncedSetPropertyFilter(e.target.value)}
                  style={{ marginLeft: "auto" }}
                />
              ) : null}
            </div>
          </div>
        ) : (
          dashboardPickers
        )}
      </div>
      {(isAdmin || isEditor) && activeTab === "manage" && (
        <Properties key={key} propertyFilter={propertyFilter} setKey={setKey} />
      )}
      {activeTab === "dashboard" && (
        <RegionalDirectorDashboard
          key={key}
          person={person}
          date={date}
          data={data}
          setData={setData}
        />
      )}
    </div>
  );
};

export default withAlertModal(PropertiesContainer);
