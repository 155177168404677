import { faMagic } from "@fortawesome/pro-light-svg-icons";
import React, { useContext } from "react";
import { useNavigate } from "react-router";

import { uploadOrganizationLogo } from "../../../services/organizationService";
import {
  createOrganization,
  updateOrganization,
} from "../../../services/overlordService";
import { getPlatform } from "../../../services/platformService";
import { setSessionOrganization } from "../../../services/sessionOrganization";
import { setOrganization } from "../../../services/sessionService";
import {
  ApplicationType,
  BaseOrganization,
  NavbarTheme,
} from "../../../services/usersService";
import PlatformContext from "../../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import JhCrumbar from "../../navbar/JhCrumbar";
import OrganizationForm from "../organization/OrganizationForm";

const emptyOrganization: BaseOrganization = {
  name: "",
  domains: [],
  application: "BI",
  configuration: {},
  slack_channel: "",
  slack_enabled: false,
  hs_record_id: "",
};

const CreateOrganizationPage: React.FC<WithAlertModalProps> = ({
  onUnexpectedError,
}) => {
  const { setPlatform } = useContext(PlatformContext);
  const navigate = useNavigate();

  const onCreateDomain = (
    name: string,
    domains: string[],
    application: ApplicationType,
    navbarTheme: NavbarTheme,
    file: File | undefined,
    commonInsights: boolean | undefined,
    sesTls: boolean | undefined,
    slackEnabled: boolean | undefined,
    slackChannel: string | undefined,
  ) => {
    setSessionOrganization(null); // so i can make the call to switch
    return createOrganization(
      name,
      domains,
      application,
      navbarTheme,
      undefined,
      commonInsights,
      sesTls,
      slackEnabled,
      slackChannel,
    )
      .then((organization) =>
        file
          ? uploadOrganizationLogo(organization.id, file).then((logo) =>
              updateOrganization(
                organization.id,
                name,
                domains,
                application,
                navbarTheme,
                logo,
                commonInsights,
                sesTls,
                slackEnabled,
                slackChannel,
              ),
            )
          : organization,
      )
      .then((organization) => setOrganization(organization.id))
      .then(getPlatform)
      .then(setPlatform)
      .then(() => navigate("/"))
      .catch(onUnexpectedError);
  };

  return (
    <LoadilyFadily className="jh-page-layout">
      <JhCrumbar
        primary="Administration"
        primaryPath="/admin"
        secondary="Create Organization"
      />
      <div className="jh-page-content organization-admin-page admin-page">
        <div className="admin-section">
          <OrganizationForm
            label="Create Organization"
            icon={faMagic}
            organization={emptyOrganization}
            isCreate
            onSubmit={onCreateDomain}
          />
        </div>
      </div>
    </LoadilyFadily>
  );
};

export default withAlertModal(CreateOrganizationPage);
