import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { DashboardKindEnum, checkPermission } from "@joyhub-integration/shared";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { BooleanParam, useQueryParam } from "use-query-params";

import DeleteModal from "../../components/admin/common/DeleteModal";
import PlatformContext from "../../components/app/PlatformContext";
import dashboardService, {
  BaseDashboard,
  Dashboard,
} from "../../services/dashboardService";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";
import { useLoadilyFadily } from "../common/allFadily";
import ButtonWithIcon from "../common/button/ButtonWithIcon";
import JhCrumbar from "../navbar/JhCrumbar";
import AddEditDashboard from "./AddEditDashboard";
import DashboardCard from "./DashboardCard";

type DashboardScreenProps = {
  kind: DashboardKindEnum.Dashboard | DashboardKindEnum.PropertyDashboard;
};

const DashboardScreen: React.FC<DashboardScreenProps & WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
  kind,
}) => {
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const [addingNewDashboard, setAddingNewDashboard] = useState<boolean>(false);
  const [selected, setSelected] = useState<number | null>(null);
  const [hidden] = useQueryParam("hidden", BooleanParam);

  const [isEditingDashboard, setIsEditingDashboard] = useState<Boolean>(false);
  const [isDeleteDashboard, setIsDeleteDashboard] = useState<Boolean>(false);
  const [loaded, setLoaded] = useState(false);

  const { organization_role, superAdmin } =
    useContext(PlatformContext).platform!;

  // hide zendesk widget until we leave this page
  useEffect(() => {
    if (superAdmin) {
      window.zE?.("messenger:set", "zIndex", -5000);
      return () => {
        window.zE?.("messenger:set", "zIndex", 5000);
      };
    }
  }, [superAdmin]);

  const fetchMyDashboards = useCallback(
    async function fetchMyDashboards() {
      dashboardService
        .getDashboards(!!hidden, true, kind)
        .then((dashboards) => setDashboards(dashboards))
        .catch(onUnexpectedError);
    },
    [hidden, kind, onUnexpectedError],
  );

  useEffect(() => {
    fetchMyDashboards().then(() => {
      setLoaded(true);
    });
  }, [fetchMyDashboards]);

  async function afterDashboardDeleted(dashboard: Dashboard) {
    fetchMyDashboards().then(() => {
      setAlert(`Dashboard ${dashboard.name} deleted.`, true);
      setSelected(null);
      setIsDeleteDashboard(false);
    });
  }

  function setDashboardStatus() {
    setAddingNewDashboard(false);
    setIsEditingDashboard(false);
    setSelected(null);
  }

  function onEditClick(id: number) {
    setSelected(id);
    setIsEditingDashboard(true);
  }

  function onDeleteClick(id: number) {
    setSelected(id);
    setIsDeleteDashboard(true);
  }

  async function onAddEditDashboardSubmit(dashboard: BaseDashboard) {
    fetchMyDashboards()
      .then(() => {
        setAlert(
          `Dashboard '${dashboard.name}' ${
            isEditingDashboard ? "updated" : "created"
          }.`,
          true,
        );
        setDashboardStatus();
      })
      .catch(onUnexpectedError);
  }

  function onClosingAddEditLightbox() {
    setAddingNewDashboard(false);
    setIsEditingDashboard(false);
    setIsDeleteDashboard(false);
    setSelected(null);
  }

  const fadily = useLoadilyFadily(loaded);

  const doDeleteDashboard = (id: number, entity: Dashboard) =>
    entity.immutable
      ? dashboardService.hideDashboard(id, true)
      : dashboardService.deleteDashboard(id);

  const onUnhideClick = (id: number) =>
    dashboardService
      .hideDashboard(id, false)
      .then(fetchMyDashboards)
      .catch(onUnexpectedError);

  return (
    <div className="jh-page-layout">
      <JhCrumbar
        primary={
          kind === DashboardKindEnum.Dashboard
            ? "Portfolio Dashboards"
            : "Property Dashboards"
        }
      />
      <div className="jh-page-content content-width" style={fadily}>
        {checkPermission("dashboards", "create", organization_role) ? (
          <div className="floaty-buttons">
            <ButtonWithIcon
              className="jh-icon-with-text dashboard-add-insight-button actually-its-a-dashboard"
              icon={faPlus}
              color="primary"
              onClick={() => setAddingNewDashboard(true)}
              label={<span>New Dashboard</span>}
            />
          </div>
        ) : null}
        {isDeleteDashboard && selected ? (
          <DeleteModal<Dashboard>
            id={selected}
            entityName="Dashboard"
            identificationKey="name"
            getEntity={dashboardService.getDashboardById}
            deleteEntity={doDeleteDashboard}
            onClose={onClosingAddEditLightbox}
            onSubmit={afterDashboardDeleted}
          />
        ) : null}
        {addingNewDashboard || isEditingDashboard ? (
          <AddEditDashboard
            kind={kind}
            onSubmit={(dashboard) => onAddEditDashboardSubmit(dashboard)}
            onClose={onClosingAddEditLightbox}
            id={isEditingDashboard && selected ? selected : undefined}
          />
        ) : null}
        <div className="flex-row flex-wrap card-container">
          {dashboards.map((dashboard) => (
            <DashboardCard
              key={dashboard.id}
              dashboard={dashboard}
              onEdit={onEditClick}
              onDelete={onDeleteClick}
              onUnhide={onUnhideClick}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default withAlertModal(DashboardScreen);
