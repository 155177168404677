import { ReportSheetData } from "@joyhub-integration/shared";
import { Card, CardBody } from "reactstrap";

import { propertyDashboardUrl } from "../../services/dashboardService";
import Unicard from "../unicard/Unicard";
import { GenericReportTable } from "./GenericReportTable";

interface ReportContentProps {
  sheetData: ReportSheetData;
}

export const ReportContent = (props: ReportContentProps) => {
  const { sheetData } = props;

  return (
    <>
      {sheetData.graph && (
        <Card
          className="jh-chart-wrapper mb-4 border-0 position-sticky"
          style={{
            left: "calc(var(--sidebar-width) + 1.5rem)",
            width:
              "calc(100vw - 3rem - var(--sidebar-width) - var(--scrollbar-width))",
          }}
        >
          <CardBody style={{ height: 400 }}>
            <Unicard data={sheetData} actions style={{ overflow: "hidden" }} />
          </CardBody>
        </Card>
      )}
      <GenericReportTable
        data={sheetData}
        drillIn={(key) =>
          typeof key !== "number" ? undefined : propertyDashboardUrl(key)
        }
      />
    </>
  );
};
