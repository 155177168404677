import "./layout.css";

import { faLink } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sortBy } from "lodash";
import React, { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
} from "reactstrap";

import { setSessionOrganization } from "../../services/sessionOrganization";
import { setOrganization } from "../../services/sessionService";
import PlatformContext from "../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";
import OrgLogo from "./OrgLogo";

interface OrgSwitcherProps {
  onSwitchOrg?: () => void;
}

const OrgSwitcher: React.FC<WithAlertModalProps & OrgSwitcherProps> = ({
  onUnexpectedError,
  onSwitchOrg,
}) => {
  const { platform } = useContext(PlatformContext);
  const { organizations } = platform!;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const toggle = (e: any) => {
    setDropdownOpen((prevState) => !prevState);
    e.stopPropagation();
  };

  const pickOrganization = (id: number) => {
    if (onSwitchOrg) onSwitchOrg();
    setSessionOrganization(null); // so i can make the call to switch
    setOrganization(id)
      //.then(getPlatform)
      //.then(setPlatform)
      //.then(() => history.push("/")) // there's some state somewhere that causes problems...
      .then(() => (document.location.href = "/"))
      .catch(onUnexpectedError);
    setSearchQuery("");
  };

  const filteredOrgs = useMemo(
    () =>
      sortBy(organizations, "name").filter((o) =>
        o.name.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    [searchQuery, organizations],
  );

  return organizations.length === 1 ? (
    <div className="mx-3 org-logo mw-100">
      <Link to="/">
        <OrgLogo />
      </Link>
    </div>
  ) : (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} className="org-logo mw-100">
      <DropdownToggle caret color="transparent" className="p-0">
        <OrgLogo key={"dropdown-logo"} />
      </DropdownToggle>
      <DropdownMenu style={{ maxWidth: "20rem" }}>
        <div style={{ maxHeight: "calc(100vh - 10rem)", overflowY: "auto" }}>
          {dropdownOpen ? (
            <div className="w-100 d-flex flex-column align-items-stretch px-3 py-2 navbar-search">
              <Input
                autoFocus
                type="text"
                placeholder="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && filteredOrgs.length === 1)
                    pickOrganization(filteredOrgs[0].id);
                }}
              />
            </div>
          ) : null}
          {filteredOrgs.map((o) => (
            <DropdownItem
              key={o.id}
              onClick={() => pickOrganization(o.id)}
              className="text-truncate"
            >
              {o.application === "PC" && <FontAwesomeIcon icon={faLink} />}{" "}
              {o.name}
            </DropdownItem>
          ))}
          {filteredOrgs.length == 0 ? (
            <DropdownItem disabled key={"no-orgs"}>
              No organizations
            </DropdownItem>
          ) : null}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default withAlertModal(OrgSwitcher);
