import { groupBy, partition } from "lodash";

import { PropertiesSelection } from "../selection";
import { PureDateIO } from "../util";
import {
  AttachmentType,
  ColumnFormat,
  ColumnSort,
  GraphAxis,
  GraphType,
  ReportGrouping,
  ReportInsightVersus,
  ReportInterval,
  ReportTitle,
  RowType,
  SnapshotType,
} from "./reportDefinition";
import { Resolved } from "./reportInsight";

export type ReportConfig<Deserialised extends boolean = true> = {
  properties: PropertiesSelection;
  asOf?: string;
  notifyWhenGoal?: "met" | "failed" | "both";
  schedule?: string;
  editMode?: boolean;
  propertyId?: number;
  attachmentType?: AttachmentType;
  sheetNumber?: number;
};

export type ComparisonValue = {
  value: number;
  versus: number;
  comparison: number | undefined;
};

export const isComparisonValue = (
  value?: ReportDataValue,
): value is ComparisonValue => (value as any)?.versus != null;

export type PropertyAttribute = {
  attribute: string;
  image_url: string;
};

export const isPropertyValue = (
  value?: ReportDataValue,
): value is PropertyAttribute => (value as any)?.attribute !== undefined;

export type PrimitiveReportDataValue =
  | string
  | string[]
  | number
  | boolean
  | Date
  | null
  | undefined;

export type ReportDataValue =
  | PrimitiveReportDataValue
  | ComparisonValue
  | PropertyAttribute;

export type ReportDataRow = {
  type: RowType;
  key?: ReportDataValue;
  group?: string;
  primarySort?: number;
  values: ReportDataValue[];
  format?: ColumnFormat; // for pivot tables, override the column format...
};

export type ReportColumnType = "String" | "Date" | "Number" | "NoValue";

export type ReportDataColumn<Meta = any> = {
  type: ReportColumnType;
  header: string;
  attribute?: string;
  bucket?: string;
  subBucket?: string;
  width: number;
  format?: ColumnFormat;
  versus?: ReportInsightVersus<Resolved>;
  sort?: ColumnSort;
  dimension?: [string, string]; // the dimension value
  index: number; // the original report column index
  graphAxis?: GraphAxis;
  meta?: Meta; // in a financial sheet the account code, etc
  visible?: boolean; // don't collapse empty
  supercolumn?: number; // if this is an expanded supercolumn, the index of the property selection :vomit:
  showAverageLine?: boolean;
  updatedAtAxis?: number;
  display?: boolean;
};

export type ReportSheetType =
  | "Date"
  | "Dimension"
  | "Property"
  | "Lease"
  | "Finance"
  | "Unit";

export type ReportSheetData = {
  name: string;
  graph?: boolean;
  graphType?: GraphType;
  snapshotType?: SnapshotType;
  xAxisIndex?: any;
  type: ReportSheetType;
  titles: ReportTitle[];
  columns: ReportDataColumn[];
  rows: ReportDataRow[];
  rowInterval?: ReportInterval;
  pivot?: boolean;
  compact?: boolean;
  grouping?: ReportGrouping;
  error?: string;
};

export type ReportPropertyData = {
  name: string;
  code: string;
} & Record<string, any>; // this any is crap, but dereferencing property attributes gives so many choices

export type ReportOrganizationData = {
  name: string;
};

export type ReportContext<Deserialised extends boolean = false> = {
  date: PureDateIO<Deserialised>;
  property?: ReportPropertyData;
  properties: { property: ReportPropertyData }[];
  selection: string;
  organization: ReportOrganizationData;
};

export type GenericReportData<Deserialised extends boolean = false> = {
  date: PureDateIO<Deserialised>;
  context: ReportContext<Deserialised>;
  body?: string;
  templateData?: Record<string, any>;
  sheets: ReportSheetData[];
  personId: number;
};

/**
 * This follows a sort order where Normal (Ungrouped) rows are first, then Groups, then their Children, then Overall
 *
 * i.e.
 *  name: NormalProperty | type: Normal | group: null
 *  name: Annapolis | type: Group
 *    name: AGroupedProperty | type: Normal | group: Annapolis
 *    name: CGroupedProperty | type: Normal | group: Annapolis
 *  name: Boston | type: Group
 *    name: BGroupedProperty | type: Normal | group: Boston
 *  name: Overall | type:
 */
export const sortReportRows = (
  rows: ReportDataRow[],
  compareNormals: (r1: ReportDataRow, r2: ReportDataRow) => number,
  compareGroups: (r1: ReportDataRow, r2: ReportDataRow) => number,
  grouping?: ReportGrouping,
) => {
  const {
    Normal: normalRows = [],
    Group: groupRows = [],
    Overall: overallRows = [],
  } = groupBy(rows, "type");

  if (grouping?.layout !== "Footer") {
    const [regularNormalRows = [], groupedNormalRows = []] = partition(
      normalRows,
      (r) => r.group == null,
    );
    return [
      ...regularNormalRows.sort(compareNormals),
      ...groupRows
        .sort(compareGroups)
        .flatMap((g) => [
          g,
          ...groupedNormalRows
            .filter((r) => r.group === g.key)
            .sort(compareNormals),
        ]),
      ...overallRows,
    ];
  } else {
    //if footer, collect all group headers at the end
    return [
      ...normalRows.sort(compareNormals),
      ...groupRows.sort(compareGroups),
      ...overallRows,
    ];
  }
};
