export enum PropertyRegion {
  sw = "SouthWest",
  ne = "NorthEast",
  se = "SouthEast",
  w = "West",
  mw = "MidWest",
}

export enum PropertyClass {
  a = "Class A",
  b = "Class B",
  c = "Class C",
}

export enum PropertyType {
  mfr = "Multifamily",
  sfr = "Single Family Rental",
  townhome = "Townhome",
  senior = "Senior Housing",
  student = "Student Housing",
  affordable = "Affordable Housing",
}

export type NewPropertyDto = {
  property_name: string;
  property_type: PropertyType;
  address: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
  unit_count: number;
  is_comparable: boolean;
};
export enum PropertyColumnKey {
  id = "id",
  property_code = "property_code",
  property_name = "property_name",
  property_alias = "property_alias",
  is_comparable = "is_comparable",
  address = "address",
  city = "city",
  state = "state",
  latitude = "latitude",
  longitude = "longitude",
  zip_code = "zip_code",
  country = "country",
  pms_name = "pms_name",
  region = "region",
  msa = "msa",
  apn = "apn",
  fips = "fips",
  owner_name = "owner_name",
  contact_person_name = "contact_person_name",
  contact_person_email = "contact_person_email",
  market = "market",
  website = "website",
  phone = "phone",
  total_sqft = "total_sqft",
  rentable_sqft = "rentable_sqft",
  parcel_size = "parcel_size",
  year_built = "year_built",
  year_updated = "year_updated",
  capitalization_rate = "capitalization_rate",
  property_class = "property_class",
  property_type = "property_type",
  unit_count = "unit_count",
  floor_count = "floor_count",
  building_count = "building_count",
  elevator_count = "elevator_count",
  parking_count = "parking_count",
  commercial_unit_count = "commercial_unit_count",
  purchase_date = "purchase_date",
  purchase_price = "purchase_price",
  image_guid = "image_guid",
  user_bool_1 = "user_bool_1",
  user_bool_2 = "user_bool_2",
  user_bool_3 = "user_bool_3",
  user_bool_4 = "user_bool_4",
  user_date_1 = "user_date_1",
  user_date_2 = "user_date_2",
  user_date_3 = "user_date_3",
  user_date_4 = "user_date_4",
  user_decimal_1 = "user_decimal_1",
  user_decimal_2 = "user_decimal_2",
  user_decimal_3 = "user_decimal_3",
  user_decimal_4 = "user_decimal_4",
  user_numeric_1 = "user_numeric_1",
  user_numeric_2 = "user_numeric_2",
  user_numeric_3 = "user_numeric_3",
  user_numeric_4 = "user_numeric_4",
  user_int_1 = "user_int_1",
  user_int_2 = "user_int_2",
  user_int_3 = "user_int_3",
  user_int_4 = "user_int_4",
  user_money_1 = "user_money_1",
  user_money_2 = "user_money_2",
  user_money_3 = "user_money_3",
  user_money_4 = "user_money_4",
  user_text_1 = "user_text_1",
  user_text_2 = "user_text_2",
  user_text_3 = "user_text_3",
  user_text_4 = "user_text_4",
}

export enum PropertyColumnDefaultTitle {
  property_code = "Code",
  property_name = "Name",
  pms_name = "PMS",
  property_alias = "Alias",
  address = "Address",
  city = "City",
  state = "State",
  zip_code = "Zip Code",
  country = "Country",
  latitude = "Latitude",
  longitude = "Longitude",
  msa = "MSA",
  region = "Region",
  apn = "APN",
  fips = "FIPS Code",
  unit_count = "Units",
  total_sqft = "Total SqFt",
  rentable_sqft = "Rentable SqFt",
  parcel_size = "Parcel Size",
  owner_name = "Owner's Name",
  contact_person_name = "Contact's Name",
  contact_person_email = "Contact's Email",
  year_built = "Year Built",
  year_updated = "Year Updated",
  property_class = "Class",
  capitalization_rate = "Capitalization Rate",
  property_type = "Type",
  floor_count = "Floors",
  building_count = "Buildings",
  elevator_count = "Elevators",
  parking_count = "Parking Spaces",
  commercial_unit_count = "Commercial Units",
  purchase_date = "Purchase Date",
  purchase_price = "Purchase Price",
  market = "Market",
  website = "Website",
  phone = "Phone",
  back_end = "Back End",
  front_end = "Front End",
  "back_end_property_code" = "Back End Property Code",
  "hidden" = "Hidden",
  "tags" = "Tags",
  system_name = "Integration",
  is_comparable = "Comparable",
}
