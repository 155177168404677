import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";

import { ReportSection } from "../../../services/reportsService";

export const ReportScreenEmpty = ({
  section,
  action,
}: {
  section: ReportSection;
  action: () => void;
}) => (
  <div className="d-flex flex-col align-items-center">
    <Button onClick={action} color="white" className="rounded mb-4 jh-card">
      <FontAwesomeIcon
        size="6x"
        icon={faPlus}
        style={{ color: "var(--primary-color)" }}
        className="p-5"
      />
    </Button>
    <p className="fs-2 text-center">
      {section === "my"
        ? "Create your first report or copy one from the library"
        : "Add reports here to share them with other organizations"}
    </p>
  </div>
);
