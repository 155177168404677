import {
  GenericReportDefinition,
  RowsByDate,
  RowsByDimension,
  RowsByFinance,
  RowsByLease,
  RowsByProperty,
  RowsByUnit,
  SheetRows,
  Unresolved,
} from "@joyhub-integration/shared";
import React from "react";
import { DropdownItem, Popover, PopoverBody } from "reactstrap";

import { DefaultDimension } from "../editUtil";

const initialRowsByProperty: RowsByProperty = { rows: "Property" };

const initialRowsByDate: RowsByDate = {
  rows: "Date",
  interval: "Month",
  count: 12,
};

const initialRowsByDimension: RowsByDimension = {
  rows: "Dimension",
  dimension: DefaultDimension,
};

const initialRowsByLease: RowsByLease = {
  rows: "Lease",
  leases: "All",
  period: "Month",
};

const initialRowsByFinance: RowsByFinance = {
  rows: "Finance",
  interval: "Month",
  count: "YTD",
};

const initialRowsByUnit: RowsByUnit = {
  rows: "Unit",
};

const initialEmailBody = "<p>\n  Total units: {{allUnits}}\n</p>";

const AddSheetPopover: React.FC<{
  onAddSheet: (
    sheet: string | number[] | SheetRows,
  ) => (e: React.MouseEvent) => void;
  toggle: () => void;
  definition: GenericReportDefinition<Unresolved>;
}> = ({ onAddSheet, toggle, definition }) => {
  return (
    <Popover
      target="sheet-add"
      placement="bottom"
      isOpen={true}
      delay={0}
      fade={false}
      toggle={toggle}
      trigger="legacy"
    >
      <PopoverBody
        className="px-0 dropdown-menu show"
        style={{ position: "relative", border: "none" }}
      >
        {definition.body != null ? null : (
          <DropdownItem onClick={onAddSheet(initialEmailBody)} toggle={false}>
            Email Body
          </DropdownItem>
        )}
        {definition.templates != null ? null : (
          <DropdownItem onClick={onAddSheet([])} toggle={false}>
            Excel Template
          </DropdownItem>
        )}
        <DropdownItem onClick={onAddSheet(initialRowsByDate)} toggle={false}>
          Date Sheet
        </DropdownItem>
        <DropdownItem
          onClick={onAddSheet(initialRowsByDimension)}
          toggle={false}
        >
          Dimension Sheet
        </DropdownItem>
        <DropdownItem onClick={onAddSheet(initialRowsByFinance)} toggle={false}>
          Finance Sheet
        </DropdownItem>
        {!definition.perProperty ? null : (
          <DropdownItem onClick={onAddSheet(initialRowsByLease)} toggle={false}>
            Lease Sheet
          </DropdownItem>
        )}
        {definition.perProperty ? null : (
          <DropdownItem
            onClick={onAddSheet(initialRowsByProperty)}
            toggle={false}
          >
            Property Sheet
          </DropdownItem>
        )}
        <DropdownItem onClick={onAddSheet(initialRowsByUnit)} toggle={false}>
          Unit Sheet
        </DropdownItem>
      </PopoverBody>
    </Popover>
  );
};

export default AddSheetPopover;
