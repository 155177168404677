import {
  ExcelNumberFormats,
  financeBookNames,
  FinanceBooks,
  isAttributeColumn,
  isDateColumn,
  isDimensionColumn,
  isInsightColumn,
  isNumberAttributeColumn,
  Period,
  ReportColumn,
  ReportInterval,
} from "@joyhub-integration/shared";
import { sortBy } from "lodash";

import { sequence } from "../../utils/misc";
import { IDropListItem, jhItem } from "../common/JhSelect/JhSelect";

export const columnBucket = (column: ReportColumn) => column.bucket;
export const columnSubBucket = (column: ReportColumn) => column.subBucket;
export const columnHeader = (column: ReportColumn) =>
  column.header || undefined; // non-optional
export const columnWidth = (column: ReportColumn) => column.width;
export const columnFormat = (column: ReportColumn) =>
  isInsightColumn(column) || isAttributeColumn(column)
    ? column.format
    : undefined;

export const PropertyNameAttribute = "property_name";

export const columnDateFmt = (column: ReportColumn) =>
  isDateColumn(column) ? column.dateFmt : undefined;

export const FullYearFormat = "yyyy";
export const FullMonthYearFormat = "mmmm yyyy";
export const ShortMonthYearFormat = "mmm yyyy";
export const FullMonthDayYearFormat = "mmmm d yyyy";
export const ShortMonthDayYearFormat = "mmm d yyyy";
export const QuarterYearFormat = "Qq yyyy";
export const DefaultDateFormat = FullMonthYearFormat;

export const baseDateOptions = [
  jhItem(FullMonthYearFormat, "January 2001"),
  jhItem("mmm yyyy", "Jan 2001"),
  jhItem(FullMonthDayYearFormat, "January 1 2001"),
  jhItem(ShortMonthDayYearFormat, "Jan 1 2001"),
  jhItem("m/d/yyyy", "1/1/2001"),
  jhItem("m/yyyy", "1/2001"),
  jhItem("yyyy", "2001"),
];

export const baseLeaseOptions = [
  jhItem("type", "Lease Type"),
  jhItem("unitNumber", "Unit Number"),
  jhItem("unitType", "Unit Type"),
  jhItem("sqft", "Sqft"),
  jhItem("marketRent", "Market Rent"),
  jhItem("rent", "New Lease Rent"),
  jhItem("term", "New Lease Term"),
  jhItem("previousRent", "Previous Lease Rent"),
  jhItem("previousTerm", "Previous Lease Term"),
  jhItem("tradeOut", "Trade Out"),
  jhItem("tradeOutPercent", "Trade Out (%)"),
  jhItem("varianceToMarket", "Variance to Market"),
  jhItem("rentPSF", "New Rent PSF"),
  jhItem("previousRentPSF", "Previous Rent PSF"),
  jhItem("date", "Lease Date (Application/Renewal/Start Date)"),
  jhItem("startDate", "Start Date"),
  jhItem("endDate", "End Date"),
  jhItem("moveIn", "Move-In Date"),
  jhItem("email", "Email"),
  jhItem("firstName", "First Name"),
  jhItem("lastName", "Last Name"),
  jhItem("birthDate", "Date of Birth"),
  jhItem("phone", "Phone Number"),
  jhItem("address1", "Address 1"),
  jhItem("address2", "Address 2"),
  jhItem("city", "City"),
  jhItem("state", "State"),
  jhItem("zipCode", "Zip Code"),
  jhItem("outstandingBalance", "Outstanding Balance"),
];

export const basePropertyOptions = [
  jhItem("propertyCode", "Property Code"),
  jhItem("propertyName", "Property Name"),
];

export const baseUnitOptions = [
  jhItem("bedrooms", "Bedrooms"),
  jhItem("unitType", "Unit Type"),
  jhItem("unit", "Unit"),
  jhItem("buildingUnit", "Building Unit"),
];

export const baseUnitStatusOptions = [
  jhItem("areaSqft", "Sqft"),
  jhItem("day", "Date"),
  jhItem("makeReadyStatus", "Make Ready Status"),
  jhItem("marketRent", "Market Rent"),
  jhItem("marketRentPSF", "Market Rent PSF"),
  jhItem("effectiveRent", "Effective Rent"),
  jhItem("occupancyStatus", "Occupancy Status"),
];

export const baseTimeFrameOptions = [
  jhItem("", "Report Date"),
  jhItem("PriorWeek", "Prior Week"),
  jhItem("PriorMonth", "Prior Month"),
  jhItem("PriorQuarter", "Prior Quarter"),
  jhItem("PriorYear", "Prior Year"),
];

export const financeTimeFrameOptions = baseTimeFrameOptions.filter(
  (i) => i.value !== "PriorWeek",
);

export const baseNumberFormatOptions = [
  jhItem(ExcelNumberFormats.Integer, "Integer: 1,000"),
  jhItem(ExcelNumberFormats.DeltaInteger, "Integer Change: +1,000; -1,000"),
  jhItem(ExcelNumberFormats.PlainInteger, "Plain Integer: 1000"),
  jhItem(ExcelNumberFormats.DecimalTenths, "Decimal: 99.9"),
  jhItem(ExcelNumberFormats.DecimalHundredths, "Decimal: 99.99"),
  jhItem(ExcelNumberFormats.Dollars, "Dollars: $1,000"),
  jhItem(
    ExcelNumberFormats.DollarsAccounting,
    "Dollars Accounting: $1,000; $(1,000)",
  ),
  jhItem(ExcelNumberFormats.DeltaDollars, "Dollars Change: +$1,000; -$9,000"),
  jhItem(ExcelNumberFormats.DollarsCents, "Dollars and Cents: $99.99"),
  jhItem(
    ExcelNumberFormats.DollarsCentsAccounting,
    "Dollars and Cents Accounting: $99.99; $(99.99)",
  ),
  jhItem(
    ExcelNumberFormats.DeltaDollarsCents,
    "Dollars and Cents Change: +$99.99; -$99.99",
  ),
  jhItem(ExcelNumberFormats.PercentTenths, "Percentage: 99.5%"),
  jhItem(
    ExcelNumberFormats.DeltaPercentTenths,
    "Percentage Change: +99.5%; -99.5%",
  ),
];

export const baseDisplaySubColumnOptions = [
  jhItem("*", "All Bedroom Types"),
  jhItem("Studio", "Studio"),
  jhItem("1 Bed", "1 Bedroom"),
  jhItem("2 Beds", "2 Bedrooms"),
  jhItem("3 Beds", "3 Bedrooms"),
  jhItem("4 Beds", "4 Bedrooms"),
];

export const financeInsightOptions = sortBy(
  FinanceBooks.map((book) => jhItem(`insight:${book}`, financeBookNames[book])),
  "label",
);

export const columnTimeFrame = (column: ReportColumn) =>
  isInsightColumn(column) ? column.timeFrame : undefined;

export const monthlyPeriodOptions = [
  jhItem("", "No Aggregation"),
  jhItem("YTD", "Year to Date"),
  ...sequence(2, 12).map((i) => jhItem(`${i}:Month`, `${i} Months`)),
];

export const quarterlyPeriodOptions = [
  jhItem("", "No Aggregation"),
  jhItem("YTD", "Year to Date"),
  ...sequence(2, 8).map((i) => jhItem(`${i}:Month`, `${i} Months`)),
];

export const weeklyPeriodOptions = [
  jhItem("", "No Aggregation"),
  ...sequence(2, 4).map((i) => jhItem(`${i}:Week`, `${i} Weeks`)),
];

export const dailyPeriodOptions = [
  jhItem("", "No Aggregation"),
  ...sequence(2, 14).map((i) => jhItem(`${i}:Day`, `${i} Days`)),
];

export const columnPeriod = (column: ReportColumn) =>
  isInsightColumn(column) ? column.period : undefined;

export const toPeriodStr = (period?: Period) =>
  period == null
    ? ""
    : period === "YTD"
      ? period
      : `${period.count}:${period.interval}`;

export const fromPeriodStr = (str: string) =>
  str === ""
    ? undefined
    : str === "YTD"
      ? str
      : {
          count: parseInt(str.split(":")[0]),
          interval: str.split(":")[1] as ReportInterval,
        };

export const aggregateOptions = [jhItem("Sum"), jhItem("Average")];

export const columnAggregate = (column: ReportColumn) =>
  isInsightColumn(column) ? column.aggregate : undefined;

export const comparisonOptions = [
  jhItem("Difference", "Absolute Change +/-"),
  jhItem("Delta", "Relative Change +/-%"),
  jhItem("Fraction", "Relative Value %"),
];

export const columnVsComparison = (column: ReportColumn) =>
  isInsightColumn(column) || isNumberAttributeColumn(column)
    ? column.versus?.comparison
    : undefined;

export const columnVsTimeframe = (column: ReportColumn) =>
  isInsightColumn(column) || isNumberAttributeColumn(column)
    ? column.versus?.timeFrame
    : undefined;

export const columnDimension = (column: ReportColumn) =>
  isDimensionColumn(column) ? column.dimension : undefined;

// from insight/types.ts on the back-end
export const dimensionText: Record<string, string> = {
  age: "Age",
  bedrooms: "Bedrooms",
  financialCode: "Financial Code",
  leaseEnd: "Lease End",
  leaseStart: "Lease Start",
  nextEvent: "Next Event",
  source: "Ad Source",
  status: "Status",
  total: "(total)",
  unitType: "Unit Type",
  reason: "Move-Out Reason",
};

export const baseDimensionOptions = Object.entries(dimensionText).map(
  ([k, v]) => jhItem(k, v),
);

export const DefaultDimension = "bedrooms";

export const baseLeaseTypeOptions = [
  jhItem("All", "New Leases and Renewals"),
  jhItem("New", "New Leases"),
  jhItem("Renewal", "Renewals"),
  jhItem("InPlace", "In-Place Leases"),
];

export const baseLeasePeriodOptions = [
  jhItem("Today", "Current Day"),
  jhItem("Week", "Prior Week"),
  jhItem("Month", "Month to Date"),
];

/** Find a react-select option from a list of them or create one */
export const findOption = (
  options: readonly IDropListItem[],
  value: string | undefined,
) =>
  value == null
    ? undefined
    : (options.find((option) => option.value === value) ?? {
        value,
        label: value,
      });
