import { apiUrl, axiosConfig } from "../utils/api";
import axios from "./axios";
import { UploadUrl, uploadFile } from "./uploadService";
import { NavbarTheme, Organization } from "./usersService";

export async function getOrganizationsForPropertyConnect(): Promise<
  Array<Organization>
> {
  return axios
    .get(apiUrl("/organizations/connect"), axiosConfig)
    .then((res) => res.data.organizations as Array<Organization>);
}

export async function getOrganizationDataUploadUrl(): Promise<UploadUrl> {
  return axios
    .post(apiUrl("/organizations/data/upload"), undefined, axiosConfig)
    .then((res) => res.data as UploadUrl);
}

export const uploadOrganizationData = (id: number, file: File) =>
  getOrganizationDataUploadUrl().then((upload) => {
    const fileName = file.name.replace(/^.*\//, "");
    return uploadFile(file, upload, `asset/${id}/data/${fileName}`).then(
      () => fileName,
    );
  });

export async function updateOrganizationConfiguration(
  id: number,
  navbarTheme: NavbarTheme,
  logo: string | undefined,
  colors: string[] | undefined,
) {
  return axios
    .put(
      apiUrl(`/organizations/${id}/configuration`),
      {
        navbarTheme,
        logo,
        colors,
      },
      axiosConfig,
    )
    .then((res) => res.data as Organization);
}

export async function getOrganizationLogoUploadUrl(
  id: number,
): Promise<UploadUrl> {
  return axios
    .post(apiUrl(`/organizations/${id}/logoUpload`), undefined, axiosConfig)
    .then((res) => res.data as UploadUrl);
}

export const uploadOrganizationLogo = (id: number, file: File) =>
  getOrganizationLogoUploadUrl(id).then((upload) => {
    const fileName = file.name.replace(/^.*\//, "");
    return uploadFile(file, upload, `asset/${id}/${fileName}`).then(
      () => fileName,
    );
  });
