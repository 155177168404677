import {
  DashboardKindEnum,
  PropertiesSelection,
} from "@joyhub-integration/shared";
import { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";

import { Dashboard as DashboardType } from "../../../services/dashboardService";
import {
  getInstantData,
  getRangeDataOrDefault,
  InstantInsights,
  RangedInsights,
} from "../../../services/dataService";
import definitions from "../../../services/insightLibrary/insightDefinitions";
import insightsLibraryService from "../../../services/insightLibrary/insightsLibraryService";
import { Insight, OtherDashboardInsight } from "../../../services/models";
import { monthsBack, nowAndAYearAgo } from "../../../utils/date";
import MainBoard from "./MainBoard";

interface DashboardBoardPropsType {
  editMode: boolean;
  dashboard: DashboardType;
  selection: PropertiesSelection;
  dashboardKind:
    | DashboardKindEnum.Dashboard
    | DashboardKindEnum.PropertyDashboard;
  dashboardName: string;
  setEditMode: (value: any) => void;
  setDashboardInsightToEdit: (di: OtherDashboardInsight) => void;
  setDashboardInsightToRemove: (di: OtherDashboardInsight) => void;
  toggleAddEditInsight: (xy: [number, number]) => void;
}

export const DashboardBoard = (props: DashboardBoardPropsType) => {
  const {
    dashboard,
    dashboardName,
    editMode,
    selection,
    setEditMode,
    toggleAddEditInsight,
    ...rest
  } = props;
  const {
    definition: { layout },
  } = dashboard as any;

  const [loaded, setLoaded] = useState(false);
  const [rangeDataBreakout, setRangeDataBreakout] = useState<RangedInsights>();
  const [rangeDataNoBreakout, setRangeDataNoBreakout] =
    useState<RangedInsights>();
  const [rangeDataBreakoutAYearAgo, setRangeDataBreakoutAYearAgo] =
    useState<RangedInsights>();
  const [rangeDataNoBreakoutAYearAgo, setRangeDataNoBreakoutAYearAgo] =
    useState<RangedInsights>();
  const [instantDataNow, setInstantDataNow] = useState<InstantInsights>();
  const [instantDataAYearAgo, setInstantDataAYearAgo] =
    useState<InstantInsights>();

  function getAllInstantData(
    insightIds: Set<number>,
    selection: PropertiesSelection,
    yoy?: boolean,
  ): Promise<InstantInsights> {
    const now = new Date();
    const aYearAgo = new Date(now);
    aYearAgo.setFullYear(now.getFullYear() - 1);
    const atDate = yoy ? aYearAgo : now;
    return insightIds.size
      ? getInstantData(Array.from(insightIds), selection, atDate, "property")
      : Promise.resolve({
          date: "",
          byProperty: {},
          byTag: {},
          overall: {},
          goals: {
            byProperty: {},
            byTag: {},
            overall: {},
          },
        });
  }

  const basicLayout = useMemo(() => {
    if (!layout?.lg) return undefined;

    const lgLayout = layout.lg.reduce((prev: any[], item: any) => {
      if (prev[item.y] === undefined) prev[item.y] = [];

      let propertiesToRemove = ["h", "w", "moved", "static"];
      propertiesToRemove.forEach((prop) => {
        delete item[prop];
      });

      if (item.width === undefined) item.width = 1;

      prev[item.y].push(item);

      return prev;
    }, {});

    let newLayout: any[] = [];

    Object.keys(lgLayout).forEach((key, index) => {
      let layoutItem = [...lgLayout[key]];
      layoutItem.sort((a: any, b: any) => a.x - b.x);
      layoutItem = layoutItem.map((lItem, i) => ({ ...lItem, x: i, y: index }));
      newLayout.push(layoutItem);
    });

    return newLayout;
  }, [layout]);

  const dashboardInsights = useMemo(
    () => dashboard.definition?.insights ?? [],
    [dashboard.definition?.insights],
  );

  const smCharts = useMemo(
    () =>
      dashboardInsights.filter((di) => {
        const definition = definitions.find((i) => i.id === di.insightId);
        return (
          !definition?.customComponent &&
          definition?.calculationType === "INSTANT"
        );
      }),
    [dashboardInsights],
  );

  const mdCharts = useMemo(
    () =>
      dashboardInsights.filter((di) => {
        const definition = definitions.find((i) => i.id === di.insightId);
        return (
          !definition?.customComponent &&
          definition?.calculationType === "RANGE"
        );
      }),
    [dashboardInsights],
  );

  const customComponentCharts = useMemo(
    () =>
      dashboardInsights.filter((di) => {
        const definition = definitions.find((i) => i.id === di.insightId);
        return !!definition?.customComponent;
      }),
    [dashboardInsights],
  );

  useEffect(() => {
    setLoaded(false);
    const smChartInsightIdsNow = smCharts.reduce<Set<number>>(
      (prev, current) => {
        const childInsightIds = insightsLibraryService.getAllChildInsightIds(
          current.insightId,
        );
        childInsightIds.forEach((id) => prev.add(id));
        return prev;
      },
      new Set(),
    );
    const smChartInsightIdsYOY = smCharts.reduce<Set<number>>(
      (prev, current) => {
        if (current.visualizationType === "YOY CHANGE") {
          const childInsightIds = insightsLibraryService.getAllChildInsightIds(
            current.insightId,
          );
          childInsightIds.forEach((id) => prev.add(id));
        } else {
          const insightDefinition = definitions.find(
            (i) => i.id === current.insightId,
          );
          for (const idOrComplexId of insightDefinition?.insightIds ?? []) {
            if (typeof idOrComplexId !== "number" && idOrComplexId.yoy) {
              for (const id of idOrComplexId.insightIds as number[]) {
                prev.add(id);
              }
            }
          }
        }
        return prev;
      },
      new Set(),
    );
    const getInsightIds = (breakout: boolean, yoy: boolean = false) => {
      return mdCharts
        .filter((mdChart) => {
          const insight = insightsLibraryService.insights.find(
            (i) => i.id === mdChart.insightId,
          ) as Insight;
          return !!insight.byPropertyCalculationType === breakout;
        })
        .reduce<Set<number>>((prev, current) => {
          const childInsightIds = insightsLibraryService.getAllChildInsightIds(
            current.insightId,
            yoy,
          );
          childInsightIds.forEach((id) => prev.add(id));
          return prev;
        }, new Set());
    };
    const rangeInsightIdsBreakout = getInsightIds(true);
    const rangeInsightIdsBreakoutYoy = getInsightIds(true, true);
    const rangeInsightIdsNonBreakout = getInsightIds(false);
    const rangeInsightIdsNonBreakoutYoy = getInsightIds(false, true);
    const { from: aYearAgo, to: now } = nowAndAYearAgo();
    const { from: twoYearsAgo, to: aYearAndMonthAgo } = nowAndAYearAgo(
      monthsBack(aYearAgo, 1),
    );
    Promise.all([
      getAllInstantData(smChartInsightIdsNow, selection, false),
      getAllInstantData(smChartInsightIdsYOY, selection, true),
      getRangeDataOrDefault(
        aYearAgo,
        now,
        rangeInsightIdsBreakout,
        selection,
        "property",
      ),
      getRangeDataOrDefault(
        aYearAgo,
        now,
        rangeInsightIdsNonBreakout,
        selection,
      ),
      getRangeDataOrDefault(
        twoYearsAgo,
        aYearAndMonthAgo,
        rangeInsightIdsBreakoutYoy,
        selection,
        "property",
      ),
      getRangeDataOrDefault(
        twoYearsAgo,
        aYearAndMonthAgo,
        rangeInsightIdsNonBreakoutYoy,
        selection,
      ),
    ]).then(
      ([
        instantNowValues,
        instantAYearAgoValues,
        rangeValuesBreakout,
        rangeValuesNoBreakout,
        rangeValuesBreakoutYoy,
        rangeValuesNoBreakoutYoy,
      ]) => {
        ReactDOM.unstable_batchedUpdates(() => {
          setInstantDataNow(instantNowValues);
          setInstantDataAYearAgo(instantAYearAgoValues);
          setRangeDataBreakout(rangeValuesBreakout);
          setRangeDataNoBreakout(rangeValuesNoBreakout);
          setRangeDataBreakoutAYearAgo(rangeValuesBreakoutYoy);
          setRangeDataNoBreakoutAYearAgo(rangeValuesNoBreakoutYoy);
          setLoaded(true);
        });
      },
    );
  }, [dashboardInsights, selection, mdCharts, smCharts]);

  return (
    <div className="new-dashboard">
      <MainBoard
        loaded={loaded}
        editMode={editMode}
        smCharts={smCharts}
        mdCharts={mdCharts}
        dashboard={dashboard}
        dashboardName={dashboardName}
        selection={selection}
        basicLayout={basicLayout}
        instantDataNow={instantDataNow}
        rangeDataBreakout={rangeDataBreakout}
        instantDataAYearAgo={instantDataAYearAgo}
        rangeDataNoBreakout={rangeDataNoBreakout}
        customComponentCharts={customComponentCharts}
        rangeDataBreakoutAYearAgo={rangeDataBreakoutAYearAgo}
        rangeDataNoBreakoutAYearAgo={rangeDataNoBreakoutAYearAgo}
        setEditMode={setEditMode}
        toggleAddEditInsight={toggleAddEditInsight}
        {...rest}
      />
    </div>
  );
};
