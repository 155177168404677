import {
  ReportColumn,
  ReportSheet,
  SheetRows,
  exhaustiveCheck,
  isRowsByDate,
  isRowsByDimension,
  isRowsByFinance,
  isRowsByLease,
  isRowsByProperty,
  isRowsByUnit,
} from "@joyhub-integration/shared";

import {
  FullMonthYearFormat,
  FullYearFormat,
  PropertyNameAttribute,
  QuarterYearFormat,
  ShortMonthDayYearFormat,
  ShortMonthYearFormat,
  dimensionText,
} from "../editUtil";

export const BodySheet = "_body";
export const TemplateSheet = "_template";

export type SpecialSheet = typeof BodySheet | typeof TemplateSheet;

export const initialSheet = (rows: SheetRows): ReportSheet => ({
  name: "",
  titles: [],
  columns: [],
  rows,
  pivot: isRowsByFinance(rows),
});

export const initialColumn = (
  rows: SheetRows,
): ReportColumn | ReportColumn[] =>
  isRowsByProperty(rows)
    ? {
        header: "Property",
        attribute: PropertyNameAttribute,
        sort: {
          order: "Ascending",
        },
      }
    : isRowsByDate(rows)
      ? {
          header: rows.interval === "Week" ? "Week Starting" : "Date",
          dateFmt:
            rows.interval === "Month"
              ? FullMonthYearFormat
              : rows.interval === "Quarter"
                ? QuarterYearFormat
                : ShortMonthDayYearFormat,
          sort: {
            order: "Descending",
          },
        }
      : isRowsByDimension(rows)
        ? {
            header: dimensionText[rows.dimension] ?? "",
            dimension: rows.dimension,
            sort: {
              order: "Ascending",
            },
          }
        : isRowsByLease(rows)
          ? {
              header: "Lease Date",
              attribute: "date",
              sort: {
                order: "Ascending",
              },
            }
          : isRowsByUnit(rows)
            ? [
                {
                  header: "Property Name",
                  attribute: "propertyName",
                  sort: {
                    order: "Ascending",
                  },
                },
                {
                  header: "Unit",
                  attribute: "unit",
                  sort: {
                    order: "Ascending",
                  },
                },
              ]
            : isRowsByFinance(rows)
              ? [
                  {
                    header: "",
                    dateFmt:
                      rows.interval === "Month"
                        ? ShortMonthYearFormat
                        : rows.interval === "Quarter"
                          ? QuarterYearFormat
                          : FullYearFormat,
                    sort: {
                      order: "Ascending",
                    },
                  },
                ]
              : exhaustiveCheck(rows);
