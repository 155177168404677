import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ClampLines from "react-clamp-lines";

import JhCard from "../../common/card/JhCard";

interface AdminPortalTileProps {
  to: string;
  name: string;
  description: string;
  icon: IconProp;
  className?: string;
}

const AdminPortalTile: React.FC<AdminPortalTileProps> = ({
  to,
  name,
  description,
  icon,
  className,
}) => {
  return (
    <JhCard
      title={name}
      description={description}
      className={className}
      linkTo={to}
    >
      {{
        JhCardBody: (
          <div className="flex-row align-items-center h-100">
            <div className="card-icon">
              <FontAwesomeIcon icon={icon} size="5x" className="color-pop" />
            </div>
            <ClampLines
              id={description}
              text={description}
              lines={4}
              buttons={false}
              innerElement="p"
              className="card-text flex-grow"
            />
          </div>
        ),
      }}
    </JhCard>
  );
};

export default AdminPortalTile;
