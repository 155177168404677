import {
  IconDefinition,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { NavLink as RRNavLink, useLocation } from "react-router-dom";
import { NavItem, NavLink } from "reactstrap";

export interface Link {
  children?: Link[];
  className?: string;
  external?: boolean;
  icon?: IconDefinition;
  path: string;
  prefix?: string;
  target?: string;
  text: string;
}

const SidebarLink = ({
  children,
  className,
  external,
  icon,
  path,
  prefix,
  target,
  text,
}: Link) => {
  const location = useLocation();

  const active: boolean =
    (prefix && location.pathname.startsWith(prefix)) ||
    location.pathname === path;
  const linkProps = external
    ? { tag: undefined, href: path, target: target }
    : {
        tag: RRNavLink,
        to: path,
        exact: true,
        isActive: () => active && !children,
      };
  return (
    <NavItem key={path} active={active && !children}>
      <NavLink
        {...linkProps}
        className={clsx("overflow-hidden text-nowrap", className)}
      >
        <span className="nav-link-icon">
          {children && (
            <FontAwesomeIcon icon={active ? faChevronDown : faChevronRight} />
          )}
          {icon && <FontAwesomeIcon icon={icon} />}
        </span>
        <span className="nav-link-text">{text}</span>
      </NavLink>
      {active && children && (
        <ul className="flex-grow-1 flex-column w-100 navbar-nav mb-3">
          {children.map((child) => (
            <SidebarLink
              key={child.path}
              {...child}
              className={clsx(className, "ps-4")}
            />
          ))}
        </ul>
      )}
    </NavItem>
  );
};

export default SidebarLink;
