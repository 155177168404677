import "./dashboard.css";

import { faCheck, faPlus } from "@fortawesome/pro-light-svg-icons";
import { DashboardKindEnum } from "@joyhub-integration/shared";
import download from "downloadjs";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BooleanParam, useQueryParam } from "use-query-params";

import { useBreakpoint } from "../../hooks/useBreakpoint";
import dashboardService, {
  BaseDashboard,
  Dashboard as DashboardType,
} from "../../services/dashboardService";
import { OtherDashboardInsight as DashboardInsight } from "../../services/models";
import { downloadAttachment } from "../../utils/download";
import { usePropertiesSelectionQueryParam } from "../../utils/useQueryParams";
import DeleteModal from "../admin/common/DeleteModal";
import PlatformContext from "../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";
import { LoadilyFadily } from "../common/allFadily";
import ButtonWithIcon from "../common/button/ButtonWithIcon";
import PrintHeader from "../common/PrintHeader";
import CopyModal from "./CopyModal";
import DashboardAddEditInsight from "./DashboardAddEditInsight";
import { DashboardBoard } from "./DashboardBoard";
import { DashboardDto } from "./dashboardDto";
import DashboardGrid from "./DashboardGrid";
import DashboardHeader from "./DashboardHeader";

type DashboardParams = {
  id: string;
  propertyId: string;
};

const Dashboard: React.FC<WithAlertModalProps> = ({
  onUnexpectedError,
  setAlert,
}) => {
  const { id: dashboardIdParam, propertyId: propertyIdParam } =
    useParams<DashboardParams>();
  const [propertyId, setPropertyId] = useState<number | undefined>();
  const [dashboardParam, setDashboardParam] = useState<string | undefined>();
  const [addEditInsightModalOpen, setAddEditInsightModalOpen] = useState<
    [number, number] | boolean
  >(false);
  const [dashboardInsightToEdit, setDashboardInsightToEdit] =
    useState<DashboardInsight>();
  const [dashboardInsightToRemove, setDashboardInsightToRemove] =
    useState<DashboardInsight>();
  const [copying, setCopying] = useState<boolean>(false);
  const [pdfDownloading, setPDFDownloading] = useState<boolean>(false);
  const [footnote] = useState<boolean>(true); // TODO: this should be driven by the dashboard
  const [editMode, setEditMode] = useQueryParam("edit", BooleanParam);
  const [selection, setSelection] = usePropertiesSelectionQueryParam();
  const navigate = useNavigate();
  const platform = useContext(PlatformContext).platform!;
  const breakpoint = useBreakpoint();

  const {
    admin,
    insightsMap,
    propertiesMap,
    propertyGroups,
    organization_role,
    embedInfo,
  } = platform;
  const [dashboards, setDashboards] = useState<DashboardType[]>([]);
  const [isAppliedParam, setIsAppliedParam] = useState<boolean>(false);
  const [dashboardName, setDashboardName] = useState<string>("");
  const dashboardKind = useMemo(
    () =>
      propertyId != null
        ? DashboardKindEnum.PropertyDashboard
        : DashboardKindEnum.Dashboard,
    [propertyId],
  );

  // hide zendesk widget until we leave this page
  useEffect(() => {
    if (admin && editMode) {
      window.zE?.("messenger:set", "zIndex", -5000);
      return () => {
        window.zE?.("messenger:set", "zIndex", 5000);
      };
    }
  }, [admin, editMode]);

  useEffect(() => {
    setTimeout(() => {
      const kids = document.body.children;
      for (let i = 0; i < kids.length; ++i) {
        const e = kids.item(i) as HTMLDivElement | null;
        if (e?.tagName == "DIV" && e.style.visibility == "visible")
          e.classList.add("zendesk");
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (!platform) return;

    const dashboardParamValue = embedInfo
      ? embedInfo.identifier
      : dashboardIdParam;

    let propertyIdValue;
    if (embedInfo)
      propertyIdValue =
        embedInfo.propertyId === null ? undefined : embedInfo.propertyId;
    else
      propertyIdValue =
        propertyIdParam == null ? undefined : parseInt(propertyIdParam);

    setDashboardParam(dashboardParamValue);
    setPropertyId(propertyIdValue);
    setIsAppliedParam(true);
  }, [
    platform,
    embedInfo,
    dashboardIdParam,
    propertyIdParam,
    setDashboardParam,
    setPropertyId,
  ]);

  useEffect(() => {
    if (selection == null)
      setSelection(propertyId != null ? propertyId : {}, "replaceIn");
  }, [dashboardParam, propertyId, selection, setSelection, propertiesMap]);

  const loadDashboards = useCallback(
    () =>
      dashboardService
        .getDashboards(false, true, dashboardKind)
        .then(setDashboards),
    [dashboardKind],
  );

  useEffect(() => {
    if (isAppliedParam) loadDashboards().catch(onUnexpectedError);
  }, [isAppliedParam, editMode, loadDashboards, onUnexpectedError]);

  const dashboard = useMemo<DashboardType | undefined>(
    () =>
      dashboards.find(
        (d) =>
          !d.shared &&
          d.identifier.toLowerCase() === dashboardParam?.toLowerCase(),
      ) ??
      dashboards.find(
        (d) => d.identifier.toLowerCase() === dashboardParam?.toLowerCase(),
      ) ??
      dashboards[0],
    [dashboardParam, dashboards],
  );

  useEffect(() => {
    if (dashboard?.id != null && dashboard?.name != null) {
      setDashboardName(dashboard.name);
      dashboardService.viewDashboard(dashboard.id).then(() => {});
      try {
        window.analytics.track("Dashboard Viewed", {
          dashboardName: dashboard.name,
          dashboardId: dashboard.id,
          userId: platform.person.id,
        });
      } catch (e) {
        console.error("Unable to fire segment track event", e);
      }
    }
  }, [dashboard?.id, dashboard?.name, platform.person.id]);

  function onAddEditInsightSubmit() {
    loadDashboards()
      .then(() => {
        setDashboardInsightToEdit(undefined);
        setAddEditInsightModalOpen(false);
      })
      .catch(onUnexpectedError);
  }

  function onSetDashboardInsightToEdit(di: DashboardInsight) {
    setDashboardInsightToEdit(di);
    setAddEditInsightModalOpen(true);
  }

  function onAddEditClose() {
    setDashboardInsightToEdit(undefined);
    setAddEditInsightModalOpen(false);
  }

  function toggleAddEditInsight(xy: [number, number] | boolean) {
    if (!xy) {
      onAddEditClose();
    } else {
      setAddEditInsightModalOpen(xy);
    }
  }

  function exportToJSON(dashboard: DashboardType) {
    const json = JSON.stringify(dashboard.definition);
    download(json, `${dashboard.name}.json`, "application/json");
  }

  function onCopySubmit(d: BaseDashboard) {
    loadDashboards().then(() => {
      setCopying(false);
      const prefix = propertyId == null ? "" : `/properties/${propertyId}`;
      navigate(`${prefix}/dashboards/${d.identifier}`, {
        state: { copied: true },
      });
    });
  }

  function onDownloadPDF() {
    if (dashboard) {
      setPDFDownloading(true);
      setAlert(
        "Kindly wait for about 20 seconds as the PDF is generated.",
        true,
      );
      dashboardService
        .downloadDashboardPDF(dashboard.id, {
          propertyId,
          properties: selection!,
        })
        .then(downloadAttachment("Dashboard"))
        .catch(onUnexpectedError)
        .finally(() => setPDFDownloading(false));
    }
  }

  // for the old dashboard feature
  function updateOldDashboardLayout(layout: ReactGridLayout.Layouts) {
    if (
      (organization_role == "Administrator" || organization_role == "Editor") &&
      editMode &&
      dashboard != null
    )
      dashboardService
        .setDashboardLayoutAndName(dashboard.id, layout)
        .then(() => loadDashboards())
        .catch(onUnexpectedError);
  }

  // for the old dashboard feature
  async function handleCloseOldEditMode() {
    if (dashboard) {
      const dto = {
        identifier: dashboard.identifier,
        name: dashboardName,
        description: dashboard.description,
        shared: dashboard.shared,
        internal: dashboard.internal,
        kind: dashboard.kind,
        definition: dashboard.definition,
        category: dashboard.category,
      } as DashboardDto;
      await dashboardService.updateDashboard(dashboard.id, dto);
    }

    setEditMode(undefined);
  }

  return (
    <div className="jh-page-layout">
      <LoadilyFadily loaded={dashboard != null}>
        {dashboard == null ? null : (
          <>
            <DashboardHeader
              dashboard={dashboard}
              editMode={!!editMode}
              onEdit={() => setEditMode(true)}
              onCopy={() => setCopying(true)}
              onDownloadDefinition={() => exportToJSON(dashboard)}
              onDownloadPDF={onDownloadPDF}
              pdfDownloading={pdfDownloading}
              propertyId={propertyId}
              dashboards={dashboards}
              propertyGroups={propertyGroups}
              dashboardName={dashboardName}
              setDashboardName={setDashboardName}
              onUnexpectedError={onUnexpectedError}
              setAlert={setAlert}
            />
            {selection == null ? null : (
              <div
                className={`jh-page-content dashboard-main position-relative ${
                  editMode ? "dashboard-editing" : "dashboard-viewing"
                }`}
                style={{
                  padding:
                    breakpoint === "xs"
                      ? 0
                      : breakpoint === "sm"
                        ? 10
                        : undefined,
                }}
              >
                <PrintHeader title={dashboard.name} fullDate />
                <div className="dashboard-container">
                  <div className="dashboard-content-container">
                    {dashboard?.definition?.isNew ? (
                      <DashboardBoard
                        editMode={
                          (organization_role == "Administrator" ||
                            organization_role == "Editor") &&
                          !!editMode
                        }
                        setEditMode={setEditMode}
                        dashboard={dashboard}
                        selection={selection ?? {}}
                        dashboardKind={dashboardKind}
                        dashboardName={dashboardName}
                        toggleAddEditInsight={toggleAddEditInsight}
                        setDashboardInsightToEdit={onSetDashboardInsightToEdit}
                        setDashboardInsightToRemove={
                          setDashboardInsightToRemove
                        }
                      />
                    ) : (
                      <>
                        <DashboardGrid
                          dashboardId={dashboard.id}
                          dashboardInsights={
                            dashboard.definition?.insights ?? []
                          }
                          setDashboardLayout={updateOldDashboardLayout}
                          setDashboardInsightToEdit={
                            onSetDashboardInsightToEdit
                          }
                          setDashboardInsightToRemove={
                            setDashboardInsightToRemove
                          }
                          layout={dashboard.definition?.layout}
                          selection={selection ?? {}}
                          editMode={
                            (organization_role == "Administrator" ||
                              organization_role == "Editor") &&
                            !!editMode
                          }
                          propertiesHash={propertiesMap}
                          insightsMap={insightsMap}
                          dashboardKind={dashboardKind}
                        />
                        {(organization_role == "Administrator" ||
                          organization_role == "Editor") &&
                        editMode ? (
                          <div className="floaty-buttons">
                            <ButtonWithIcon
                              className="jh-icon-with-text dashboard-add-insight-button"
                              icon={faPlus}
                              color="primary"
                              onClick={() => toggleAddEditInsight(true)}
                              label={<span>Add a Card</span>}
                            />
                            <ButtonWithIcon
                              className="jh-icon-with-text"
                              icon={faCheck}
                              color="success"
                              onClick={handleCloseOldEditMode}
                            />
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                  {footnote && !editMode ? (
                    <div className="dashboard-footnote">
                      * Current month calculated over last 30 days
                    </div>
                  ) : null}
                </div>
                {dashboardInsightToRemove ? (
                  <DeleteModal<DashboardInsight>
                    id={dashboardInsightToRemove.id}
                    entityName="Dashboard Insight"
                    identificationKey="name"
                    getEntity={(id) =>
                      dashboardService.getDashboardInsight(dashboard.id, id)
                    }
                    deleteEntity={(id) =>
                      dashboardService.deleteDashboardInsight(dashboard.id, id)
                    }
                    onClose={() => setDashboardInsightToRemove(undefined)}
                    onSubmit={() =>
                      loadDashboards()
                        .then(() => setDashboardInsightToRemove(undefined))
                        .catch(onUnexpectedError)
                    }
                  />
                ) : null}
                {copying ? (
                  <CopyModal
                    id={dashboard.id}
                    originalName={dashboard.name}
                    onSubmit={(d) => onCopySubmit(d)}
                    onClose={() => setCopying(false)}
                  />
                ) : null}
                {addEditInsightModalOpen ? (
                  <DashboardAddEditInsight
                    dashboardId={dashboard.id}
                    dashboardKind={dashboardKind}
                    isOldDashboard={!dashboard?.definition?.isNew}
                    dashboardInsightId={dashboardInsightToEdit?.id}
                    xy={
                      Array.isArray(addEditInsightModalOpen)
                        ? addEditInsightModalOpen
                        : undefined
                    }
                    onClose={onAddEditClose}
                    onSubmit={onAddEditInsightSubmit}
                  />
                ) : null}
              </div>
            )}
          </>
        )}
      </LoadilyFadily>
    </div>
  );
};

export default withAlertModal(Dashboard);
