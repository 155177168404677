import { underscoreSpaces } from "@joyhub-integration/shared";
import { useContext } from "react";

import { apiUrl } from "../../utils/api";
import PlatformContext from "../app/PlatformContext";

const OrgLogo = () => {
  const { platform } = useContext(PlatformContext);
  const { organization } = platform!;

  const logoUrl = underscoreSpaces(organization.configuration.logo);
  const dark = platform?.organization.configuration.navbarTheme === "dark";

  return logoUrl ? (
    <img
      src={apiUrl(`/organizations/${organization.id}/logo/${logoUrl}`)}
      alt={organization.name}
      className="mw-100 p-lg-2 p-0 org-logo"
      style={{ maxHeight: "60px" }}
    />
  ) : (
    <div className={`org-title-logo mw-100 p-lg-2 p-0 ${dark ? "dark" : ""}`}>
      {organization.name}
    </div>
  );
};

export default OrgLogo;
