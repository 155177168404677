import "./Navbar.css";

import {
  faAngleLeft,
  faBooks,
  faBuilding,
  faCity,
  faCog,
  faCommentQuestion,
  faEdit,
  faFileChartLine,
  faSignOut,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import preval from "preval.macro";
import React from "react";
import { Nav, Navbar, NavItem, NavLink } from "reactstrap";

import { ReactComponent as RevolutionRELogo } from "../../images/logo-h.svg";
import { ReactComponent as RevolutionRELogoIcon } from "../../images/logo-icon.svg";
import { environment, environmentName } from "../../services/platformService";
import { setSessionOrganization } from "../../services/sessionOrganization";
import { logoutUrl } from "../../services/sessionService";
import { prId } from "../../utils/api";
import PlatformContext from "../app/PlatformContext";
import OrgSwitcher from "../layout/OrgSwitcher";
import SidebarLink, { Link } from "./SidebarLink";

const buildDate = preval`module.exports = new Date().toISOString().substring(0, 10)`;
const commitId = (
  process.env.REACT_APP_AWS_COMMIT_ID || "0123456789ABCDEF"
).substring(0, 16);
const branchName = process.env.REACT_APP_AWS_BRANCH;

const logOut = () => {
  setSessionOrganization(null);
  document.location.href = logoutUrl();
};

const JoyHubNavbar: React.FC<{
  toggleSidebar?: () => void;
  sidebarOpen?: Record<string, boolean | undefined>;
  breakpoint?: string;
}> = ({ toggleSidebar, sidebarOpen, breakpoint }) => {
  const { platform } = React.useContext(PlatformContext);

  if (!platform) return null;
  const { organization, admin, superAdmin } = platform;

  const isBiApp = organization.application === "BI";
  const isPcApp = organization.application === "PC";

  const links: Array<Link> = [];
  if (isBiApp) {
    links.push(
      {
        path: "/",
        text: "Portfolio",
        prefix: "/dashboards",
        icon: faCity,
      },
      {
        path: "/properties",
        text: "Properties",
        prefix: "/properties",
        icon: faBuilding,
      },
      {
        path: "/reports/my",
        text: "Reports",
        prefix: "/report",
        icon: faFileChartLine,
        children: [
          {
            path: "/reports/my",
            text: "My Reports",
            prefix: "/reports/my",
            icon: faFileChartLine,
          },
          {
            path: "/reports/library",
            text: "Library",
            prefix: "/reports/library",
            icon: faBooks,
          },
        ],
      },
    );
    if (admin) {
      links.push({
        path: "/admin",
        text: "Setup",
        prefix: "/admin",
        icon: faCog,
      });
    }
  }
  if (isPcApp) {
    if (admin) {
      links.push({
        path: "/users",
        text: "Manage Users",
        prefix: "/users",
        icon: faUsers,
      });
      if (superAdmin) {
        links.push({
          path: "/admin/organization",
          text: "Manage Organization",
          prefix: "/admin/organization",
          icon: faEdit,
        });
      }
    }
  }
  links.push({
    path: "https://support.revolutionre.com",
    external: true,
    text: "Help",
    icon: faCommentQuestion,
    target: "_blank",
  });

  const navItems = links.map((link) => (
    <SidebarLink key={link.path} {...link} />
  ));
  const theme = organization.configuration.navbarTheme ?? "dark";
  return (
    <Navbar
      className="flex-column flex-nowrap navbar"
      color={theme}
      dark={theme === "dark"}
      light={theme === "light"}
      expand="lg"
      container={false}
    >
      {sidebarOpen && breakpoint === "xs" && !sidebarOpen[breakpoint] ? null : (
        <>
          <div
            className={`${
              breakpoint &&
              sidebarOpen &&
              !sidebarOpen[breakpoint] &&
              "sm-org-switcher"
            }`}
          >
            <OrgSwitcher />
          </div>
          {environment !== "Prod" ? (
            <div className={`env-sash env-${environment.toLowerCase()}`}>
              <div className="inner">{environmentName}</div>
            </div>
          ) : null}
          <Nav navbar className="flex-grow-1 flex-column w-100 mt-4">
            {navItems}
            <NavItem>
              <NavLink href="#" color="primary" onClick={logOut}>
                <span className="nav-link-icon">
                  <FontAwesomeIcon icon={faSignOut} />
                </span>
                <span className="nav-link-text">Logout</span>
              </NavLink>
            </NavItem>
          </Nav>
          <a
            href="https://revolutionre.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-center"
          >
            <RevolutionRELogo
              className="logo-full"
              style={{ width: "60%", margin: "1em 1em .25em" }}
            />
            <RevolutionRELogoIcon className="logo-icon" />
          </a>
          <div className="build-info">
            {`${buildDate} | ${prId == null ? commitId : branchName}`}
            {platform && platform.buildDate !== buildDate
              ? ` | ${platform.buildDate}`
              : null}
          </div>
        </>
      )}
      {toggleSidebar ? (
        <button
          onClick={toggleSidebar}
          className="btn btn-light p-0 d-flex justify-content-center align-items-center border sidebar-toggle"
        >
          <FontAwesomeIcon
            className="angle-bracket"
            icon={faAngleLeft}
            size="lg"
          />
          <span className="sr-only">Toggle sidebar</span>
        </button>
      ) : null}
    </Navbar>
  );
};

export default JoyHubNavbar;
