import "./loading.css";

import React from "react";
import { Spinner } from "reactstrap";

import { ReactComponent as Logo } from "../../images/logo-h.svg";

const Loading: React.FC<{ joyhubLogo?: boolean }> = ({ joyhubLogo }) => {
  return (
    <div className="loading-page-main" style={{ opacity: 0.5 }}>
      {joyhubLogo ? (
        <Logo id="loading-logo" style={{ fill: "#0e1553" }} />
      ) : null}
      <div style={{ height: "0" }}>
        <Spinner id="loading-spinner" color="secondary" type="border" />
      </div>
    </div>
  );
};

export default Loading;
