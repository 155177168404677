import {
  faFileImport,
  faLayerPlus,
  faPencilAlt,
  faPlus,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AdHocInsightEntity,
  ignoreCaseIncludesMatcher,
} from "@joyhub-integration/shared";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "reactstrap";

import {
  deleteAdHocInsight,
  getAdHocInsight,
  getAdHocInsights,
} from "../../../services/adHocInsightService";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ActionBar from "../../common/button/ActionBar";
import { NonBreakingSpace } from "../../common/button/ButtonWithIcon";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";
import JhCrumbar from "../../navbar/JhCrumbar";
import DeleteModal from "../common/DeleteModal";
import AddEditAdHocInsightModal from "./AddEditAdHocInsightModal";
import TestProcessModal from "./TestProcessModal";

const AdHocInsightsAdminPage: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const [adHocInsights, setAdHocInsights] =
    useState<Array<AdHocInsightEntity>>();
  const [selected, setSelected] = useState<AdHocInsightEntity>();
  const [AdHocInsightFilter, setAdHocInsightFilter] = useState("");
  const [modal, setModal] = useState<"create" | "edit" | "delete" | "test">();
  const navigate = useNavigate();

  const fetchAdHocInsights = useCallback(
    async function () {
      return getAdHocInsights()
        .then((adHocIns) => {
          setAdHocInsights(adHocIns);
        })
        .catch(onUnexpectedError);
    },
    [onUnexpectedError],
  );

  useEffect(() => {
    fetchAdHocInsights();
  }, [fetchAdHocInsights]);

  const filteredAdHocInsights = useMemo(() => {
    const matcher = ignoreCaseIncludesMatcher(AdHocInsightFilter);
    return adHocInsights?.filter((adHocInsight) => matcher(adHocInsight.name));
  }, [adHocInsights, AdHocInsightFilter]);

  const onAdHocInsightSubmitted = (adHocInsight: AdHocInsightEntity) => {
    fetchAdHocInsights().then(() => {
      const verb = modal === "edit" ? "edited" : "added";
      setAlert(`Ad Hoc Insight ${adHocInsight.name} ${verb}`, true);
      setSelected(undefined);
      setModal(undefined);
    });
  };

  const onAdHocInsightDeleted = (adHocInsight: AdHocInsightEntity) => {
    fetchAdHocInsights().then(() => {
      setAlert(`Ad Hoc Insight ${adHocInsight.name} deleted`, true);
      setSelected(undefined);
      setModal(undefined);
    });
  };

  const buttonProps = [
    <Button className="jh-btn-primary" onClick={() => setModal("create")}>
      <FontAwesomeIcon size="sm" icon={faPlus} className="me-1" />
      {NonBreakingSpace}
      Add Report
    </Button>,
    {
      icon: faPencilAlt,
      disabled: !selected,
      onClick: () => setModal("edit"),
      tooltip: "Edit Ad Hoc Report",
    },
    {
      icon: faTrash,
      disabled: !selected,
      onClick: () => setModal("delete"),
      tooltip: "Delete Ad Hoc Report",
    },
    {
      icon: faLayerPlus,
      disabled: !selected,
      onClick: () => setModal("test"),
      tooltip: "Test Ad Hoc Report",
    },
    {
      icon: faFileImport,
      disabled: !selected,
      onClick: () => navigate(`${selected?.id}`),
      tooltip: "History",
    },
  ];

  const tableCols: Array<KeyValue<AdHocInsightEntity>> = [
    {
      key: "name",
      title: "Name",
    },
    {
      key: "description",
      title: "Description",
      sortable: false,
    },
    {
      key: "active",
      title: "Status",
      toValue: (i) => (i.active ? "Active" : "Inactive"),
    },
  ];

  return (
    <LoadilyFadily loaded={adHocInsights != null} className="jh-page-layout">
      <JhCrumbar
        primary="Administration"
        primaryPath="/admin"
        secondary="Manage Ad Hoc Reports"
      >
        <Input
          className="w-auto ms-auto rounded-pill"
          size={36}
          type="text"
          placeholder="Filter insights"
          onChange={(e) => setAdHocInsightFilter(e.target.value)}
        />
      </JhCrumbar>
      <div className="jh-page-content users-admin-page admin-page">
        <ActionBar buttonProps={buttonProps} />
        <TableWithSelection<AdHocInsightEntity>
          selected={selected}
          onSelectedChange={(selected) => setSelected(selected)}
          columns={tableCols}
          sortColumn="name"
          rows={filteredAdHocInsights}
        />
        {modal === "create" || modal === "edit" ? (
          <AddEditAdHocInsightModal
            currentSelected={modal === "edit" ? selected : undefined}
            onSubmit={onAdHocInsightSubmitted}
            onClose={() => setModal(undefined)}
          />
        ) : modal === "delete" && selected ? (
          <DeleteModal
            id={selected.id}
            entityName="Ad Hoc Insight"
            identificationKey="name"
            getEntity={getAdHocInsight}
            deleteEntity={deleteAdHocInsight}
            onClose={() => setModal(undefined)}
            onSubmit={onAdHocInsightDeleted}
          />
        ) : modal === "test" && selected ? (
          <TestProcessModal
            currentSelected={selected}
            onClose={() => setModal(undefined)}
          />
        ) : null}
      </div>
    </LoadilyFadily>
  );
};

export default withAlertModal(AdHocInsightsAdminPage);
