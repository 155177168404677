import {
  faBuilding,
  faCity,
  faCloudUpload,
  faFileCertificate,
  faGrinTears,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DashboardKindEnum } from "@joyhub-integration/shared";
import clsx from "clsx";
import React, { useCallback, useContext } from "react";
import { useDropzone } from "react-dropzone";
import { Link, useNavigate } from "react-router-dom";
import { useMedia } from "react-use";
import { Button, Card, ModalBody } from "reactstrap";

import { ReportSection } from "../../services/reportsService";
import PlatformContext from "../app/PlatformContext";
import ButtonWithIcon from "../common/button/ButtonWithIcon";
import UncontrolledModal from "../common/modal/UncontrolledModal";

const reports = [
  {
    kind: DashboardKindEnum.GenericReport,
    qs: "",
    title: "Portfolio Report",
    description: "Porfolio aggregate report.",
    icon: faCity,
  },
  {
    kind: DashboardKindEnum.GenericReport,
    qs: "&subkind=property",
    title: "Property Report",
    description: "Single property report.",
    icon: faBuilding,
  },
  {
    kind: DashboardKindEnum.Scorecard,
    qs: "",
    title: "Scorecard Email",
    description: "Standard portfolio health email.",
    icon: faFileCertificate,
  },
  {
    kind: DashboardKindEnum.JoyhubStats,
    qs: "",
    title: "Revolution RE Statistics",
    description: "Revolution RE statistics email.",
    icon: faGrinTears,
  },
] as const;

export const UploadStorageKey = "_upload";

const AddReportModal: React.FC<{
  toggle: () => void;
  section: ReportSection;
}> = ({ toggle, section }) => {
  const { superAdmin, overlordDomain } = useContext(PlatformContext).platform!;
  const navigate = useNavigate();

  const isWide = useMedia("(min-width: 1440px)");

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length) {
        const fr = new FileReader();
        fr.onload = (e) => {
          const str = e.target?.result as string;
          const defn = JSON.parse(str);
          if (typeof defn?.asOf !== "string" || !Array.isArray(defn?.sheets)) {
            alert("Bad bad bad");
          } else {
            const qs = defn.perProperty ? "&subkind=property" : "";
            sessionStorage.setItem(UploadStorageKey, str);
            navigate(
              `/reports/${section}/_new?kind=${DashboardKindEnum.GenericReport}&upload=1&${qs}`,
            );
          }
        };
        fr.readAsText(acceptedFiles[0]);
      }
    },
    [navigate, section],
  );

  const {
    getRootProps,
    getInputProps,
    inputRef,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: "application/json",
    noClick: true,
  });

  return (
    <UncontrolledModal size="lg" isOpen={true} onClosed={toggle}>
      <ModalBody>
        <div
          {...getRootProps()}
          className={clsx(
            "flex-row flex-wrap p-5 justify-content-center generic-drop",
            {
              isDragActive,
              isDragAccept,
              isDragReject,
            },
          )}
        >
          <ButtonWithIcon
            color="transparent"
            icon={faTimes}
            iconSize="2x"
            style={{
              position: "absolute",
              right: "-.5rem",
              top: "-.5rem",
              zIndex: 100,
              padding: ".75rem 1rem",
            }}
            onClick={toggle}
          />
          {reports
            .filter(
              ({ kind }) =>
                kind !== DashboardKindEnum.JoyhubStats ||
                (superAdmin && overlordDomain),
            )
            .map(({ kind, title, description, icon, qs }, index) => (
              <Card
                key={index}
                tag={Link}
                to={`/reports/${section}/_new?kind=${kind}${qs}`}
                className="jh-card flex-column align-items-center p-4 add-report-card text-decoration-none"
              >
                <FontAwesomeIcon
                  icon={icon}
                  size={isWide ? "6x" : "4x"}
                  className="color-pop"
                />
                <h5 className="mt-2 text-nowrap">{title}</h5>
                <div className="small text-muted text-center">
                  {description}
                </div>
              </Card>
            ))}
          {!superAdmin ? null : (
            <div
              className="position-absolute"
              style={{ right: "1rem", bottom: "1rem" }}
            >
              <input {...getInputProps()} />
              <Button
                outline
                className="border-0 px-1"
                onClick={() => inputRef.current?.click()}
                color="dark"
              >
                <FontAwesomeIcon icon={faCloudUpload} size="lg" />
              </Button>
            </div>
          )}
        </div>
      </ModalBody>
    </UncontrolledModal>
  );
};

export default AddReportModal;
