import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Badge } from "reactstrap";

import { apiUrl, axiosConfig } from "../../../utils/api";
import { dateOf, dateToTimeString } from "../../../utils/date";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import TableWithoutSelection from "../../common/table/TableWithoutSelection";
import { KeyValue } from "../../common/table/TableWithSelection";
import JhCrumbar from "../../navbar/JhCrumbar";

const Limit = 50;

type CommunicationLogEntry = {
  id: number;
  organization: string;
  date: string;
  bounced: boolean;
  opened: boolean;
  subject: string;
  email: string;
};

const getCommunicationsLog = (
  offset: number,
  limit: number,
): Promise<Array<CommunicationLogEntry>> => {
  return axios
    .get(
      apiUrl(`/communications/log?offset=${offset}&limit=${limit}`),
      axiosConfig,
    )
    .then((res) => res.data.emails);
};

const CommunicationsLogPage: React.FC<WithAlertModalProps> = ({
  onUnexpectedError,
}) => {
  const [emails, setEmails] = useState<Array<CommunicationLogEntry>>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [more, setMore] = useState<boolean>(false);

  const loadFiles = useCallback(
    (offset: number) => {
      setLoading(true);
      return getCommunicationsLog(offset, Limit)
        .then((ems) => {
          setEmails((e) => [...e.slice(0, offset), ...ems]);
          setMore(ems.length === Limit);
        })
        .catch(onUnexpectedError)
        .finally(() => setLoading(false));
    },
    [onUnexpectedError],
  );

  useEffect(() => {
    loadFiles(0).finally(() => setLoaded(true));
  }, [loadFiles]);

  const loadMore = () => {
    if (!loading) loadFiles(emails.length).then(() => {});
  };

  const tableCols: Array<KeyValue<CommunicationLogEntry>> = [
    {
      key: "date",
      title: "Date",
      toValue: (e) => dateToTimeString(dateOf(e.date)),
      sortValue: (e) => dateOf(e.date)?.getTime(),
    },
    {
      key: "email",
      title: "Recipient",
    },
    {
      key: "email_type",
      title: "Category",
    },
    {
      key: "subject",
      title: "Subject",
    },
    {
      key: "lastEvent",
      title: "Last Event",
      toValue: (e) => {
        if (e.bounced) {
          return <Badge color={"danger"}>Bounced</Badge>;
        } else if (e.opened) {
          return <Badge color={"success"}>Opened</Badge>;
        } else {
          return <Badge color={"secondary"}>Delivered</Badge>;
        }
      },
    },
    {
      key: "organization",
      title: "Organization",
    },
  ];

  return (
    <LoadilyFadily className="jh-page-layout" loaded={loaded}>
      <JhCrumbar
        primary="Administration"
        primaryPath="/admin"
        secondary={"Communications Log"}
      />
      <div className="jh-page-content admin-page">
        <div className="admin-section">
          <TableWithoutSelection
            columns={tableCols}
            rows={emails}
            sortDirection={"desc"}
            sortColumn={"date"}
            moar={more ? loadMore : undefined}
          />
        </div>
      </div>
    </LoadilyFadily>
  );
};

export default withAlertModal(CommunicationsLogPage);
