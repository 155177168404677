import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  PropertiesSelection,
  isPropertiesByGroup,
  isPropertiesComparison,
} from "@joyhub-integration/shared";
import React, { useContext, useMemo, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  UncontrolledTooltip,
} from "reactstrap";

import { usePropertiesSelectionQueryParam } from "../../utils/useQueryParams";
import PlatformContext from "../app/PlatformContext";
import { useOnUnexpectedError } from "../common/alert/withAlertModal";
import propertyGroupService, {
  PropertyGroup,
  PropertyGroupDto,
} from "../properties/propertyGroupService";

const PropertyGroupEditor: React.FC<{
  propertyGroup?: PropertyGroup;
  selection?: PropertiesSelection;
  onEdited: (group: number) => void;
  onDeleted?: (group: number) => void;
}> = ({ propertyGroup, selection, onEdited, onDeleted }) => {
  const [activeSelection] = usePropertiesSelectionQueryParam();
  const { updatePlatform } = useContext(PlatformContext);
  const onUnexpectedError = useOnUnexpectedError();

  const [groupName, setGroupName] = useState(propertyGroup?.name ?? "");
  const [groupShared, setGroupShared] = useState(
    !!propertyGroup?.shared_with_org,
  );
  const activeGroupId = useMemo(
    () =>
      activeSelection != null && isPropertiesByGroup(activeSelection)
        ? activeSelection.group
        : undefined,
    [activeSelection],
  );

  const reloadGroups = () =>
    propertyGroupService
      .getPropertyGroups()
      .then((propertyGroups) => updatePlatform({ propertyGroups }));

  const onPropertyGroupSave = () => {
    const newSelection = propertyGroup?.selection ?? selection;
    if (
      newSelection == null ||
      isPropertiesByGroup(newSelection) ||
      isPropertiesComparison(newSelection)
    )
      return;
    const propertyGroupDto: PropertyGroupDto = {
      name: groupName,
      selection: newSelection,
      sharedWithOrg: groupShared,
    };
    if (propertyGroup == null) {
      propertyGroupService
        .createPropertyGroup(propertyGroupDto)
        .then((g) => reloadGroups().then(() => onEdited(g.id)))
        .catch(onUnexpectedError);
    } else {
      propertyGroupService
        .updatePropertyGroup(propertyGroup.id, propertyGroupDto)
        .then((g) => reloadGroups().then(() => onEdited(g.id)))
        .catch(onUnexpectedError);
    }
  };

  const onPropertyGroupDelete = () => {
    if (propertyGroup == null) return;
    propertyGroupService
      .deletePropertyGroup(propertyGroup.id)
      .then(() => reloadGroups())
      .then(() => onDeleted?.(propertyGroup.id))
      .catch(onUnexpectedError);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div className="m-3">
        <div className="mb-3">
          <Input
            id="pg-name"
            type="text"
            placeholder="Property group name..."
            value={groupName}
            autoFocus
            required
            onChange={(e) => setGroupName(e.target.value)}
          />
        </div>
        <FormGroup check>
          <Input
            type="checkbox"
            checked={groupShared}
            onChange={() => setGroupShared(!groupShared)}
          />
          <Label check onClick={() => setGroupShared(!groupShared)}>
            Shared{" "}
            <FontAwesomeIcon
              id="share-group-info"
              size="xs"
              color="#666"
              icon={faQuestionCircle}
            />
            <UncontrolledTooltip target="share-group-info" placement="right">
              Share this property group with your organization
            </UncontrolledTooltip>
          </Label>
        </FormGroup>
      </div>
      <div className="m-3 d-flex flex-row">
        {propertyGroup == null ? null : (
          <Button
            color="danger"
            onClick={onPropertyGroupDelete}
            disabled={propertyGroup.id === activeGroupId}
            className="me-2 flex-grow-1"
          >
            Delete
          </Button>
        )}
        <Button
          color="primary"
          onClick={onPropertyGroupSave}
          disabled={!groupName}
          className="flex-grow-1"
        >
          {propertyGroup != null ? "Update" : "Save"}
        </Button>
      </div>
    </div>
  );
};

export default PropertyGroupEditor;
