import {
  faCloudDownload,
  faCopy,
  faEdit,
  faFileDownload,
} from "@fortawesome/pro-light-svg-icons";
import { useContext, useEffect, useMemo, useState } from "react";

import { Input } from "reactstrap";
import dashboardService, {
  Dashboard,
  propertyDashboardUrl,
} from "../../services/dashboardService";
import {
  deleteScheduledEmail,
  getScheduledEmail,
  getScheduledEmails,
  ScheduledEmail,
} from "../../services/scheduledEmailsService";
import { usePropertiesSelectionQueryParam } from "../../utils/useQueryParams";
import DeleteModal from "../admin/common/DeleteModal";
import PlatformContext from "../app/PlatformContext";
import ButtonWithIcon from "../common/button/ButtonWithIcon";
import EmbedLink from "../craport/EmbedLink";
import ScheduledEmails from "../craport/ScheduledEmails";
import SendScheduleEmail from "../craport/SendScheduleEmail";
import JhCrumbar from "../navbar/JhCrumbar";
import PropertiesPicker from "../picker/PropertiesPicker";
import { PropertyGroup } from "../properties/propertyGroupService";
import DashboardSwitcher from "./DashboardSwitcher";

interface DashboardHeaderPropsType {
  dashboard: Dashboard;
  dashboardName: string;
  dashboards: Dashboard[];
  editMode: boolean;
  pdfDownloading: boolean;
  propertyGroups: PropertyGroup[];
  propertyId?: number;
  onDownloadDefinition: () => void;
  onDownloadPDF: () => void;
  onCopy: () => void;
  onEdit: () => void;
  onUnexpectedError: (e?: any) => void;
  setAlert: (message: string, success: boolean) => void;
  setDashboardName: (value: string) => void;
}

const DashboardHeader = (props: DashboardHeaderPropsType) => {
  const {
    dashboard,
    editMode,
    dashboardName,
    onEdit,
    onCopy,
    onDownloadDefinition,
    onDownloadPDF,
    onUnexpectedError,
    setAlert,
    setDashboardName,
    dashboards,
    propertyGroups,
    propertyId: basePropertyId,
    pdfDownloading,
  } = props;

  const platform = useContext(PlatformContext).platform!;
  const { propertiesMap, admin, superAdmin, organization_role, embedInfo } =
    platform;

  const [selection] = usePropertiesSelectionQueryParam();
  const properties = useMemo(
    () => Object.values(propertiesMap),
    [propertiesMap],
  );

  const propertyId =
    embedInfo?.restricted && properties.length === 1
      ? properties[0].id
      : basePropertyId;

  const [viewScheduledEmailsModalOpen, setViewScheduledEmailsModalOpen] =
    useState(false);
  const [scheduledEmails, setScheduledEmails] = useState<ScheduledEmail[]>([]);
  const [scheduledEmailToBeRemoved, setScheduledEmailToBeRemoved] =
    useState<ScheduledEmail>();

  const dashboardHeading = useMemo(
    () =>
      propertyId == null
        ? dashboard.name !== ""
          ? dashboard.name
          : "Portfolio"
        : (propertiesMap[propertyId]?.property_name ?? "Property"),
    [propertyId, dashboard.name, propertiesMap],
  );

  const linkPrefix =
    propertyId == null ? "/dashboards" : `/properties/${propertyId}/dashboards`;

  const titleRedirect =
    propertyId && !embedInfo ? propertyDashboardUrl(propertyId) : undefined;

  const invisibleBar = editMode || (embedInfo && propertyId != null);

  useEffect(() => {
    if (admin && dashboard) {
      getScheduledEmails(dashboard.id, propertyId)
        .then((scheduledEmails) => setScheduledEmails(scheduledEmails))
        .catch(onUnexpectedError);
    }
  }, [admin, dashboard, propertyId, onUnexpectedError]);

  function onScheduledEmailsChange() {
    getScheduledEmails(dashboard.id, propertyId)
      .then(setScheduledEmails)
      .catch(onUnexpectedError);
  }

  function onScheduledEmailRemoveClick(scheduledEmail: ScheduledEmail) {
    setScheduledEmailToBeRemoved(scheduledEmail);
  }

  function onScheduledEmailDeleteSubmit() {
    setAlert("Scheduled email deleted successfully.", true);
    setScheduledEmailToBeRemoved(undefined);
    onScheduledEmailsChange();
  }

  return (
    <>
      {!editMode ? (
        <JhCrumbar
          primary={dashboardHeading}
          primaryPath={linkPrefix}
          titleRedirect={titleRedirect}
          noShadow
        >
          {platform?.embedInfo ? null : (
            <div className="ms-auto">
              <DashboardSwitcher
                activeDashboardId={dashboard.id}
                dashboards={dashboards}
                linkPrefix={linkPrefix}
              />
            </div>
          )}
        </JhCrumbar>
      ) : (
        <div
          className="d-flex align-items-center justify-content-start px-3"
          style={{ height: 68 }}
        >
          <Input
            type="text"
            id="dashboard-name"
            required
            style={{ width: 300 }}
            value={dashboardName}
            onChange={(e) => setDashboardName(e.target.value)}
          />
        </div>
      )}
      {invisibleBar ? null : (
        <div className="dashboard-filters d-flex flex-md-row align-items-md-center pb-3 px-4 gap-1 justify-content-between jh-crumbar-like scrolly-box-shadow flex-column align-items-end">
          <div className="flex-row">
            {superAdmin && (
              <ButtonWithIcon
                icon={faCloudDownload}
                tooltip="Download Definition"
                className="jh-action-icon"
                id="download-definition"
                onClick={onDownloadDefinition}
              />
            )}
            {(organization_role == "Administrator" ||
              organization_role == "Editor") && (
              <ButtonWithIcon
                icon={faCopy}
                tooltip="Copy Dashboard"
                className="jh-action-icon"
                onClick={onCopy}
                id="copy-dashboard"
              />
            )}
            {(organization_role == "Administrator" ||
              organization_role == "Editor") && (
              <ButtonWithIcon
                icon={faFileDownload}
                tooltip="Download Dashboard PDF"
                className="jh-action-icon"
                onClick={onDownloadPDF}
                disabled={pdfDownloading}
                id="download-dashboard-pdf"
                style={pdfDownloading ? { cursor: "wait" } : {}}
              />
            )}
            {organization_role == "Administrator" ||
            organization_role == "Editor" ? (
              <SendScheduleEmail
                id={dashboard.id}
                sendEmail={(recipients) =>
                  dashboardService.sendDashboard(dashboard.id, {
                    properties: selection!,
                    recipients,
                    propertyId,
                  })
                }
                isDashboard
                propertyId={propertyId}
                scheduledEmails={scheduledEmails}
                onScheduledEmailsChange={onScheduledEmailsChange}
                onViewScheduledEmailsClick={() =>
                  setViewScheduledEmailsModalOpen(true)
                }
              />
            ) : null}
            {organization_role == "Administrator" ? (
              <EmbedLink
                kind="dashboard"
                identifier={dashboard.identifier}
                propertyId={propertyId}
              />
            ) : null}
            {(organization_role == "Administrator" ||
              organization_role == "Editor") &&
              !dashboard.immutable && (
                <ButtonWithIcon
                  icon={faEdit}
                  tooltip="Edit Dashboard"
                  className="jh-action-icon"
                  onClick={onEdit}
                  id="edit-dashboard"
                  iconStyle={{ marginLeft: ".2em" }}
                />
              )}
          </div>
          <div className="flex-row">
            {propertyId != null || !properties.length ? null : (
              <PropertiesPicker
                allProperties={properties}
                propertyGroups={propertyGroups}
                right={!superAdmin}
              />
            )}
          </div>
          {viewScheduledEmailsModalOpen ? (
            <ScheduledEmails
              onClose={() => setViewScheduledEmailsModalOpen(false)}
              onScheduledEmailsChange={onScheduledEmailsChange}
              scheduledEmails={scheduledEmails}
              onRemoveClick={onScheduledEmailRemoveClick}
            />
          ) : null}
          {scheduledEmailToBeRemoved ? (
            <DeleteModal<ScheduledEmail>
              id={scheduledEmailToBeRemoved.id}
              entityName="Scheduled Email"
              identificationKey="id"
              getEntity={getScheduledEmail}
              deleteEntity={deleteScheduledEmail}
              onClose={() => setScheduledEmailToBeRemoved(undefined)}
              onSubmit={onScheduledEmailDeleteSubmit}
            />
          ) : null}
        </div>
      )}
    </>
  );
};

export default DashboardHeader;
