import "./grid-layout.scss";
import "./layout.css";

import clsx from "clsx";
import React, {
  CSSProperties,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { createBreakpoint, useClickAway, useWindowScroll } from "react-use";

import { Platform } from "../../services/platformService";

interface LayoutProps {
  children: React.ReactNode;
  Sidebar?: React.FC<{
    toggleSidebar?: () => void;
    sidebarOpen?: Record<string, boolean | undefined>;
    breakpoint?: string;
  }>;
  platform?: Platform;
}

const breakpoints = { xs: 0, sm: 576, lg: 992 };
const useBreakpoint = createBreakpoint(
  breakpoints,
) as () => keyof typeof breakpoints;

type SidebarOpenAtBreakpoint = {
  [Key in keyof typeof breakpoints]?: boolean;
};

const isFirefox = () => {
  //keep them in this order
  //the earlier ones includes the later ones
  if (navigator.appVersion.match(/Edge\//)) {
    return false;
  } else if (navigator.appVersion.match(/like Gecko$/)) {
    return false;
  } else if (navigator.appVersion.match(/Chrome\//)) {
    return false;
  } else if (navigator.appVersion.match(/Safari\//)) {
    return false;
  } else {
    return true;
  }
};

// https://caniuse.com/?search=vw
// Currently all browsers but Firefox incorrectly consider 100vw to be the entire page width, including vertical scroll bar
const computeScrollbarDimensions = () => {
  const ff = isFirefox();
  const el = document.documentElement;
  return {
    scrollbarWidth: ff ? 0 : window.innerWidth - el.clientWidth,
    scrollbarHeight: ff ? 0 : window.innerHeight - el.clientHeight,
  };
};

const Layout: React.FC<LayoutProps> = ({ children, platform, Sidebar }) => {
  const sidebarRef = useRef<HTMLDivElement>(null);

  const breakpoint = useBreakpoint();

  const [sidebarOpen, setSidebarOpen] = useReducer(
    (state: SidebarOpenAtBreakpoint, newState: SidebarOpenAtBreakpoint) => ({
      ...state,
      ...newState,
    }),
    Sidebar !== null ? { lg: true } : {},
  );

  const { y } = useWindowScroll();

  const { scrollbarWidth, scrollbarHeight } = computeScrollbarDimensions();

  const cssVariables = useMemo(
    () =>
      ({
        "--scrollbar-width": `${scrollbarWidth}px`,
        "--scrollbar-height": `${scrollbarHeight}px`,
      }) as CSSProperties,
    [scrollbarWidth, scrollbarHeight],
  );

  // collapse sidebar on small breakpoint whenever breakpoint changes
  useEffect(() => {
    setSidebarOpen({ sm: false, xs: false });
  }, [breakpoint]);

  // collapse sidebar on small breakpoint on any click outside the sidebar
  useClickAway(sidebarRef, () => {
    setSidebarOpen({ sm: false, xs: false });
  });

  useEffect(() => {
    if (
      platform?.organization?.domains?.includes("joyhub.io") ||
      platform?.superAdmin
    ) {
      document.body.classList.add("agitated");
    } else {
      document.body.classList.remove("agitated");
    }
  }, [platform]);

  const toggleSidebar = () => {
    setSidebarOpen({ [breakpoint]: !sidebarOpen[breakpoint] });
  };

  return (
    <div
      className={clsx(
        "layout",
        Sidebar === undefined
          ? "no_sidebar"
          : sidebarOpen[breakpoint]
            ? "sidebar-open"
            : "sidebar-closed",
        breakpoint === "sm" ? "sm-sidebar" : "",
        breakpoint === "xs" ? "xs-sidebar" : "",
        breakpoint,
        y > 0 && "window-scrolled",
      )}
      style={cssVariables}
    >
      <div ref={sidebarRef} className="sidebar">
        {Sidebar ? (
          <Sidebar
            toggleSidebar={toggleSidebar}
            sidebarOpen={sidebarOpen}
            breakpoint={breakpoint}
          />
        ) : undefined}
      </div>
      <div className="content">{children}</div>
    </div>
  );
};

export default Layout;
