import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SystemRole } from "@joyhub-integration/shared";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { Report } from "../../../services/models";
import { ReportSection } from "../../../services/reportsService";
import { dateToTimeString } from "../../../utils/date";
import PlatformContext from "../../app/PlatformContext";

export interface ReportCardProps {
  report: Report;
  onDelete: () => void;
  section: ReportSection;
}

const ReportCard: React.FC<ReportCardProps> = ({
  report,
  onDelete,
  section,
}) => {
  const { admin, organization_role } = useContext(PlatformContext).platform!;
  const isFinance = report.kind === "Finance";

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [rowActive, setRowActive] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const title = (
    <div className="flex-row align-items-baseline">
      <div className="me-1 text-overflow-ellipsis">{report.name}</div>
    </div>
  );

  const cellClass = `align-middle ${rowActive ? "bg-highlight" : "bg-white"}`;

  return (
    <tr
      className="position-relative report-row"
      style={{ height: "4em" }}
      onMouseEnter={() => setRowActive(true)}
      onFocus={() => setRowActive(true)}
      onMouseLeave={() => setRowActive(false)}
      onBlur={() => setRowActive(false)}
    >
      <th scope="row" className={`${cellClass} ps-4 rounded-start`}>
        <Link
          to={report.reportUrl ?? `/reports/${section}/${report.identifier}`}
          className="stretched-link"
        >
          {title}
        </Link>
      </th>
      {!isFinance && section === "my" ? (
        <td className={cellClass}>{report.editor_name ?? ""}</td>
      ) : null}
      <td className={cellClass}>
        {isFinance ? <>&nbsp;</> : dateToTimeString(new Date(report.created))}
      </td>
      <td className={cellClass}>
        {isFinance ? <>&nbsp;</> : dateToTimeString(new Date(report.edited))}
      </td>
      <td className={cellClass}>
        {!report.category?.length ? (
          <span>&nbsp;</span>
        ) : (
          report.category.map((category, index) => {
            return (
              <Badge
                color="light"
                key={index}
                className="me-1 py-1 px-2 fw-normal rounded-0 border border-primary bg-none text-primary"
              >
                {category}
              </Badge>
            );
          })
        )}
      </td>
      {isFinance
        ? null
        : organization_role !== SystemRole.byName("Viewer") && (
            <td
              className={`${cellClass} text-right rounded-end`}
              style={{ width: "5em" }}
            >
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                className="position-relative"
              >
                <DropdownToggle color="white">
                  <FontAwesomeIcon icon={faEllipsisVertical} />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    id={`edit-${report.id}`}
                    tag={Link}
                    to={`/reports/${section}/${report.id}/edit`}
                    disabled={report.immutable}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    id={`copy-${report.id}`}
                    tag={Link}
                    to={`/reports/${section}/_new?copy=${report.id}`}
                  >
                    Copy
                  </DropdownItem>
                  <DropdownItem id={`delete-${report.id}`} onClick={onDelete}>
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </td>
          )}
    </tr>
  );
};

export default ReportCard;
