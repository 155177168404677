import { Buffer } from "buffer";

import {
  CustomColumn,
  NonGroupPropertiesSelection,
  SystemRole,
} from "@joyhub-integration/shared";
import { AxiosResponse } from "axios";

import {
  apiUrl,
  axiosBlobConfig,
  axiosConfig,
  axiosJsonConfig,
} from "../utils/api";
import axios from "./axios";

export interface Role {
  id: number;
  name: string;
  properties_selection: NonGroupPropertiesSelection | null;
}

export interface User {
  id: number;
  organizationId: number;
  name: string;
  email: string;
  active: boolean;
  organization_role: SystemRole;
  roles: Array<Role>;
  last_login?: string;
}

export type ApplicationType = "BI" | "PC" | "DV";

export type NavbarTheme = "dark" | "light";

export interface WithId {
  id: number;
}

export interface BaseOrganization {
  name: string;
  domains: string[];
  application: ApplicationType;
  configuration: {
    navbarTheme?: NavbarTheme;
    logo?: string;
    sesTls?: boolean;
    commonInsights?: boolean;
    // columns?: MetadataColumn[]; // dead
    customColumns?: CustomColumn[];
    activeCampaignId?: number;
    colors?: string[];
  };
  slack_channel: string;
  slack_enabled: boolean;
  hs_record_id: string;
}

export interface Organization extends BaseOrganization, WithId {}

export interface AddEditUserDto {
  email: string;
  name: string;
  active: boolean;
  organization_role: SystemRole;
  roles: number[];
  emailInvite?: boolean;
}

export interface UserResponse {
  person: User;
  error?: any;
}

export async function getRoles(): Promise<Array<Role>> {
  return axios
    .get(apiUrl("/roles"), axiosConfig)
    .then((res) => res.data.roles as Array<Role>);
}

export async function getUsers(): Promise<Array<User>> {
  return axios
    .get(apiUrl("/people"), axiosConfig)
    .then((res) => res.data.people as Array<User>);
}

export async function getUserById(id: number): Promise<User> {
  return axios
    .get(apiUrl(`/people/${id}`), axiosConfig)
    .then((res) => res.data as User);
}

export async function importUsers(
  emailInvites: boolean,
  ignoreWarnings: boolean,
  file: File,
): Promise<AxiosResponse> {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () =>
      resolve(
        axios.put(
          apiUrl("/people/import"),
          {
            emailInvites,
            ignoreWarnings,
            body: Buffer.from(fr.result as ArrayBuffer).toString("base64"),
          },
          axiosJsonConfig,
        ),
      );
    fr.onerror = () => reject(`Error reading file.`);
    fr.readAsArrayBuffer(file);
  });
}

export async function exportUsers(): Promise<AxiosResponse> {
  return axios.get(apiUrl("/people/export"), axiosBlobConfig);
}

export async function createUser(
  userDto: AddEditUserDto,
): Promise<UserResponse> {
  return axios
    .post(apiUrl("/people"), userDto, axiosConfig)
    .then((res) => res.data as UserResponse);
}

export async function editUser(
  id: number,
  userDto: AddEditUserDto,
): Promise<UserResponse> {
  return axios
    .put(apiUrl(`/people/${id}`), userDto, axiosConfig)
    .then((res) => res.data as UserResponse);
}

export async function getInviteTemplate(
  id: number,
  password: string | undefined,
): Promise<string> {
  return axios
    .post(apiUrl(`/people/${id}/inviteTemplate`), { password }, axiosConfig)
    .then((res) => res.data as string);
}

export async function getResetTemplate(
  id: number,
  password: string | undefined,
): Promise<string> {
  return axios
    .post(apiUrl(`/people/${id}/resetTemplate`), { password }, axiosConfig)
    .then((res) => res.data as string);
}

export async function reinviteUser(
  id: number,
  password: string | undefined,
): Promise<boolean> {
  return axios
    .post(apiUrl(`/people/${id}/reinvite`), { password }, axiosConfig)
    .then((res) => res.data as boolean);
}

export async function resetUserPassword(
  id: number,
  password: string | undefined,
): Promise<boolean> {
  return axios
    .post(apiUrl(`/people/${id}/resetPassword`), { password }, axiosConfig)
    .then((res) => res.data as boolean);
}

export async function deleteUser(id: number): Promise<void> {
  return axios.delete(apiUrl(`/people/${id}`), axiosConfig);
}
