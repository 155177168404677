import { faShare, faShareAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isAdministrator } from "@joyhub-integration/shared";
import clsx from "clsx";
import { orderBy } from "natural-orderby";
import React, { useContext, useMemo, useState } from "react";
import {
  Button,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Popover,
} from "reactstrap";

import PlatformContext from "../app/PlatformContext";
import { PropertyGroup } from "../properties/propertyGroupService";
import PropertyGroupEditor from "./PropertyGroupEditor";

const ManagePropertyGroupsModal: React.FC<{
  propertyGroups: PropertyGroup[];
  toggle: () => void;
}> = ({ propertyGroups, toggle }) => {
  const { platform } = useContext(PlatformContext);
  const { organization_role, person } = platform!;
  const [propertyGroup, setPropertyGroup] = useState<PropertyGroup>();
  const [edit, setEdit] = useState(false);

  const sortedGroups = useMemo(
    () => orderBy(propertyGroups, (g) => g.name, "asc"),
    [propertyGroups],
  );

  const canEdit = useMemo(
    () =>
      propertyGroup &&
      (isAdministrator(organization_role) ||
        propertyGroup.creator_id === person.id),
    [propertyGroup, organization_role, person],
  );

  const finishEdit = () => {
    setPropertyGroup(undefined);
    setEdit(false);
  };

  return (
    <Modal toggle={toggle} isOpen>
      <ModalHeader>Manage Property Groups</ModalHeader>
      <ModalBody className="px-0">
        <div
          style={{ minHeight: "6rem", maxHeight: "50vh", overflowY: "scroll" }}
        >
          {!sortedGroups.length ? (
            <div className="dashboard-property-dropdown-item">
              No property groups
            </div>
          ) : null}

          {sortedGroups.map((g) => (
            <DropdownItem
              key={g.id}
              className={clsx(
                "dashboard-property-dropdown-item bordered d-flex pe-2 align-items-baseline",
                {
                  active: g === propertyGroup,
                },
              )}
              onClick={() => setPropertyGroup(g)}
              toggle={false}
            >
              <div className="flex-grow-1" style={{ minWidth: 0 }}>
                {g.name}
                {!g.shared_with_org ? null : (
                  <FontAwesomeIcon
                    className="ms-2"
                    icon={g.creator_id === person.id ? faShare : faShareAlt}
                    size="xs"
                    style={{ verticalAlign: "baseline" }}
                    title={
                      g.creator_id === person.id
                        ? "Shared by you"
                        : "Shared with you"
                    }
                  />
                )}
              </div>
            </DropdownItem>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        {isAdministrator(organization_role) && (
          <>
            <Popover
              target="edit-property-group"
              isOpen={edit}
              placement="bottom-start"
            >
              {!propertyGroup ? null : (
                <PropertyGroupEditor
                  propertyGroup={propertyGroup}
                  onEdited={finishEdit}
                  onDeleted={finishEdit}
                />
              )}
            </Popover>
            <Button
              id="edit-property-group"
              outline
              color="primary"
              className={clsx("ms-2", { active: edit })}
              disabled={!canEdit}
              onClick={() => setEdit(!edit)}
            >
              Edit property group
            </Button>
          </>
        )}
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ManagePropertyGroupsModal;
