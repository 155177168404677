import {
  faCircleInfo,
  faCogs,
  faDownload,
  faPlus,
  faRedo,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FinancialImportDto } from "@joyhub-integration/shared";
import React, { useCallback, useEffect, useState } from "react";

import { dateToTimeString } from "../../../utils/date";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ActionBar from "../../common/button/ActionBar";
import { ButtonWithIconProps } from "../../common/button/ButtonWithIcon";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";
import JhCrumbar from "../../navbar/JhCrumbar";
import AddFinancialImportModal from "./AddFinancialImportModal";
import DeleteFinancialImportModal from "./DeleteFinancialImportModal";
import {
  financialImportDownloadUrl,
  getFinancialImports,
  reingestFinancialImport,
} from "./financialImportsApi";
import InfoFinancialImportModal from "./InfoFinancialImportModal";

const Limit = 50;

const FinancialImportsAdminPage: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [imports, setImports] = useState<Array<FinancialImportDto<true>>>([]);
  const [more, setMore] = useState(false);
  const [selectedId, setSelectedId] = useState<number>();
  const selected = imports?.find((i) => i.id === selectedId);
  const [modal, setModal] = useState<"add" | "info" | "delete">();
  const closeModal = () => setModal(undefined);

  const loadImports = useCallback(
    (offset: number) => {
      setLoading(true);
      return getFinancialImports(offset, Limit)
        .then((imports) => {
          setImports((fis) => [...fis.slice(0, offset), ...imports]);
          setMore(imports.length === Limit);
        })
        .catch(onUnexpectedError)
        .finally(() => setLoading(false));
    },
    [onUnexpectedError],
  );

  useEffect(() => {
    loadImports(0).finally(() => setLoaded(true));
  }, [loadImports, refresh]);

  const loadMore = () => {
    if (!loading) loadImports(imports.length).then(() => {});
  };

  const onImportsChanged = () => {
    loadImports(0).finally(closeModal);
  };

  const buttonProps: ButtonWithIconProps[] = [
    {
      label: "Import Financials",
      icon: faPlus,
      color: "success",
      onClick: () => setModal("add"),
    },
    {
      icon: faCircleInfo,
      tooltip: "Import Summary",
      disabled: selected == null,
      onClick: () => setModal("info"),
    },
    {
      icon: faDownload,
      tooltip: "Download Statement",
      disabled: selected == null,
      download: true,
      href:
        selected == null ? undefined : financialImportDownloadUrl(selected.id),
    },
    {
      icon: faCogs,
      tooltip: "Reingest File",
      disabled: selected == null,
      onClick: () =>
        reingestFinancialImport(selected!.id)
          .then(() => setAlert("Ingest started", true))
          .catch(onUnexpectedError),
    },
    {
      icon: faTrash,
      tooltip: "Delete Import File",
      color: "danger",
      disabled: selected == null,
      onClick: () => setModal("delete"),
    },
  ];

  const rightButtonProps = [
    {
      icon: faRedo,
      onClick: () => setRefresh((r) => 1 + r),
      outline: true,
      tooltip: "Refresh",
      disabled: loading,
    },
  ];

  const tableCols: Array<KeyValue<FinancialImportDto<true>>> = [
    {
      key: "description",
      title: "Description",
    },
    {
      key: "creator_name",
      title: "Importer",
    },
    {
      key: "created",
      title: "Import Date",
      toValue: (i) => dateToTimeString(new Date(i.created)),
      sortValue: (i) => i.created,
    },
  ];

  return (
    <LoadilyFadily className="jh-page-layout" loaded={loaded}>
      <JhCrumbar
        primary="Administration"
        primaryPath="/admin"
        secondary="Manage Financial Imports"
      />
      <div className="jh-page-content integrations-admin-page admin-page">
        <ActionBar
          buttonProps={buttonProps}
          rightButtonProps={rightButtonProps}
        />
        <TableWithSelection<FinancialImportDto<true>>
          selected={selected}
          onSelectedChange={(s) => setSelectedId(s?.id)}
          columns={tableCols}
          rows={imports}
          sortColumn="created"
          sortDirection="desc"
          moar={more ? loadMore : undefined}
        />
        {modal === "add" && (
          <AddFinancialImportModal
            onSubmit={onImportsChanged}
            onClose={closeModal}
          />
        )}
        {modal === "info" && selected != null && (
          <InfoFinancialImportModal value={selected} onClose={closeModal} />
        )}
        {modal === "delete" && selected != null && (
          <DeleteFinancialImportModal
            value={selected}
            onSubmit={onImportsChanged}
            onClose={closeModal}
          />
        )}
      </div>
    </LoadilyFadily>
  );
};

export default withAlertModal(FinancialImportsAdminPage);
