import { useCallback, useEffect, useState } from "react";

import { find } from "lodash";
import { useQuill } from "react-quilljs";
import { HtmlInsightId } from "../../../../services/insightLibrary/insightDefinitions";
import insightLibraryService from "../../../../services/insightLibrary/insightsLibraryService";
import { ValidationError } from "../../../admin/integrations/addEditIntegrationModal/common";

interface HtmlCardPropsType {
  dashboardInsight?: Record<string, any>;
  validationError: ValidationError;
  onClose: () => void;
  onSubmit: () => void;
  onUnexpectedError: (e?: any) => void;
  updateDashboardInsight: (fieldName: string, newData: any) => void;
}

export const HtmlCard = (props: HtmlCardPropsType) => {
  const { dashboardInsight, onUnexpectedError, updateDashboardInsight } = props;

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        ["link"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
      ],
    },
  });

  const updateHtmlContent = useCallback(
    (content?: string) => {
      updateDashboardInsight("stuff", {
        htmlContent: content,
      });
    },
    [updateDashboardInsight],
  );

  useEffect(() => {
    if (quill) {
      quill.on("text-change", () => {
        updateHtmlContent(quill.root.innerHTML);
      });
    }
  }, [quill]);

  useEffect(() => {
    if (quill && dashboardInsight && !isLoaded) {
      quill.clipboard.dangerouslyPasteHTML(
        dashboardInsight?.stuff?.htmlContent ?? "<div></div>",
      );
      updateDashboardInsight("linkedReport", undefined);
      updateDashboardInsight("name", "HTML card");
      setIsLoaded(true);
    }
  }, [dashboardInsight]);

  useEffect(() => {
    const setupInitialData = async () => {
      try {
        const insights = await insightLibraryService.getInsights();
        const htmlInsight = find(insights, { id: HtmlInsightId });

        updateDashboardInsight("insight", htmlInsight);
      } catch (error) {
        onUnexpectedError(error);
      }
    };

    setupInitialData();
  }, [onUnexpectedError, updateDashboardInsight]);

  return (
    <div style={{ padding: 10 }}>
      <div style={{ width: "100%", minHeight: 200 }}>
        <div ref={quillRef} />
      </div>
    </div>
  );
};
