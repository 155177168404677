import "./craport.css";

import {
  faArrowsUpDownLeftRight,
  faCalendarAlt,
  faClose,
  faCloudDownload,
  faCopy,
  faEdit,
  faPrint,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AttachmentType,
  DashboardKindEnum,
  GenericReportData,
  GenericReportDefinition,
  PropertiesSelection,
  PureDate,
} from "@joyhub-integration/shared";
import axios from "axios";
import clsx from "clsx";
import fileDownload from "js-file-download";
import React, { useContext, useEffect, useMemo, useState } from "react";
import DatePicker from "react-date-picker";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Nav, NavItem, NavLink } from "reactstrap";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";

import dashboardService from "../../services/dashboardService";
import {
  getActualReport,
  SingleReportResult,
} from "../../services/reportsService";
import {
  deleteScheduledEmail,
  getScheduledEmail,
  getScheduledEmails,
  ScheduledEmail,
  SendEmailResult,
} from "../../services/scheduledEmailsService";
import { apiUrl, axiosBlobConfig, axiosJsonConfig } from "../../utils/api";
import { downloadAttachment } from "../../utils/download";
import {
  asPropertiesQuery,
  usePropertiesSelectionQueryParam,
} from "../../utils/useQueryParams";
import DeleteModal from "../admin/common/DeleteModal";
import PlatformContext from "../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";
import { LoadilyFadily } from "../common/allFadily";
import ButtonWithIcon from "../common/button/ButtonWithIcon";
import PrintHeader from "../common/PrintHeader";
import JhCrumbar from "../navbar/JhCrumbar";
import PropertiesPicker from "../picker/PropertiesPicker";
import CopyModal from "./CopyModal";
import DownloadReport from "./DownloadReport";
import { preventer } from "./edit/GenericReportEditorActionBar";
import EmbedLink from "./EmbedLink";
import { ReportContent } from "./ReportContent";
import ScheduledEmails from "./ScheduledEmails";
import SendScheduleEmail from "./SendScheduleEmail";

export type ReportConfig = {
  properties: PropertiesSelection;
  asOf?: PureDate;
  attachmentType?: AttachmentType;
  sheetNumber?: number;
};

type SendRequest = ReportConfig & {
  recipients: string[];
};

const sendReport = async (id: number, request: SendRequest) =>
  axios
    .post<SendEmailResult>(
      apiUrl(`/reports/${id}/send`),
      request,
      axiosJsonConfig,
    )
    .then((res) => res.data);

export type RenderedResult = {
  subject: string;
  body: string;
};

export const renderReport = async (
  report: number | string,
  configuration: ReportConfig,
) =>
  axios
    .post<RenderedResult>(
      apiUrl(`/reports/${report}/render`),
      configuration,
      axiosJsonConfig,
    )
    .then((res) => res.data);

type DataRequest = ReportConfig & {
  sheet: string | number;
};

export const getReportData = async (
  report: number | string,
  configuration: DataRequest,
) =>
  axios
    .post<
      GenericReportData<true>
    >(apiUrl(`/reports/${report}/data`), configuration, axiosJsonConfig)
    .then((res) => res.data);

const downloadReport = async (
  report: number,
  configuration: ReportConfig & { template: number | undefined },
) =>
  axios.post(
    apiUrl(`/reports/${report}/download`),
    configuration,
    axiosBlobConfig,
  );

type DownloadName = {
  id: number | undefined;
  filename: string;
};

const getReportDownloadNames = async (
  report: number | string,
  configuration: ReportConfig,
) => {
  return axios
    .post<{
      files: DownloadName[];
    }>(
      apiUrl(`/reports/${report}/downloadNames`),
      configuration,
      axiosJsonConfig,
    )
    .then((res) => res.data.files);
};

type CraportParams = {
  report: string;
  section: string;
};

const Craport: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const params = useParams<CraportParams>();
  const reportIdentifier = params.report!;
  const section = params.section;
  const {
    propertiesMap,
    propertyGroups,
    person,
    admin,
    superAdmin,
    organization_role,
    embedInfo,
  } = useContext(PlatformContext).platform!;
  const [selection] = usePropertiesSelectionQueryParam();
  const [sheetName, setSheet] = useQueryParam("sheet", StringParam);
  const [exportPDF] = useQueryParam("pdf", BooleanParam);
  const [loaded, setLoaded] = useState(false);
  const [info, setInfo] = useState<SingleReportResult>();
  const [rendered, setRendered] = useState<RenderedResult>();
  const [genericReportData, setGenericReportData] =
    useState<GenericReportData<true>>();
  const [templateFiles, setTemplateFiles] = useState<DownloadName[]>();
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [viewScheduledEmailsModalOpen, setViewScheduledEmailsModalOpen] =
    useState(false);
  const [scheduledEmails, setScheduledEmails] = useState<ScheduledEmail[]>([]);
  const [scheduledEmailToBeRemoved, setScheduledEmailToBeRemoved] =
    useState<ScheduledEmail>();
  const [date, setDate] = useState<Date | null>(null);
  const navigate = useNavigate();
  const [maximized, setMaximized] = useState(false);
  const [copying, setCopying] = useState(false);

  useEffect(() => {
    let identifier = reportIdentifier;

    if (embedInfo) identifier = embedInfo.identifier;

    getActualReport(identifier)
      .then((report) => {
        setInfo(report);
        dashboardService.viewDashboard(report.id);
        try {
          window.analytics.track("Report Viewed", {
            reportName: report.name,
            reportId: report.id,
            userId: person.id,
          });
        } catch (e) {
          console.error("Unable to fire segment track event", e);
        }
        if (report.kind === DashboardKindEnum.FinanceReport) {
          const { period, tree, book } = report.definition;
          const edit = report.immutable ? "" : `?id=${report.id}`;
          navigate(
            `/reports/${section}/finance/${tree}/${book}/${period}${edit}`,
            { replace: true },
          );
        } else {
          setLoaded(true);
        }
      })
      .catch(onUnexpectedError);
  }, [
    onUnexpectedError,
    reportIdentifier,
    person,
    admin,
    navigate,
    embedInfo,
    section,
  ]);

  useEffect(() => {
    if (admin && info != null) {
      getScheduledEmails(info.id)
        .then((scheduledEmails) => setScheduledEmails(scheduledEmails))
        .catch(onUnexpectedError);
    }
  }, [admin, info, onUnexpectedError]);

  useEffect(() => {
    setTimeout(() => {
      const kids = document.body.children;
      for (let i = 0; i < kids.length; ++i) {
        const e = kids.item(i) as HTMLDivElement | null;
        if (e?.tagName == "DIV" && e.style.visibility == "visible")
          e.classList.add("zendesk");
      }
    }, 100);
  }, []);

  const genericReportDefinition =
    info?.kind === DashboardKindEnum.GenericReport
      ? (info.definition as GenericReportDefinition)
      : undefined;
  const sheetDefinition =
    genericReportDefinition?.sheets.find((s) => s.name === sheetName) ??
    genericReportDefinition?.sheets[0];
  const sheet = sheetDefinition?.name;

  const feProperties = useMemo(
    () => Object.values(propertiesMap).filter((p) => p.front_end),
    [propertiesMap],
  );

  const viewConfiguration = useMemo(() => {
    const asOf = PureDate.of(date ?? undefined);
    return selection == null ? undefined : { properties: selection, asOf };
  }, [selection, date]);

  useEffect(() => {
    if (info && viewConfiguration && downloadOpen && !templateFiles)
      getReportDownloadNames(info.id, viewConfiguration)
        .then(setTemplateFiles)
        .catch(onUnexpectedError);
  }, [
    info,
    viewConfiguration,
    downloadOpen,
    templateFiles,
    setTemplateFiles,
    onUnexpectedError,
  ]);

  useEffect(() => {
    setRendered(undefined);
    if (info != null && viewConfiguration != null)
      renderReport(info.id, viewConfiguration)
        .then(setRendered)
        .catch(onUnexpectedError);
  }, [viewConfiguration, info, onUnexpectedError]);

  useEffect(() => {
    setGenericReportData(undefined);
    setMaximized(false);
    if (info != null && sheet != null && viewConfiguration != null)
      getReportData(info.id, {
        ...viewConfiguration,
        sheet: exportPDF ? "*" : sheet,
      })
        .then(setGenericReportData)
        .catch(onUnexpectedError);
  }, [info, viewConfiguration, sheet, exportPDF, onUnexpectedError]);

  const doDownload = (
    template: number | undefined,
    attachmentType?: AttachmentType,
    sheetNumber?: number,
  ) => {
    if (info && viewConfiguration) {
      setDownloadOpen(false);
      setDownloading(true);

      if (attachmentType === "pdf" || attachmentType === "all")
        setAlert(
          "Kindly wait for about 20 seconds as the PDF is generated.",
          true,
        );

      downloadReport(info.id, {
        ...viewConfiguration,
        template,
        attachmentType,
        sheetNumber,
      })
        .then(downloadAttachment("Report"))
        .catch(onUnexpectedError)
        .finally(() => setDownloading(false));
    }
  };

  const doDownloadOne = (
    attachmentType: AttachmentType,
    sheetNumber?: number,
  ) => {
    if (info && viewConfiguration)
      getReportDownloadNames(info.id, viewConfiguration)
        .then(([{ id }]) => doDownload(id, attachmentType, sheetNumber))
        .catch(onUnexpectedError);
  };

  function onScheduledEmailsChange() {
    getScheduledEmails(info!.id)
      .then(setScheduledEmails)
      .catch(onUnexpectedError);
  }

  function onScheduledEmailRemoveClick(scheduledEmail: ScheduledEmail) {
    setScheduledEmailToBeRemoved(scheduledEmail);
  }

  function onScheduledEmailDeleteSubmit() {
    setAlert("Scheduled email deleted successfully.", true);
    setScheduledEmailToBeRemoved(undefined);
    onScheduledEmailsChange();
  }

  const doDownloadDefinition = () => {
    if (genericReportDefinition != null) {
      const blob = new Blob(
        [JSON.stringify(genericReportDefinition, undefined, 2)],
        {
          type: "application/json ",
        },
      );
      const filename = `${info?.name ?? "Report"}.json`;
      fileDownload(blob, filename);
    }
  };

  const sheetData = genericReportData?.sheets?.[0];
  const allSheetData = genericReportData?.sheets;
  const sheetNameQuery =
    sheetData == null ? "" : `&sheet=${encodeURIComponent(sheetData.name)}`;

  const downloadCount =
    (genericReportDefinition?.sheets?.length ? 1 : 0) +
    (genericReportDefinition?.templates?.length ?? 0);
  const multiDownload = downloadCount > 1;

  return (
    <div className="jh-page-layout">
      <JhCrumbar
        primary="Reports"
        primaryPath="/reports"
        secondary={rendered?.subject ?? info?.name}
        noShadow
      ></JhCrumbar>
      <LoadilyFadily loaded={loaded}>
        <div className="dashboard-filters d-flex align-items-center pb-3 px-4 justify-content-between jh-crumbar-like">
          <div className="flex-row ">
            {!exportPDF && (
              <>
                <ButtonWithIcon
                  icon={faPrint}
                  tooltip="Print Report"
                  className="jh-action-icon"
                  onClick={() => window.print()}
                  id="print-report"
                />
                <DownloadReport
                  downloading={downloading}
                  downloadOpen={downloadOpen}
                  multiDownload={multiDownload}
                  templateFiles={templateFiles}
                  sheetNames={genericReportDefinition?.sheets ?? []}
                  setDownloadOpen={setDownloadOpen}
                  doDownload={doDownload}
                  doDownloadOne={doDownloadOne}
                />
              </>
            )}
            {(organization_role == "Administrator" ||
              organization_role == "Editor") &&
            info ? (
              <SendScheduleEmail
                id={info.id}
                sendEmail={(recipients, attachmentType) =>
                  sendReport(info.id, {
                    ...viewConfiguration!,
                    recipients,
                    attachmentType,
                  })
                }
                scheduledEmails={scheduledEmails}
                onScheduledEmailsChange={onScheduledEmailsChange}
                onViewScheduledEmailsClick={() =>
                  setViewScheduledEmailsModalOpen(true)
                }
              />
            ) : null}
            {superAdmin && genericReportDefinition ? (
              <ButtonWithIcon
                icon={faCloudDownload}
                tooltip="Download Definition"
                className="jh-action-icon"
                id="download-definition"
                onClick={doDownloadDefinition}
              />
            ) : null}
            {(organization_role == "Administrator" ||
              organization_role == "Editor") &&
            info ? (
              <ButtonWithIcon
                icon={faCopy}
                tooltip="Copy Report"
                className="jh-action-icon"
                onClick={() => setCopying(true)}
                id="copy-dashboard"
              />
            ) : null}
            {organization_role == "Administrator" ? (
              <EmbedLink
                kind="report"
                identifier={reportIdentifier}
                sheetNames={genericReportDefinition?.sheets ?? []}
              />
            ) : null}
            {(organization_role == "Administrator" ||
              organization_role == "Editor") &&
            info &&
            !info.immutable ? (
              <ButtonWithIcon
                icon={faEdit}
                tooltip="Edit Report"
                className="jh-action-icon"
                tag={Link}
                to={`/reports/${info.shared ? "library" : "my"}/${
                  info.id
                }/edit?${asPropertiesQuery(selection)}${sheetNameQuery}`}
                iconStyle={{ verticalAlign: "-.52em", marginLeft: ".2em" }}
                id="edit-report"
              />
            ) : null}
          </div>
          <div className="flex-row">
            <PropertiesPicker
              allProperties={feProperties}
              propertyGroups={propertyGroups}
              className="me-2"
            />
            <DatePicker
              value={PureDate.of(genericReportData?.date) ?? date}
              onChange={setDate}
              clearIcon={null}
              disabled={!!embedInfo}
              calendarIcon={
                <FontAwesomeIcon icon={faCalendarAlt} className="me-1" />
              }
              className="jh-date-picker btn btn-light"
            />
          </div>
        </div>

        {!exportPDF && (
          <Nav tabs className="d-print-none craport-tab-bar jh-crumbar-like">
            {(genericReportDefinition?.sheets.length ?? 0) > 1
              ? genericReportDefinition?.sheets.map((sheet, index) => {
                  const active = sheet.name === sheetData?.name;
                  return (
                    <NavItem key={index} className="me-2">
                      <NavLink
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          setSheet(index === 0 ? undefined : sheet.name);
                        }}
                        active={active}
                      >
                        {sheet.name}
                        {active && (
                          <span
                            className="d-inline-block px-1 ms-1 action-edit"
                            onClick={preventer(() => setMaximized(true))}
                          >
                            <FontAwesomeIcon
                              icon={faArrowsUpDownLeftRight}
                              size="sm"
                            />
                          </span>
                        )}
                      </NavLink>
                    </NavItem>
                  );
                })
              : null}
          </Nav>
        )}

        <div
          className={clsx(
            !exportPDF ? "jh-page-content" : "",
            maximized && "maximized",
          )}
        >
          {maximized && (
            <Button
              className="minimizer d-print-none"
              onClick={() => setMaximized(false)}
            >
              <FontAwesomeIcon icon={faClose} size="sm" />
            </Button>
          )}
          <div className="scorecard-body">
            {rendered == null ||
            (sheet != null && genericReportData == null) ? (
              <div className="flex flex-column-center">
                <div
                  style={{
                    maxWidth: "800px",
                    height: "600px",
                    border: "1px solid #eee",
                    borderRadius: "20px",
                    overflow: "hidden",
                    margin: "30px auto",
                    padding: "20px",
                    background: "#fff",
                  }}
                >
                  <div className="skeleton" />
                </div>
              </div>
            ) : (
              <div className="flex flex-column">
                {sheetData != null ? (
                  <>
                    {/* this should render all the excel titles but shrug */}
                    <PrintHeader title={rendered?.subject ?? ""} />
                    {!exportPDF ? (
                      <ReportContent sheetData={sheetData} />
                    ) : (
                      allSheetData?.map((sData, index) => (
                        <div style={{ paddingTop: 50, paddingBottom: 50 }}>
                          <Nav
                            tabs
                            className="craport-tab-bar jh-crumbar-like"
                            style={{ marginBottom: 30 }}
                          >
                            <NavLink active={true}>
                              {genericReportDefinition?.sheets[index].name}
                            </NavLink>
                          </Nav>
                          <div style={{ padding: 30 }}>
                            <ReportContent sheetData={sData} />
                          </div>
                        </div>
                      ))
                    )}
                  </>
                ) : sheet == null ? (
                  <div dangerouslySetInnerHTML={{ __html: rendered.body }} />
                ) : null}
              </div>
            )}
          </div>
          {viewScheduledEmailsModalOpen ? (
            <ScheduledEmails
              onClose={() => setViewScheduledEmailsModalOpen(false)}
              onScheduledEmailsChange={onScheduledEmailsChange}
              scheduledEmails={scheduledEmails}
              onRemoveClick={onScheduledEmailRemoveClick}
            />
          ) : null}
          {scheduledEmailToBeRemoved ? (
            <DeleteModal<ScheduledEmail>
              id={scheduledEmailToBeRemoved.id}
              entityName="Scheduled Email"
              identificationKey="id"
              getEntity={getScheduledEmail}
              deleteEntity={deleteScheduledEmail}
              onClose={() => setScheduledEmailToBeRemoved(undefined)}
              onSubmit={onScheduledEmailDeleteSubmit}
            />
          ) : null}
          {copying && info ? (
            <CopyModal
              report={info}
              onSubmit={(d) => {
                setCopying(false);
                navigate(`/reports/my/${d.identifier}`);
              }}
              onClose={() => setCopying(false)}
            />
          ) : null}
        </div>
      </LoadilyFadily>
    </div>
  );
};

export default withAlertModal(Craport);
