import "./adminPortal.css";

import {
  faBallotCheck,
  faBuildingLock,
  faBullseyeArrow,
  faCalculatorAlt,
  faCauldron,
  faChartScatter,
  faCity,
  faCloud,
  faFileImport,
  faFileInvoiceDollar,
  faInboxes,
  faPaintRoller,
  faProjectDiagram,
  faUserCog,
} from "@fortawesome/pro-light-svg-icons";
import React, { useContext } from "react";

import PlatformContext from "../../app/PlatformContext";
import allFadily from "../../common/allFadily";
import JhCrumbar from "../../navbar/JhCrumbar";
import AdminPortalTile from "./AdminPortalTile";

const AdminPortal: React.FC = () => {
  const { platform } = useContext(PlatformContext);

  return (
    <div className="jh-page-layout">
      <JhCrumbar primary="Administration" />
      <div className="jh-page-content admin-portal-main">
        <div className="admin-portal-container flex-wrap">
          <AdminPortalTile
            to="/admin/users"
            name="Manage Users"
            description="Manage users in your organization."
            icon={faUserCog}
          />
          <AdminPortalTile
            to="/admin/property-access"
            name="Manage Property Access"
            description="Manage property access permissions for your organization."
            icon={faBuildingLock}
          />
          <AdminPortalTile
            to="/admin/integrations"
            name="Manage Integrations"
            description="Manage integrations in your organization."
            icon={faProjectDiagram}
          />
          {platform?.superAdmin && (
            <AdminPortalTile
              to="/admin/financials/manage"
              name="Manage Financials"
              description="Manage financials in your organization."
              icon={faFileInvoiceDollar}
            />
          )}
          <AdminPortalTile
            to="/admin/goals"
            name="Manage Goals"
            description="Manage goals for your properties."
            icon={faBullseyeArrow}
          />
          <AdminPortalTile
            to="/admin/insights"
            name="Manage Insights"
            description="Manage custom insight definitions."
            icon={faCalculatorAlt}
          />
          <AdminPortalTile
            to="/insights/data"
            name="Insight Data"
            description="View underlying insight data."
            icon={faChartScatter}
          />
          {platform?.superAdmin ? (
            <AdminPortalTile
              to={"/admin/adhoc-insights"}
              name={"Manage Ad Hoc Reports"}
              description={"View all ad hoc report parsing rules."}
              icon={faBallotCheck}
            />
          ) : null}
          <AdminPortalTile
            to="/admin/financials/import"
            name="Financial Imports"
            description="Import financial data."
            icon={faFileImport}
          />
          <AdminPortalTile
            to="/admin/appearance-settings"
            name="Appearance Settings"
            description="Configure logo, dark mode, and chart colors."
            icon={faPaintRoller}
          />
          {platform?.superAdmin ? (
            <>
              <AdminPortalTile
                to="/admin/organization"
                name="Manage Organization"
                description="Manage this organization."
                icon={faCity}
                className="overlord-card"
              />
              <AdminPortalTile
                to="/admin/create"
                name="Create Organization"
                description="Create a new organization."
                icon={faCauldron}
                className="overlord-card"
              />
              <AdminPortalTile
                to={"/admin/cloud"}
                name={"Cloud Health"}
                description={"View AWS Cloud data"}
                icon={faCloud}
              />
              <AdminPortalTile
                to={"/admin/communications"}
                name={"Communications Log"}
                description={
                  " View all emails sent via the platform. Check if they were opened."
                }
                icon={faInboxes}
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default allFadily(AdminPortal);
