export const preventBrowserAutoComplete = (event: Event) => {
  if ((event.target as any).autocomplete) {
    (event.target as any).autocomplete = "whatever";
  }
};

/**
 * This is a workaround for blur actions that behave poorly in modal contexts
 *
 * As a specific instance, Typeahead depends on the document receiving a click in order to close
 * its popup selector when someone clicks away. Adding this event to onBlur will ensure the
 * document always gets a click, even if you are within a tricky modal that tries to consume it.
 */
export const sendEmptyDocumentClick = () => {
  document.dispatchEvent(new PointerEvent("click", {}));
};

export type Iso<A> = (a: A) => A;

/** Sequence from [0..n) or [n..m] */
export const sequence = (n: number, m?: number): Array<number> => {
  const zero = m === undefined ? 0 : n;
  const count = m === undefined ? n : 1 + m - n;
  return Array(count)
    .fill(undefined)
    .map((_value, index) => zero + index);
};
