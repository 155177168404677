import { apiUrl, axiosConfig, axiosJsonConfig } from "../utils/api";
import axios from "./axios";

export type EmbedLinkType = {
  id: number;
  organization_id: number;
  creator_id: number;
  dashboard_id: number;
  uuid: string;
  kind: string;
  restricted: boolean;
  configuration?: Record<string, any>;
};

export const getEmbedLinks = async (
  identifier: number | string,
  propertyId?: number,
) => {
  const url = apiUrl(
    `/embed/${identifier}${propertyId ? `/${propertyId}` : ``}`,
  );

  return axios
    .get<Array<EmbedLinkType>>(url, axiosConfig)
    .then((res) => res.data);
};

export const createEmbedLink = async (
  identifier: number | string,
  kind: string,
  properties: object,
  sheet: number,
  propertyId?: number,
) => {
  const url = apiUrl(
    `/embed/${identifier}${propertyId ? `/${propertyId}` : ``}`,
  );

  return axios
    .post<
      Array<EmbedLinkType>
    >(url, { kind, configuration: { properties, sheet } }, axiosJsonConfig)
    .then((res) => res.data);
};

export const deleteEmbedLink = async (id: number) =>
  axios.delete<void>(apiUrl(`/embed/${id}`), axiosConfig).then(() => {});

export const putEmbedLinkRestricted = async (id: number, restricted: boolean) =>
  axios
    .put<void>(apiUrl(`/embed/${id}/restricted`), restricted, axiosJsonConfig)
    .then(() => void 0);
