import { DashboardKindEnum } from "@joyhub-integration/shared";
import { find, isNull, isUndefined } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, ModalFooter } from "reactstrap";

import dashboardService from "../../../services/dashboardService";
import { EmbeddedReportId } from "../../../services/insightLibrary/insightDefinitions";
import { InsightVisualizationType } from "../../../services/models";
import { ValidationError } from "../../admin/integrations/addEditIntegrationModal/common";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import UncontrolledModal from "../../common/modal/UncontrolledModal";
import { CardMenu } from "./CardModalBody/CardMenu";
import { EmailCard } from "./CardModalBody/EmailCard";
import { GraphCard } from "./CardModalBody/GraphCard";
import { HtmlCard } from "./CardModalBody/HtmlCard";
import { IframeCard } from "./CardModalBody/IframeCard";
import { ImageCard } from "./CardModalBody/ImageCard";
import { TableCard } from "./CardModalBody/TableCard";
import { CardModalHeader } from "./CardModalHeader";
import { CardComponentsType, CardType } from "./type";
import { cardItems, validateForm } from "./utils";

const CardComponents: CardComponentsType = {
  menu: CardMenu,
  graph: GraphCard,
  table: TableCard,
  email: EmailCard,
  html: HtmlCard,
  image: ImageCard,
  iframe: IframeCard,
};

interface DashboardAddEditInsightProps {
  dashboardId: number;
  dashboardKind:
    | DashboardKindEnum.Dashboard
    | DashboardKindEnum.PropertyDashboard;
  dashboardInsightId?: number;
  isOldDashboard: boolean;
  xy?: [number, number];
  onClose: () => void;
  onSubmit: () => void;
}

const DashboardAddEditInsight = (
  props: DashboardAddEditInsightProps & WithAlertModalProps,
) => {
  const {
    dashboardId,
    dashboardInsightId,
    isOldDashboard,
    xy,
    onClose,
    onSubmit,
    onUnexpectedError,
  } = props;

  const [cardType, setCardType] = useState<CardType>("menu");
  const [dashboardInsight, setDashboardInsight] = useState<Record<string, any>>(
    {},
  );
  const [validationError, setValidationError] = useState<ValidationError>({});
  const [initialDashboardInsight, setInitialDashboardInsight] = useState<
    Record<string, any>
  >({});
  const [isCardLoaded, setIsCardLoaded] = useState<boolean>(false);

  const CardModalBody = CardComponents[cardType];

  const isEdit = useMemo(
    () => !isNull(dashboardInsightId) && !isUndefined(dashboardInsightId),
    [dashboardInsightId],
  );

  const updateDashboardInsight = useCallback(
    (fieldName: string, newData: any) => {
      setDashboardInsight((prevInsight) => ({
        ...prevInsight,
        [fieldName]: newData,
      }));
    },
    [],
  );

  const refreshDashboardInsight = useCallback(() => {
    setDashboardInsight({ ...initialDashboardInsight });
  }, [initialDashboardInsight]);

  const handleSubmit = async () => {
    if (!validateForm(cardType, dashboardInsight, setValidationError)) return;

    try {
      if (isEdit) {
        await dashboardService.editDashboardInsight(
          dashboardId,
          dashboardInsightId as number,
          dashboardInsight?.insight?.id as number,
          dashboardInsight?.name as string,
          dashboardInsight?.visualizationType as InsightVisualizationType,
          dashboardInsight?.dimensionId,
          dashboardInsight?.linkedReport,
          dashboardInsight?.stuff,
        );
      } else {
        if (isOldDashboard)
          await dashboardService.addInsightToOldDashboard(
            dashboardId,
            dashboardInsight?.insight!,
            dashboardInsight?.name as string,
            dashboardInsight?.visualizationType as InsightVisualizationType,
            dashboardInsight?.dimensionId,
            dashboardInsight?.linkedReport,
            dashboardInsight?.stuff,
          );
        else
          await dashboardService.addInsightToDashboard(
            dashboardId,
            dashboardInsight?.insight!,
            dashboardInsight?.name as string,
            dashboardInsight?.visualizationType as InsightVisualizationType,
            dashboardInsight?.dimensionId,
            dashboardInsight?.linkedReport,
            dashboardInsight?.stuff,
            xy!,
          );
      }

      onSubmit();
    } catch (error) {
      onUnexpectedError(error);
    }
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const dashboard = await dashboardService.getDashboardById(dashboardId);
        const insight = find(dashboard.definition.insights, {
          id: dashboardInsightId,
        });
        setDashboardInsight({ ...insight });
        setInitialDashboardInsight({ ...insight });
      } catch (error) {
        onUnexpectedError(error);
      }
    };

    fetchDashboardData();
  }, [dashboardId, dashboardInsightId, onUnexpectedError]);

  useEffect(() => {
    if (!isCardLoaded) {
      const matchingCard = cardItems.find(
        (card) => card.id === initialDashboardInsight.insightId,
      );

      if (matchingCard) {
        if (matchingCard.id === EmbeddedReportId)
          setCardType(
            initialDashboardInsight.stuff?.showGraph === true
              ? "graph"
              : "table",
          );
        else setCardType(matchingCard.type);
        setIsCardLoaded(true);
      }
    }
  }, [
    initialDashboardInsight.insightId,
    initialDashboardInsight.stuff?.showGraph,
    isCardLoaded,
  ]);

  return (
    <UncontrolledModal onClosed={onClose} onFormSubmit={handleSubmit} size="md">
      <CardModalHeader
        isEdit={isEdit}
        cardType={cardType}
        updateCardType={setCardType}
        refreshDashboardInsight={refreshDashboardInsight}
      />
      <CardModalBody
        dashboardInsight={dashboardInsight}
        validationError={validationError}
        updateCardType={setCardType}
        updateDashboardInsight={updateDashboardInsight}
        {...props}
      />
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSubmit}>
          {isEdit ? "Save" : "Add"}
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default withAlertModal(DashboardAddEditInsight);
