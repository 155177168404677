import { IconProp } from "@fortawesome/fontawesome-svg-core";
import React, { useState } from "react";
import Select, {
  Props as ReactSelectProps,
  StylesConfig,
  ValueType,
  components,
} from "react-select";
import CreatableSelect from "react-select/creatable";

const { Option } = components;

export type IDropListItem = {
  label: string;
  value: string;
  className?: string;
  [key: string]: any;
};

export const jhItem = (
  value: string,
  label?: string,
  className?: string,
): IDropListItem => ({
  value,
  label: label ?? value,
  className,
});

interface IDropList extends ReactSelectProps<IDropListItem, boolean> {
  icon?: IconProp;
  label?: string;
  onValueUpdate?: (value: IDropListItem) => void;
  isSearchable?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isCreatable?: boolean;
  placeholder?: string;
  invalid?: boolean;
  isMulti?: boolean;
  defaultValue?: Array<IDropListItem>;
  onBlur?: () => void;
  customOption?: (optionProps: any) => any;
  innerRef?: any;
  pointy?: boolean;
  isValidNewOption?: (value: string) => boolean;
}

export const defaultSelectStyles = (
  pointy?: boolean,
): StylesConfig<IDropListItem, false> => ({
  control: (base) => ({
    ...base,
    ...(pointy ? {} : { borderRadius: "1rem" }),
    height: "calc(1.5em + 0.75rem + 2px)",
  }),
  valueContainer: (base) => ({
    ...base,
    paddingLeft: "1em",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    marginTop: 0,
    marginBottom: 0,
  }),
  menu: (base) => ({
    ...base,
    zIndex: 9999,
  }),
});

const JhSelect: React.FC<IDropList> = (props) => {
  const [selectedOption, setSelected] = useState<IDropListItem | null>(null);

  function onSelectUpdate(value: ValueType<IDropListItem, boolean>) {
    /*
     *  Workaround because of weird typings in react select
     *  See https://github.com/DefinitelyTyped/DefinitelyTyped/issues/32553
     */
    setSelected(value as IDropListItem); // | undefined...
    props.onValueUpdate && props.onValueUpdate(value as IDropListItem);
  }

  const errorStyle: StylesConfig<IDropListItem, false> = props.invalid
    ? {
        control: (base) => ({
          ...base,
          borderColor: "#dc3545",
        }),
      }
    : {};

  const styles: StylesConfig<IDropListItem, false> = {
    ...defaultSelectStyles(props.pointy),
    ...(props.styles ?? {}),
    ...errorStyle,
  };

  const JhOption = (optionProps: any) => (
    <Option {...optionProps}>
      {props.customOption ? props.customOption(optionProps) : ""}
    </Option>
  );

  // for some reason, react struggles with this typing
  const SelectComponent = (
    props.isCreatable ? CreatableSelect : Select
  ) as React.ComponentType<IDropList>;

  return (
    <SelectComponent
      {...props}
      styles={styles}
      value={props.value || selectedOption}
      onChange={(option: any) => onSelectUpdate(option)}
      className={props.isMulti ? "basic-multi-select" : props.className}
      classNamePrefix={props.isMulti ? "select" : props.classNamePrefix}
      defaultValue={props.defaultValue || []}
      ref={props.innerRef}
      components={{ Option: props.customOption ? JhOption : Option }}
    />
  );
};
export default JhSelect;
