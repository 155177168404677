import "./properties.css";

import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

import { PropertiesTabType } from "../../services/propertiesService";

interface TabBarProps {
  activeTab: PropertiesTabType;
  handleTabClick: (type: PropertiesTabType) => void;
}

export const PropertiesTabBar: React.FC<TabBarProps> = ({
  activeTab,
  handleTabClick,
}) => {
  return (
    <Nav className="d-print-none property-tabbar">
      <NavItem key={0}>
        <NavLink
          className={activeTab === "dashboard" ? "active" : ""}
          onClick={(e) => {
            e.preventDefault();
            handleTabClick("dashboard");
          }}
        >
          Dashboard
        </NavLink>
      </NavItem>
      <NavItem key={1}>
        <NavLink
          className={activeTab === "manage" ? "active" : ""}
          onClick={(e) => {
            e.preventDefault();
            handleTabClick("manage");
          }}
        >
          Manage
        </NavLink>
      </NavItem>
    </Nav>
  );
};
