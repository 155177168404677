import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Col,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  Label,
  Row,
} from "reactstrap";

import {
  FTPProtocols,
  YardiConfiguration,
} from "../../../../services/integrationsService";
import { getOrganizationsForPropertyConnect } from "../../../../services/organizationService";
import { Platform } from "../../../../services/platformService";
import { Organization } from "../../../../services/usersService";
import { convertBase64 } from "../../../../utils/file";
import PlatformContext from "../../../app/PlatformContext";
import { IntegrationFormProps, ValidationError } from "./common";

const InitialConfig: YardiConfiguration = {
  protocol: "SFTP",
  server: "",
  username: "",
  path: "/",
  encrypted: false,
  certificate: "",
  privateKey: "",
  decryptionPassword: "",
  propListIds: "",
  ebsVolumeSize: undefined,
  ignorePmsFinancials: false,
  useGlTotal: true,
  modernSisters: true,
};

const YardiForm: React.FC<IntegrationFormProps> = (props) => {
  const [yardiConfig, setYardiConfig] =
    useState<YardiConfiguration>(InitialConfig);
  const [type, setType] = useState<"live" | "backup">("live");
  const [propertyConnectOrganizations, setPropertyConnectOrganizations] =
    useState<Organization[]>();
  const {
    isCreate,
    validationError,
    setConfigurationValidation,
    setConfiguration,
  } = props;
  const platform = useContext(PlatformContext).platform as Platform;

  const validate = useCallback(
    (newConfig: YardiConfiguration, isCreate: boolean): ValidationError => {
      const isPcApp = platform.organization.application === "PC";
      if (!newConfig.server) {
        return { message: "FTP Server is required.", field: "server" };
      } else if (!newConfig.username) {
        return { message: "Username is required.", field: "username" };
      } else if (isCreate && !newConfig.secret) {
        return { message: "Password is required.", field: "password" };
      } else if (!newConfig.path) {
        return { message: "Path to files is required.", field: "path" };
      } else if (isPcApp && !newConfig.client) {
        return { message: "Client is required.", field: "client" };
      } else if (newConfig.ebsVolumeSize && newConfig.ebsVolumeSize >= 4097) {
        return {
          message: "EBS Volume Size must be less than or equal to 4096.",
          field: "ebsVolumeSize",
        };
      }
      return {};
    },
    [platform.organization.application],
  );

  useEffect(() => {
    if (type === "backup") {
      setConfigurationValidation(validate(yardiConfig, isCreate));
    }
  }, [yardiConfig, setConfigurationValidation, isCreate, validate, type]);

  useEffect(() => {
    if (props.configuration && Object.keys(props.configuration).length) {
      const config = props.configuration as YardiConfiguration;
      setYardiConfig(config);
      if (config.username) {
        setType("backup");
      }
    }
  }, [props.configuration]);

  useEffect(() => {
    const isPcApp = platform.organization.application === "PC";
    if (isPcApp) {
      getOrganizationsForPropertyConnect().then((organizations) => {
        setPropertyConnectOrganizations(organizations);
      });
    }
  }, [platform.organization.application]);

  const onConfigChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value =
      e.target.type === "checkbox"
        ? e.target.checked
        : e.target.type === "number"
          ? e.target.valueAsNumber
          : e.target.value;
    const newConfig = {
      ...yardiConfig,
      [e.target.name]: value,
    };
    setYardiConfig(newConfig);
    setConfiguration(newConfig);
  };

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const name = e.target.name;
    if (!file) return;
    const newConfig = {
      ...yardiConfig,
      [name]: await convertBase64(file),
    };
    setYardiConfig(newConfig);
    setConfiguration(newConfig);
  };

  const errorMessage = (field: string) => {
    return (
      validationError.field === field &&
      validationError.message && (
        <FormFeedback>{validationError.message}</FormFeedback>
      )
    );
  };

  return (
    <>
      <Row>
        <Col>
          <Button
            block
            color="primary"
            outline
            active={type === "live"}
            size="lg"
            onClick={() => {
              setType("live");
              const config: YardiConfiguration = {
                ...InitialConfig,
                ignorePmsFinancials: yardiConfig.ignorePmsFinancials,
                useGlTotal: yardiConfig.useGlTotal,
              };
              setConfiguration(config);
            }}
          >
            Live
          </Button>
        </Col>
        <Col>
          <Button
            block
            color="primary"
            outline
            active={type === "backup"}
            size="lg"
            onClick={() => setType("backup")}
          >
            Backup
          </Button>
        </Col>
      </Row>
      <Row style={{ paddingTop: "1em" }}>
        {type === "backup" ? (
          <>
            <FormGroup>
              <Label>FTP Server</Label>
              <InputGroup>
                <Input
                  type="text"
                  name="server"
                  value={yardiConfig.server}
                  onChange={onConfigChange}
                  invalid={validationError.field === "server"}
                  placeholder="eg: ftp.example.com"
                  autoComplete="off"
                />
                <Input
                  type="select"
                  name="protocol"
                  value={yardiConfig.protocol}
                  onChange={onConfigChange}
                  className="custom-select"
                >
                  {FTPProtocols.map((protocol) => (
                    <option key={protocol} value={protocol}>
                      {protocol}
                    </option>
                  ))}
                </Input>
                {errorMessage("server")}
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label>Username</Label>
              <Input
                type="text"
                name="username"
                value={yardiConfig.username}
                onChange={onConfigChange}
                invalid={validationError.field === "username"}
                autoComplete="off"
              />
              {errorMessage("username")}
            </FormGroup>
            <FormGroup>
              <Label>Password</Label>
              <Input
                type="text"
                name="secret"
                value={yardiConfig.secret}
                onChange={onConfigChange}
                invalid={validationError.field === "password"}
                autoComplete="off"
                className="secret-blur"
              />
              {errorMessage("password")}
            </FormGroup>
            <FormGroup>
              <Label>Path to .bak files</Label>
              <Input
                type="text"
                name="path"
                value={yardiConfig.path}
                onChange={onConfigChange}
                invalid={validationError.field === "path"}
                autoComplete="off"
              />
              {errorMessage("path")}
            </FormGroup>
            <FormGroup inline>
              <Input
                type="checkbox"
                name="encrypted"
                onChange={onConfigChange}
                checked={yardiConfig.encrypted}
              />
              <Label check>&nbsp;Database Encryption In Use</Label>
            </FormGroup>
            {yardiConfig.encrypted && (
              <>
                <FormGroup>
                  <Label>Certificate</Label>
                  <div style={{ display: "flex" }}>
                    <Input
                      type="file"
                      name="certificate"
                      onChange={onFileChange}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label>Private Key</Label>
                  <div style={{ display: "flex" }}>
                    <Input
                      type="file"
                      name="privateKey"
                      onChange={onFileChange}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label>Decryption Password</Label>
                  <Input
                    type="text"
                    name="decryptionPassword"
                    value={yardiConfig.decryptionPassword}
                    onChange={onConfigChange}
                    autoComplete="off"
                    className="secret-blur"
                  />
                </FormGroup>
              </>
            )}
            <FormGroup>
              <Label>PropList IDs (Optional)</Label>
              <Input
                type="text"
                name="propListIds"
                value={yardiConfig.propListIds}
                onChange={onConfigChange}
                autoComplete="off"
              />
            </FormGroup>

            {platform.superAdmin && (
              <FormGroup>
                <Label>EBS Volume Size (in MB)</Label>
                <Input
                  type="number"
                  name="ebsVolumeSize"
                  value={yardiConfig.ebsVolumeSize}
                  onChange={onConfigChange}
                  invalid={validationError.field === "ebsVolumeSize"}
                />

                <FormText>
                  The default value of 2048 will be used if the field is empty.
                </FormText>
                {errorMessage("ebsVolumeSize")}
              </FormGroup>
            )}
          </>
        ) : null}

        {platform.superAdmin && (
          <>
            <FormGroup inline>
              <Input
                id="ignore-pms-financials"
                type="checkbox"
                name="ignorePmsFinancials"
                onChange={onConfigChange}
                checked={!!yardiConfig.ignorePmsFinancials}
              />
              <Label for="ignore-pms-financials" check>
                &nbsp;Ignore PMS Financials (use Emailed T-12s Instead)
              </Label>
            </FormGroup>
            <FormGroup inline>
              <Input
                id="use-gl-total"
                type="checkbox"
                name="useGlTotal"
                onChange={onConfigChange}
                checked={!!yardiConfig.useGlTotal}
              />
              <Label for="use-gl-total" check>
                &nbsp;Use GL Total
              </Label>
            </FormGroup>
          </>
        )}

        {propertyConnectOrganizations && propertyConnectOrganizations.length ? (
          <FormGroup>
            <Label>Revolution RE Client</Label>
            <Input
              type="select"
              className="custom-select"
              value={yardiConfig.client}
              onChange={(e) => {
                const client = e.target.value
                  ? parseInt(e.target.value, 10)
                  : undefined;
                setYardiConfig({ ...yardiConfig, client: client });
              }}
            >
              <option value=""></option>
              {propertyConnectOrganizations.map((org) => (
                <option key={org.id} value={org.id}>
                  {org.name}
                </option>
              ))}
            </Input>
            {validationError.field === "client" && validationError.message ? (
              <FormFeedback className="jh-block">
                {validationError.message}
              </FormFeedback>
            ) : null}
          </FormGroup>
        ) : null}
        {type === "live" && (
          <p>
            <b>Note: </b>Please contact your support representative to schedule
            Yardi installation.
          </p>
        )}
      </Row>
    </>
  );
};

export default YardiForm;
