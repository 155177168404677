import { InsightDefinition } from "@joyhub-integration/shared";
import { compareTwoStrings as similarity } from "string-similarity";

import { KnownInsight } from "../../services/insightsService";

export const siblings = (
  i: InsightDefinition,
  all: Record<string, KnownInsight>,
): InsightDefinition[] | undefined =>
  Object.values(all).filter(
    (other) =>
      other !== i &&
      ((i.identifier && other.identifier?.name === i.identifier?.name) ||
        similarity(other.name, i.name) > 0.75),
  );
