import { faMugHot, faPaperPlane } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Button,
  ButtonDropdown,
  ButtonGroup,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  PopoverBody,
  Spinner,
  UncontrolledPopover,
} from "reactstrap";

import { AttachmentType } from "@joyhub-integration/shared";
import {
  createScheduledEmail,
  ScheduledEmail,
  SendEmailResult,
} from "../../services/scheduledEmailsService";
import { usePropertiesSelectionQueryParam } from "../../utils/useQueryParams";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";
import EmailsInput from "./EmailsInput";

interface SendScheduleEmailProps {
  id?: number;
  sendEmail: (
    recipients: string[],
    attachmentType: AttachmentType,
  ) => Promise<SendEmailResult>;
  scheduledEmails: ScheduledEmail[];
  onViewScheduledEmailsClick: () => void;
  onScheduledEmailsChange: () => void;
  isDashboard?: boolean;
  propertyId?: number;
}

const SendScheduleEmail: React.FC<
  SendScheduleEmailProps & WithAlertModalProps
> = ({
  id,
  isDashboard,
  propertyId,
  sendEmail,
  scheduledEmails,
  setAlert,
  onScheduledEmailsChange,
  onUnexpectedError,
  onViewScheduledEmailsClick,
}) => {
  const [selection] = usePropertiesSelectionQueryParam();
  const [recipients, setRecipients] = useState<string[]>([]);
  const [sending, setSending] = useState(false);
  const [sendDropdownOpen, setSendDropdownOpen] = useState(false);
  const [scheduleAutoEmail, setScheduleAutoEmail] = useState(false);
  const [weeklyMonthly, setWeeklyMonthly] = useState<"weekly" | "monthly">(
    "weekly",
  );
  const [attachmentType, setAttachmentType] = useState<AttachmentType>(
    isDashboard ? "pdf" : "excel",
  );
  const [daySelected, setDaySelected] = useState<string>();
  const [periodInMonthSelected, setPeriodInMonthSelected] = useState<
    "Start" | "Mid" | "End"
  >("Start");
  const [weekdaysOnly, setWeekdaysOnly] = useState<boolean>(false);
  const sendButtonDisabled =
    sending ||
    recipients.length === 0 ||
    (scheduleAutoEmail &&
      weeklyMonthly === "weekly" &&
      daySelected === undefined);

  const toggleSendDropdownOpen = () => {
    if (sendDropdownOpen) {
      setScheduleAutoEmail(false);
      setWeeklyMonthly("weekly");
      setDaySelected(undefined);
      setPeriodInMonthSelected("Start");
    }
    setSendDropdownOpen(!sendDropdownOpen);
  };

  const doSend = () => {
    setSending(true);
    if (attachmentType !== "excel")
      setAlert(
        "Kindly wait for about 20 seconds as the PDF is generated.",
        true,
      );
    sendEmail(recipients, attachmentType)
      .then(({ delivered, failed, bounced, optedOut }) => {
        const results = [
          delivered.length ? [`Sent to ${delivered.join(", ")}.`] : [],
          failed.length ? [`Failed sending to ${failed.join(", ")}.`] : [],
          bounced.length
            ? [`Failed due to bounces to ${bounced.join(", ")}.`]
            : [],
          optedOut.length
            ? [`Failed due to opt-out to ${optedOut.join(", ")}.`]
            : [],
        ];
        setAlert(
          results.flat().join(", "),
          failed.length + bounced.length + optedOut.length === 0,
        );
        setRecipients([]);
        toggleSendDropdownOpen();
        onScheduledEmailsChange();
      })
      .catch(onUnexpectedError)
      .finally(() => setSending(false));
  };

  const doScheduleAutoEmail = () => {
    setSending(true);
    const schedule =
      weeklyMonthly === "weekly"
        ? daySelected
        : `Month${periodInMonthSelected}${weekdaysOnly ? "Weekday" : ""}`;
    createScheduledEmail({
      emailAddresses: recipients,
      definition_id: id!,
      active: true,
      schedule: schedule!,
      configuration: {
        properties: selection ?? {},
        propertyId,
        attachmentType,
      },
    })
      .then(() => {
        setAlert("Scheduled email created successfully", true);
        toggleSendDropdownOpen();
        onScheduledEmailsChange();
      })
      .catch(onUnexpectedError)
      .finally(() => setSending(false));
  };

  const viewScheduledEmails = () => {
    toggleSendDropdownOpen();
    onViewScheduledEmailsClick();
  };

  const renderWeekly = () => {
    const weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return (
      <div className="flex-row mt-3">
        {weekDays.map((day) => {
          return (
            <div
              key={day}
              className="flex-col vertical-checkbox align-items-center"
              style={{ flex: 1 }}
            >
              <Input
                id={`weekday-${day}`}
                type="radio"
                checked={daySelected === day}
                onChange={() => {
                  if (daySelected === day) {
                    setDaySelected(undefined);
                  } else {
                    setDaySelected(day);
                  }
                }}
              />
              <Label for={`weekday-${day}`}>{day.substring(0, 2)}</Label>
            </div>
          );
        })}
      </div>
    );
  };

  const renderMonthly = () => {
    return (
      <>
        <ButtonGroup className="mt-3 w-100">
          <Button
            color="primary"
            outline={periodInMonthSelected !== "Start"}
            onClick={() => setPeriodInMonthSelected("Start")}
          >
            Start
          </Button>
          <Button
            color="primary"
            outline={periodInMonthSelected !== "Mid"}
            onClick={() => setPeriodInMonthSelected("Mid")}
          >
            Mid
          </Button>
          <Button
            color="primary"
            outline={periodInMonthSelected !== "End"}
            onClick={() => setPeriodInMonthSelected("End")}
          >
            End
          </Button>
        </ButtonGroup>
        <FormGroup check className="mt-3">
          <Label check>
            <Input
              type="checkbox"
              checked={weekdaysOnly}
              onChange={() => setWeekdaysOnly(!weekdaysOnly)}
            />{" "}
            Weekdays only
          </Label>
        </FormGroup>
      </>
    );
  };

  return (
    <ButtonDropdown isOpen={sendDropdownOpen} toggle={toggleSendDropdownOpen}>
      <DropdownToggle
        id="paper-plane"
        className="jh-action-icon ms-1 dropdown-toggle"
      >
        <FontAwesomeIcon icon={faPaperPlane} />
        {scheduledEmails.length ? (
          <span className="schedule-count">
            {scheduledEmails.length < 10 ? scheduledEmails.length : "*"}
          </span>
        ) : null}
      </DropdownToggle>
      {!sendDropdownOpen ? (
        <UncontrolledPopover
          placement="bottom"
          trigger="hover"
          target="paper-plane"
        >
          <PopoverBody>{`Email ${
            isDashboard ? "Dashboard" : "Report"
          }`}</PopoverBody>
        </UncontrolledPopover>
      ) : null}
      <DropdownMenu style={{ width: 350 }}>
        <div className="px-3">
          <div className="mb-3">
            <strong>{`Email ${isDashboard ? "Dashboard" : "Report"}`}</strong>
            {scheduledEmails.length ? (
              <>
                <span className="ms-2">( </span>
                <span
                  className="btn-link view-scheduled-emails-btn"
                  onClick={viewScheduledEmails}
                >
                  {scheduledEmails.length} scheduled
                </span>
                <span> )</span>
              </>
            ) : null}
          </div>
          <FormGroup>
            <EmailsInput onChange={setRecipients} />
          </FormGroup>
          {!isDashboard && (
            <div className="mt-3">
              <ButtonGroup className="w-100">
                <Button
                  color="primary"
                  outline={attachmentType !== "excel"}
                  onClick={() => setAttachmentType("excel")}
                >
                  Excel
                </Button>
                <Button
                  color="primary"
                  outline={attachmentType !== "pdf"}
                  onClick={() => setAttachmentType("pdf")}
                >
                  PDF
                </Button>
                <Button
                  color="primary"
                  outline={attachmentType !== "all"}
                  onClick={() => setAttachmentType("all")}
                >
                  All
                </Button>
              </ButtonGroup>
            </div>
          )}
          {id == null ? null : (
            <FormGroup check className="mt-3">
              <Label check>
                <Input
                  type="checkbox"
                  checked={scheduleAutoEmail}
                  onChange={() => setScheduleAutoEmail(!scheduleAutoEmail)}
                />{" "}
                Schedule automatic email
              </Label>
            </FormGroup>
          )}
          {scheduleAutoEmail ? (
            <div className="mt-3">
              <ButtonGroup className="w-100">
                <Button
                  color="primary"
                  outline={weeklyMonthly !== "weekly"}
                  onClick={() => setWeeklyMonthly("weekly")}
                >
                  Weekly
                </Button>
                <Button
                  color="primary"
                  outline={weeklyMonthly !== "monthly"}
                  onClick={() => setWeeklyMonthly("monthly")}
                >
                  Monthly
                </Button>
              </ButtonGroup>
            </div>
          ) : null}
          {scheduleAutoEmail && weeklyMonthly === "weekly"
            ? renderWeekly()
            : scheduleAutoEmail && weeklyMonthly === "monthly"
              ? renderMonthly()
              : null}
          {scheduleAutoEmail ? (
            <div className="flex-row mt-3 align-items-center">
              <FontAwesomeIcon className="weekly-coffee me-2" icon={faMugHot} />
              <p className="mb-0 text-wrap">
                Automatic emails go out first thing in the morning.
              </p>
            </div>
          ) : null}
          <div className="mt-3 w-100">
            <Button
              color="primary"
              className="w-100 mb-2 position-relative"
              onClick={scheduleAutoEmail ? doScheduleAutoEmail : doSend}
              disabled={sendButtonDisabled}
              style={sending ? { cursor: "wait" } : {}}
            >
              {scheduleAutoEmail ? "Schedule" : "Send"}
              {sending ? (
                <Spinner
                  color="light"
                  size="sm"
                  style={{
                    position: "absolute",
                    right: ".625rem",
                    top: ".625rem",
                  }}
                />
              ) : null}
            </Button>
          </div>
        </div>
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default withAlertModal(SendScheduleEmail);
