import {
  asPropertyIds,
  ColumnValue,
  CustomColumn,
  CustomColumnWithValue,
  DateExpression,
  exhaustiveCheck,
  ignoreCaseIncludesMatcher,
  isAllProperties,
  isDateExpression,
  isNameExpression,
  isPeriodExpression,
  isPropertiesByColumns,
  isPropertiesByGroup,
  isPropertiesByIdOrIds,
  isPropertiesByMetadata,
  isPropertiesComparison,
  isRangeExpression,
  mapDefined,
  NewPropertyDto,
  PeriodExpression,
  PropertiesByColumn,
  PropertiesSelection,
  PropertyClass,
  PropertyColumnDefaultTitle,
  PropertyRegion,
  PropertyType,
  PureDate,
  RangeExpression,
  SystemVendor,
} from "@joyhub-integration/shared";
import { AxiosResponse } from "axios";
import loadImage, { LoadImageResult } from "blueimp-load-image";
import { Buffer } from "buffer";
import { isBoolean, isNumber, mapValues, toLower } from "lodash";
import { compare, orderBy } from "natural-orderby";
import { PropertyGroup } from "../components/properties/propertyGroupService";
import {
  apiUrl,
  axiosBlobConfig,
  axiosConfig,
  axiosJsonConfig,
} from "../utils/api";
import { dateOf } from "../utils/date";
import { isString } from "../utils/string";
import { deduplicateInsensitively } from "../utils/tagUtils";
import axios from "./axios";

const naturallyCompare = compare();

export interface Property {
  id: number;
  system_id: number;
  foreign_id: number;
  hidden: boolean;
  is_comparable?: boolean;
  property_code: string;
  property_alias?: string;
  property_name: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  zip_code?: string;
  front_end: boolean;
  back_end: boolean;
  back_end_property_id: number;
  source_property_id?: string;
  region: PropertyRegion | null;
  pms_name?: string;
  pms_override_columns: string[];
  msa?: string;
  apn?: string;
  fips?: string;
  owner_name?: string;
  contact_person_name?: string;
  contact_person_email?: string;
  market?: string;
  website?: string;
  phone?: string;
  total_sqft?: number;
  rentable_sqft?: number;
  parcel_size?: number;
  year_built?: number;
  year_updated?: number;
  capitalization_rate?: number;
  property_class?: PropertyClass;
  property_type?: PropertyType;
  unit_count?: number;
  floor_count?: number;
  building_count?: number;
  elevator_count?: number;
  parking_count?: number;
  commercial_unit_count?: number;
  purchase_date?: Date;
  purchase_price?: number;
  system_name?: string;
  tags: Array<string>;
  metadata: Record<string, string | number>;
  image_guid?: string;
  user_bool_1?: boolean;
  user_bool_2?: boolean;
  user_bool_3?: boolean;
  user_bool_4?: boolean;
  user_date_1?: Date;
  user_date_2?: Date;
  user_date_3?: Date;
  user_date_4?: Date;
  user_decimal_1?: number;
  user_decimal_2?: number;
  user_decimal_3?: number;
  user_decimal_4?: number;
  user_numeric_1?: number;
  user_numeric_2?: number;
  user_numeric_3?: number;
  user_numeric_4?: number;
  user_int_1?: number;
  user_int_2?: number;
  user_int_3?: number;
  user_int_4?: number;
  user_money_1?: number;
  user_money_2?: number;
  user_money_3?: number;
  user_money_4?: number;
  user_text_1?: string;
  user_text_2?: string;
  user_text_3?: string;
  user_text_4?: string;
}

export type PropertyFilter = Omit<
  Property,
  | "id"
  | "system_id"
  | "foreign_id"
  | "hidden"
  | "front_end"
  | "back_end"
  | "back_end_property_id"
  | "pms_override_columns"
  | "source_property_id"
  | "tags"
  | "metadata"
  | "image_guid"
  | "contact_person_name"
  | "contact_person_email"
  | "year_built"
  | "system_name"
>;

export function getMockPropertyFilter(): PropertyFilter {
  return {
    property_code: "",
    property_alias: "",
    property_name: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
    region: null,
    pms_name: "",
    msa: "",
    apn: "",
    fips: "",
    owner_name: "",
    market: "",
    website: "",
    phone: "",
    total_sqft: 0,
    rentable_sqft: 0,
    parcel_size: 0,
    year_updated: 0,
    capitalization_rate: 0,
    property_class: PropertyClass.a,
    property_type: PropertyType.affordable,
    is_comparable: false,
    unit_count: 0,
    floor_count: 0,
    building_count: 0,
    elevator_count: 0,
    parking_count: 0,
    commercial_unit_count: 0,
    purchase_date: dateOf(""),
    purchase_price: 0,
    user_bool_1: false,
    user_bool_2: false,
    user_bool_3: false,
    user_bool_4: false,
    user_date_1: new Date(),
    user_date_2: new Date(),
    user_date_3: new Date(),
    user_date_4: new Date(),
    user_decimal_1: 0,
    user_decimal_2: 0,
    user_decimal_3: 0,
    user_decimal_4: 0,
    user_numeric_1: 0,
    user_numeric_2: 0,
    user_numeric_3: 0,
    user_numeric_4: 0,
    user_int_1: 0,
    user_int_2: 0,
    user_int_3: 0,
    user_int_4: 0,
    user_money_1: 0,
    user_money_2: 0,
    user_money_3: 0,
    user_money_4: 0,
    user_text_1: "",
    user_text_2: "",
    user_text_3: "",
    user_text_4: "",
  };
}

export type PropertyDropDownType =
  | typeof PropertyClass
  | typeof PropertyRegion
  | typeof PropertyType;

export interface PropertyWithUnitCount extends Property {
  insightUnitCount?: number;
}

export interface PropertyWithVendor extends Property {
  vendor?: SystemVendor;
}

export function getColumnName(key: string, customColumns: CustomColumn[]) {
  return (
    (customColumns ?? []).find((col) => col.columnKey === key)?.name ??
    PropertyColumnDefaultTitle[
      key as keyof typeof PropertyColumnDefaultTitle
    ] ??
    ""
  );
}

export async function getProperties(
  hidden: boolean = false,
  sortBy: string = "property_name",
  sortDirection: string = "ASC",
  pageNumber?: number,
  search?: string,
): Promise<Array<Property>> {
  const hiddenParam = `?hidden=${hidden ? "true" : "false"}`;
  const searchParam =
    search && search.trim() !== ""
      ? `&search=${encodeURIComponent(search)}`
      : "";
  const sortParams = `&sortBy=${sortBy}&sortDirection=${sortDirection}${
    pageNumber ? `&pageNumber=${pageNumber}` : ""
  }`;
  const queryParams = hiddenParam + searchParam + sortParams;
  return axios
    .get(apiUrl(`/properties${queryParams}`), axiosConfig)
    .then((res) => res.data.properties as Array<Property>);
}

export async function getPMSProperties(
  systemIds: number[],
): Promise<Array<PropertyWithVendor>> {
  let systems = Array.from(new Set(systemIds.map((system) => String(system))));

  const queryParams = systems
    .map((id) => `systemIds=${encodeURIComponent(id)}`)
    .join("&");

  return axios
    .get(apiUrl(`/properties/pms?${queryParams}`), axiosConfig)
    .then((res) => res.data.properties as Array<PropertyWithVendor>);
}

export async function getProperty(id: number): Promise<Property> {
  return axios
    .get(apiUrl(`/properties/${id}`), axiosConfig)
    .then((res) => res.data as Property);
}

export async function getPropertySharing(id: number): Promise<number[]> {
  return axios
    .get<{
      organizations: number[];
    }>(apiUrl(`/properties/${id}/sharing`), axiosConfig)
    .then((res) => res.data.organizations);
}

export async function setPropertySharing(
  id: number,
  organizations: number[],
): Promise<void> {
  return axios.put(
    apiUrl(`/properties/${id}/sharing`),
    { organizations },
    axiosJsonConfig,
  );
}

export async function hideUnHideProperty(
  id: number,
  hide: boolean,
): Promise<any> {
  return axios.put(apiUrl(`/properties/${id}/hidden`), hide, axiosJsonConfig);
}

export async function softDeleteProperty(id: number): Promise<any> {
  return axios.put(apiUrl(`/properties/${id}/soft-delete`), {}, axiosConfig);
}

export async function editColumn(
  id: number,
  columnKey: string,
  columnType: string,
  columnValue: string,
): Promise<any> {
  return axios.put(
    apiUrl(`/properties/${id}`),
    { columnKey, columnType, columnValue },
    axiosJsonConfig,
  );
}

export async function addProperty(property: NewPropertyDto): Promise<any> {
  return axios.post(apiUrl(`/properties`), { ...property }, axiosJsonConfig);
}

export async function addTag(id: number, tag: string): Promise<any> {
  return axios.post(apiUrl(`/properties/${id}/tag`), tag, axiosJsonConfig);
}

export async function removeTag(id: number, tag: string): Promise<any> {
  return axios.post(apiUrl(`/properties/${id}/untag`), tag, axiosJsonConfig);
}

export async function editCustomColumn(
  id: number,
  customColumn: CustomColumnWithValue,
) {
  return axios.post(
    apiUrl(`/properties/${id}/customColumns`),
    {
      customColumn,
    },
    axiosConfig,
  );
}

export async function getAvailableTags(): Promise<Array<string>> {
  return axios
    .get(apiUrl("/properties/tags"), axiosConfig)
    .then((res) => res.data.tags as Array<string>);
}

export async function exportProperties(): Promise<AxiosResponse> {
  return axios.get(apiUrl("/properties/export"), axiosBlobConfig);
}

export async function importProperties(file: File): Promise<AxiosResponse> {
  // Pretty gruesome but uploading files through aws-serverless-express is a bit
  // of an unknown so hack it as json.
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = () =>
      resolve(
        axios.put(
          apiUrl("/properties/import"),
          {
            name: file.name,
            type: file.type,
            body: Buffer.from(fr.result as ArrayBuffer).toString("base64"),
          },
          axiosJsonConfig,
        ),
      );
    fr.onerror = () => reject(`Error reading file.`);
    fr.readAsArrayBuffer(file);
  });
}

/**
 * Before uploading the property image to the server, we must contend with the fact that there is a maximum upload size
 * of 6mb for AWS Lambdas. We constrain the image to 1280 by 1280 and send it as a PNG (a well supported type), which
 * should stay comfortably under 3mb, while giving us enough details.
 */
export const uploadPropertyImage = async (
  id: number,
  file: File,
): Promise<AxiosResponse> => {
  return loadImage(file, { maxWidth: 1280, maxHeight: 1280, canvas: true })
    .then((result: LoadImageResult) => {
      const canvas = result.image as HTMLCanvasElement; // I know this to be a canvas, because I requested it there ^
      return new Promise<ArrayBuffer>((resolve, reject) => {
        canvas.toBlob(async (blob: Blob | null) => {
          if (!blob) {
            reject("Unable to create blob from uploaded image");
          } else {
            resolve(await blob.arrayBuffer());
          }
        }, "image/png"); // most browsers like png
      });
    })
    .then((buffer: ArrayBuffer) => {
      return axios.put(
        apiUrl(`/properties/${id}/imageUpload`),
        {
          name: file.name,
          type: file.type,
          body: Buffer.from(buffer).toString("base64"),
        },
        axiosJsonConfig,
      );
    });
};

// match a property by some filter text
export const propertyMatcher = (filterText: string) => {
  const filterMatches = ignoreCaseIncludesMatcher(filterText);
  return (p: Property) =>
    filterMatches(p.property_code) || filterMatches(p.property_name);
};

// Should plausibly be "My properties" when you have a limited view, but not for now
export const AllPropertiesLabel = "All properties";

export const getPropertySelectionLabel = (
  selection: PropertiesSelection,
  allProperties: Property[],
  propertyGroups: PropertyGroup[],
  verbose: boolean = false,
  customColumns: CustomColumn[],
): string => {
  if (isAllProperties(selection)) {
    return AllPropertiesLabel;
  } else if (isPropertiesByIdOrIds(selection)) {
    const pids = asPropertyIds(selection);
    if (verbose) {
      const propertyMap: Record<number, Property> = {}; // we go back and forth..
      for (const p of allProperties) {
        propertyMap[p.id] = p;
      }
      const Max = 5;
      const properties = mapDefined(pids, (id) => propertyMap[id])
        .map((p) => p.property_name)
        .sort(naturallyCompare);
      const remaining = properties.length - Max;
      return (
        properties.slice(0, Max).join(", ") +
        (remaining > 0 ? ` and ${remaining} more` : "")
      );
    } else {
      const count = pids.length;
      if (count === 1) {
        const property = allProperties.find((p) => p.id === pids[0]);
        return `${property?.property_name}`;
      } else {
        return `${count} properties`;
      }
    }
  } else if (isPropertiesByColumns(selection)) {
    return Object.entries(selection.columns)
      .map(([column, filter]) => {
        const name = customColumns
          ? getColumnName(column, customColumns)
          : column;
        return getFilterLabel(column, name, filter);
      })
      .join(",");
  } else if (isPropertiesByMetadata(selection)) {
    return "Properties by metadata (unsupported)";
  } else if (isPropertiesByGroup(selection)) {
    const group = propertyGroups.find((g) => g.id === selection.group);
    return group == null ? "Unknown selection" : group.name;
  } else if (isPropertiesComparison(selection)) {
    return "Properties comparison";
  }
  exhaustiveCheck(selection);
};

export const getSelectedProperties = (
  selection: PropertiesSelection,
  properties: Property[],
  propertyGroups: PropertyGroup[],
): Property[] => {
  if (isAllProperties(selection)) {
    return properties;
  } else if (isPropertiesByIdOrIds(selection)) {
    const pids = new Set(asPropertyIds(selection));
    return properties.filter((p) => pids.has(p.id));
  } else if (isPropertiesByColumns(selection)) {
    return filterPropertiesByMetadata(properties, selection);
  } else if (isPropertiesByMetadata(selection)) {
    return [];
  } else if (isPropertiesByGroup(selection)) {
    const group = propertyGroups.find((g) => g.id === selection.group);
    return group == null
      ? []
      : getSelectedProperties(group.selection, properties, []);
  } else if (isPropertiesComparison(selection)) {
    const pids = new Set<number>();
    if (selection.vs != null) {
      for (const p of getSelectedProperties(
        selection.vs,
        properties,
        propertyGroups,
      )) {
        pids.add(p.id);
      }
    }
    for (const sel of selection.comparison) {
      for (const p of getSelectedProperties(sel, properties, propertyGroups)) {
        pids.add(p.id);
      }
    }
    return properties.filter((p) => pids.has(p.id));
  }
  exhaustiveCheck(selection);
};

export const filterPropertiesByMetadata = (
  properties: Property[],
  selection: PropertiesByColumn,
) => {
  return properties.filter((property) => {
    return Object.entries(selection.columns).every(([field, expressions]) => {
      const meta = property.metadata[field];
      if (meta) {
        if (expressions.every(isString) && isString(meta)) {
          // simple match
          return new Set(expressions.map(toLower)).has(meta.toLowerCase());
        } else if (expressions.every(isNameExpression) && isString(meta)) {
          // /* This is overly complicated. We do need to evaluate the expression and do a check though. */
          // /*
          //  * We also need to consider that you could combine a name expression (i.e. that you own this prop)
          //  * with a string expression (i.e. looking for props owned by someone else).
          //  * */
          // const compacted = compact(
          //   expressions.map((e) => extractPersonMetadata(e, viewer)),
          // );
          // return new Set(compacted.map(toLower)).has(meta.toLowerCase());
        } else if (
          expressions.every(isRangeExpression) &&
          isNumber(Number(meta))
        ) {
          /**
           * For a given field there can really only be one range expression.
           * */
          return expressions.every((expression) =>
            evaluateRangeExpression(meta, expression),
          );
        } else if (expressions.every(isDateExpression) && isString(meta)) {
          return expressions.every((expression) =>
            evaluateDateExpression(meta, expression),
          );
        } else if (expressions.every(isPeriodExpression) && isString(meta)) {
          return expressions.every((expression) =>
            evaluatePeriodExpression(meta, expression),
          );
        }
        return false;
      } else {
        return false;
      }
    });
  });
};

export const getSelectedPropertyIds = (
  selection: PropertiesSelection,
  allProperties: Property[],
  propertyGroups: PropertyGroup[],
): number[] =>
  getSelectedProperties(selection, allProperties, propertyGroups).map(
    (p) => p.id,
  );

export const getFilterLabel = (
  key: string,
  name: string,
  filter: ColumnValue[],
): string => {
  /* The fact that this is an array is only valuable in the string[] case. */
  const firstFilter = filter[0];
  if (isString(firstFilter)) {
    return `${name}: ${filter.join(",")}`;
  } else if (isRangeExpression(firstFilter)) {
    const { min, max } = firstFilter;
    return `${name}: ${min ?? "-"} to ${max ?? "-"}`;
  } else if (isDateExpression(firstFilter)) {
    const { after, before } = firstFilter;
    return `${name}: ${after ?? "-"} to ${before ?? "-"}`;
  } else if (isPeriodExpression(firstFilter)) {
    return `${name}: ${firstFilter.period}`;
  } else if (isBoolean(firstFilter)) {
    const trueLabel = key === "is_comparable" ? "Comp" : "Checked ☑";
    const falseLabel = key === "is_comparable" ? "Own" : "Not Checked";
    return `${name}: ${firstFilter === true ? trueLabel : falseLabel}`;
  } else {
    return "";
  }
};

export const getColumnOptions = (
  properties: Array<PropertyFilter>,
): Record<string, Array<string>> => {
  const values: Record<string, Array<string>> = {};
  for (const property of properties) {
    for (const [k, v] of Object.entries(property)) {
      if (v != null)
        if (values[k] == null) values[k] = [v?.toString() ?? ""];
        else values[k].push(v?.toString() ?? "");
    }
  }
  return mapValues(values, (o) => orderBy(deduplicateInsensitively(o)));
};

export const updateGeocode = async (id: number) => {
  return await axios.post(
    apiUrl(`/properties/${id}/geocode`),
    undefined,
    axiosJsonConfig,
  );
};

// const extractPersonMetadata = (v: NameExpression, person?: any) => {
//   if (person == null) {
//     return null;
//   } else if (v.name === "user.name") {
//     return person.name;
//   } else if (v.name === "user.email") {
//     return person.email;
//   }
//   exhaustiveCheck(v.name);
// }

/**
 * Evaluates numerical expressions with an inclusive-min and exclusive-max.
 * */
const evaluateRangeExpression = (
  value: string | number,
  expression: RangeExpression,
): boolean => {
  if (expression.min && expression.max) {
    return Number(value) >= expression.min && Number(value) <= expression.max;
  } else if (expression.min) {
    return Number(value) >= expression.min;
  } else if (expression.max) {
    return Number(value) <= expression.max;
  } else {
    return false;
  }
};

/**
 * Evaluates date expressions with an inclusive-min and exclusive-max.
 * */
const evaluateDateExpression = (
  value: string,
  expression: DateExpression,
): boolean => {
  const dateValue = new PureDate(value);
  if (dateValue.toString() !== "Invalid Date") {
    if (expression.before && expression.after) {
      return (
        (dateValue.greaterThan(expression.after) ||
          dateValue.equals(expression.after)) &&
        dateValue.lessThan(expression.before)
      );
    } else if (expression.before) {
      return dateValue.lessThan(expression.before);
    } else if (expression.after) {
      return (
        dateValue.greaterThan(expression.after) ||
        dateValue.equals(expression.after)
      );
    } else {
      return false;
    }
  } else {
    return false;
  }
};

/**
 * Evaluates date expressions with an inclusive-min and exclusive-max.
 * */
const evaluatePeriodExpression = (
  value: string,
  expression: PeriodExpression,
): boolean => {
  const dateValue = new PureDate(value);
  const now = new PureDate();
  if (dateValue.toString() !== "Invalid Date") {
    switch (expression.period) {
      case "1mo":
        // TODO: obviously days are not exactly months/years.
        return dateValue.greaterThan(now.withPlusDays(-30));
      case "3mo":
        return dateValue.greaterThan(now.withPlusDays(-90));
      case "6mo":
        return dateValue.greaterThan(now.withPlusDays(-180));
      case "1yr":
        return dateValue.greaterThan(now.withPlusDays(-365));
      default:
        return false;
    }
  } else {
    return false;
  }
};

export type PropertiesTabType = "dashboard" | "manage";
