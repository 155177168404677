import "./finance.css";

import {
  faEdit,
  faFileDownload,
  faPrint,
} from "@fortawesome/pro-light-svg-icons";
import {
  AccountTreeEntity,
  FinanceBook,
  FullAccountTreeNode,
} from "@joyhub-integration/shared";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { createEnumParam } from "serialize-query-params";
import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from "use-query-params";

import {
  getPropertySelectionLabel,
  getSelectedProperties,
} from "../../services/propertiesService";
import { usePropertiesSelectionQueryParam } from "../../utils/useQueryParams";
import Loading from "../app/Loading";
import PlatformContext from "../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";
import { LoadilyFadily, useLoadilyFadily } from "../common/allFadily";
import ButtonWithIcon from "../common/button/ButtonWithIcon";
import JhSelect, { IDropListItem } from "../common/JhSelect/JhSelect";
import JhCrumbar from "../navbar/JhCrumbar";
import PropertiesPicker from "../picker/PropertiesPicker";
import AccountByProperty from "./AccountByProperty";
import AccountTreeView from "./AccountTreeView";
import AnnualAccountTreeView from "./AnnualAccountTreeView";
import BudgetTreeView from "./BudgetTreeView";
import {
  financeBookAndBudgetOptions,
  getFullGlTree,
  getGlTrees,
} from "./financeService";

const BookParam = createEnumParam(["accrual", "cash", "budget"]);
const DefaultBook = "accrual";

const PeriodParam = createEnumParam([
  "3mo",
  "6mo",
  "9mo",
  "12mo",
  "ytd",
  "yearly",
]);
const DefaultPeriod = "12mo";

const periodOptions = [
  { label: "Trailing 3-Month", value: "3mo" },
  { label: "Trailing 6-Month", value: "6mo" },
  { label: "Trailing 9-Month", value: "9mo" },
  { label: "Trailing 12-Month", value: "12mo" },
  { label: "Year to Date", value: "ytd" },
  { label: "3 Years and Year to Date", value: "yearly" },
] as const;

type FinanceScreenParams = {
  tree?: string;
  book?: string;
  period?: string;
};

const FinanceScreen: React.FC<WithAlertModalProps> = ({
  onUnexpectedError,
}) => {
  const url = useLocation().pathname; // maybe?
  const params = useParams<FinanceScreenParams>();
  const [accountTrees, setAccountTrees] = useState<AccountTreeEntity[]>([]);
  const [treeNodes, setTreeNodes] = useState<FullAccountTreeNode[]>([]);
  const [accountTreeCode, setAccountTreeCode] = useQueryParam(
    "tree",
    withDefault(StringParam, params.tree),
  );
  const [book, setBook] = useQueryParam(
    "book",
    withDefault(BookParam, BookParam.decode(params.book) ?? DefaultBook),
  );
  const [period, setPeriod] = useQueryParam(
    "period",
    withDefault(
      PeriodParam,
      PeriodParam.decode(params.period) ?? DefaultPeriod,
    ),
  );
  const [reportId] = useQueryParam("id", NumberParam);
  const [selection] = usePropertiesSelectionQueryParam();
  const [expandNode, setExpandNode] = useQueryParam("expandNode", NumberParam);
  const [accountTree, setAccountTree] = useState<AccountTreeEntity>();
  const [breakout, setBreakout] = useState<FullAccountTreeNode>();
  const [loaded, setLoaded] = useState(false);
  const { propertiesMap, propertyGroups, organization } =
    useContext(PlatformContext).platform!;

  const bookOption = financeBookAndBudgetOptions.find((o) => o.value === book);
  const periodOption = periodOptions.find((o) => o.value === period);

  const backendProperties = useMemo(
    () => Object.values(propertiesMap).filter((p) => p.back_end),
    [propertiesMap],
  );

  const system = useMemo(
    () =>
      getSelectedProperties(
        selection ?? {},
        backendProperties,
        propertyGroups,
      )[0]?.system_id,
    [selection, backendProperties, propertyGroups],
  );
  const reportTitle = useMemo(
    () =>
      accountTree && bookOption && periodOption
        ? `${accountTree?.tree_name}, ${bookOption?.label}, ${periodOption?.label}`
        : undefined,
    [accountTree, bookOption, periodOption],
  );

  useEffect(() => {
    if (system != null && !loaded) {
      getGlTrees(system)
        .then((trees) => {
          // for now, to make it demo better...
          const hasYsi = trees.find((t) => t.tree_code?.startsWith("ysi_"));
          const cleanTrees = !hasYsi
            ? trees
            : trees
                .filter((t) => t.tree_code?.startsWith("ysi_"))
                .map(({ tree_name, ...rest }) => ({
                  ...rest,
                  tree_name: tree_name?.replace("YSI ", "") ?? null,
                }));
          setAccountTrees(cleanTrees);
          if (accountTreeCode) {
            setAccountTree(
              cleanTrees.find((t) => t.tree_code === accountTreeCode),
            );
          }
          setLoaded(true);
        })
        .catch(onUnexpectedError);
    }
  }, [system, loaded, onUnexpectedError, accountTreeCode]);

  useEffect(() => {
    if (system && accountTree) {
      getFullGlTree(system, accountTree.tree_id)
        .then((apexNodes) => setTreeNodes(apexNodes))
        .catch(onUnexpectedError);
    }
  }, [system, accountTree, onUnexpectedError]);

  useEffect(() => {
    if (treeNodes?.length) {
      if (!expandNode) {
        setBreakout(undefined);
      } else if (breakout?.tree_node_id !== expandNode) {
        const find = (
          nodes: FullAccountTreeNode[],
        ): FullAccountTreeNode | undefined => {
          for (const node of nodes) {
            const found =
              node.tree_node_id === expandNode ? node : find(node.children);
            if (found !== undefined) return found;
          }
        };
        setBreakout(find(treeNodes));
      }
    }
  }, [expandNode, breakout, treeNodes]);

  const accountOptions = accountTrees.map(
    ({ tree_name, tree_code, tree_id }) => ({
      label: tree_name!,
      value: tree_id.toString(),
    }),
  );

  const accountSelected = (sel: IDropListItem) => {
    const tree = accountTrees.find((t) => t.tree_id === parseInt(sel.value));
    if (tree) {
      setBreakout(undefined);
      setExpandNode(undefined, "replaceIn");
      setAccountTree(tree);
      setAccountTreeCode(tree.tree_code, "replaceIn");
    }
  };

  const bookSelected = (sel: IDropListItem) => {
    setExpandNode(undefined, "replaceIn");
    setBook(BookParam.decode(sel.value), "replaceIn");
    setBreakout(undefined);
  };

  const periodSelected = (sel: IDropListItem) => {
    setExpandNode(undefined, "replaceIn");
    setPeriod(PeriodParam.decode(sel.value), "replaceIn");
    setBreakout(undefined);
  };

  const bookId = book === "cash" ? FinanceBook.Cash : FinanceBook.Accrual;
  const selectedTree = accountTree?.tree_id?.toString();

  const propertiesLabel = useMemo(
    () =>
      getPropertySelectionLabel(
        selection ?? {},
        backendProperties,
        propertyGroups,
        undefined,
        organization.configuration.customColumns ?? [],
      ),
    [selection, backendProperties, propertyGroups],
  );

  const numMonths =
    period === "ytd"
      ? new Date().getMonth() + 1
      : parseInt(period ?? DefaultPeriod);

  const fadily = useLoadilyFadily(loaded);

  return (
    <div className="jh-page-layout">
      <JhCrumbar
        primary={
          breakout ? (accountTree?.tree_name ?? "Financials") : "Reports"
        }
        primaryPath={breakout ? url : "/reports"}
        secondary={
          (breakout ? breakout.tree_node_name : reportTitle) ?? "Financials"
        }
        back={!!breakout}
      >
        <div className="d-flex flex-grow-1 justify-content-end" style={fadily}>
          {!Object.keys(params).length ? (
            <>
              <JhSelect
                options={accountOptions}
                isSearchable={true}
                placeholder="Account Tree"
                isMulti={false}
                onValueUpdate={accountSelected}
                value={accountOptions.find((o) => o.value === selectedTree)}
                className="flex-grow-1 ms-3 me-2"
              />
              <JhSelect
                options={financeBookAndBudgetOptions}
                isSearchable={true}
                placeholder="Book"
                isMulti={false}
                onValueUpdate={bookSelected}
                value={bookOption}
                className="flex-grow-1 me-2"
              />
              <JhSelect
                options={periodOptions}
                isSearchable={true}
                placeholder="Period"
                isMulti={false}
                onValueUpdate={periodSelected}
                value={periodOption}
                className="flex-grow-1 me-2"
              />
            </>
          ) : null}
        </div>
      </JhCrumbar>
      <LoadilyFadily loaded={loaded}>
        <div className="dashboard-filters d-flex w-100 align-items-center mt-4 px-4 justify-content-between">
          <div className="flex-row ps-1">
            <ButtonWithIcon
              icon={faPrint}
              tooltip="Print Report"
              className="jh-action-icon"
              onClick={() => window.print()}
              id="print-report"
            />
            <ButtonWithIcon
              icon={faFileDownload}
              tooltip="Download Report"
              className="jh-action-icon"
              disabled={true}
              id="download-report"
            />
            {reportId == null ? null : (
              <ButtonWithIcon
                icon={faEdit}
                tooltip="Edit Report"
                className="jh-action-icon"
                tag={Link}
                to={`/reports/${reportId}/edit`}
                iconStyle={{ verticalAlign: "-.52em", marginLeft: ".2em" }}
                id="edit-report"
              />
            )}
          </div>
          <PropertiesPicker
            allProperties={backendProperties}
            propertyGroups={propertyGroups}
            backEnd
            right
          />
        </div>
        <div className="jh-page-content ">
          <div className="finance-screen-main">
            {!accountTree ? (
              <div />
            ) : treeNodes[0]?.tree_id !== accountTree.tree_id ||
              system == null ? (
              <Loading />
            ) : !breakout ? (
              period === "yearly" ? (
                <AnnualAccountTreeView
                  system={system}
                  numYears={4}
                  accountTree={accountTree}
                  treeNodes={treeNodes}
                  book={bookId}
                  propertiesLabel={propertiesLabel ?? "All Properties"}
                  onBreakout={(node) => {
                    setBreakout(node);
                    setExpandNode(node.tree_node_id, "pushIn");
                  }}
                />
              ) : book === "budget" ? (
                <BudgetTreeView
                  system={system}
                  accountTree={accountTree}
                  treeNodes={treeNodes}
                  propertiesLabel={propertiesLabel}
                />
              ) : (
                <AccountTreeView
                  system={system}
                  numMonths={numMonths}
                  accountTree={accountTree}
                  treeNodes={treeNodes}
                  book={bookId}
                  propertiesLabel={propertiesLabel}
                  periodLabel={periodOption?.label}
                  onBreakout={(node) => {
                    setBreakout(node);
                    setExpandNode(node.tree_node_id, "pushIn");
                  }}
                />
              )
            ) : (
              <AccountByProperty
                system={system}
                numMonths={numMonths}
                accountTree={accountTree}
                book={bookId}
                treeNode={breakout}
                propertiesLabel={propertiesLabel}
              />
            )}
          </div>
        </div>
      </LoadilyFadily>
    </div>
  );
};

export default withAlertModal(FinanceScreen);
