import React, { useEffect, useState } from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import dashboardService, {
  DashboardHistoryInfo,
} from "../../../services/dashboardService";
import { formatFullDateAndTime } from "../../../utils/number";
import { useOnUnexpectedError } from "../../common/alert/withAlertModal";
import UncontrolledModal from "../../common/modal/UncontrolledModal";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";

type ReportHistoryModalProps = {
  id: number;
  onSubmit: (definition: any) => void;
  onClose: () => void;
};

const Columns: KeyValue<DashboardHistoryInfo>[] = [
  {
    key: "edited",
    title: "Edited On",
    toValue: (h) => formatFullDateAndTime(h.edited),
    sortValue: (h) => new Date(h.edited).getTime(),
  },
  { key: "editor", title: "Edited By", sortable: false },
];

const ReportHistoryModal: React.FC<ReportHistoryModalProps> = ({
  id,
  onClose,
  onSubmit,
}) => {
  const [rows, setRows] = useState<DashboardHistoryInfo[]>();
  const [selection, setSelection] = useState<DashboardHistoryInfo>();
  const [definition, setDefinition] = useState<any>();
  const onUnexpectedError = useOnUnexpectedError();

  useEffect(() => {
    dashboardService
      .getDashboardHistories(id)
      .then(setRows)
      .catch(onUnexpectedError);
  }, [id, onUnexpectedError]);

  useEffect(() => {
    if (selection)
      dashboardService
        .getDashboardHistory(id, selection.id)
        .then(({ definition }) => setDefinition(definition))
        .catch(onUnexpectedError);
  }, [id, selection, onUnexpectedError]);

  return (
    <UncontrolledModal>
      <ModalHeader>Restore Report</ModalHeader>
      <ModalBody style={{ overflow: "auto", maxHeight: "50vh" }}>
        <TableWithSelection
          selected={selection}
          onSelectedChange={setSelection}
          columns={Columns}
          rows={rows}
          sortDirection="desc"
        />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => onSubmit(definition)}
          color="primary"
          disabled={definition == null}
        >
          Restore
        </Button>
      </ModalFooter>
    </UncontrolledModal>
  );
};

export default ReportHistoryModal;
