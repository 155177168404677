import { ReactNode, forwardRef, memo } from "react";

type RowProps = {
  rowId: string | number;
  columns: string[];
  isSelected?: boolean;
  singleLineRows?: boolean;
  onRowClick: (rowId: string | number) => void;
  valueFromId: (
    rowId: string | number,
    colKey: string,
    idx: number,
  ) => ReactNode | undefined;
  style: React.CSSProperties;
};

const RowWithSelection = memo(
  forwardRef<HTMLTableRowElement, RowProps>(
    (
      {
        rowId,
        columns,
        isSelected,
        singleLineRows,
        onRowClick,
        valueFromId,
        style,
      },
      ref,
    ) => {
      return (
        <tr
          ref={ref}
          className={
            isSelected
              ? "jh-table-with-selection-row-selected"
              : "jh-table-with-selection-row"
          }
          style={{ whiteSpace: singleLineRows ? "nowrap" : "normal", ...style }}
          key={`row-${rowId}`}
          onClick={() => onRowClick(rowId)}
        >
          {columns.map((col, idx) => (
            <td key={`cell-${col}-${rowId}`}>{valueFromId(rowId, col, idx)}</td>
          ))}
        </tr>
      );
    },
  ),
  (prevProps, nextProps) => prevProps.isSelected === nextProps.isSelected,
);

export default RowWithSelection;
