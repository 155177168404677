import { PropertiesSelection } from "@joyhub-integration/shared";
import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
} from "reactstrap";

import { useBreakpoint } from "../../hooks/useBreakpoint";
import { Dashboard } from "../../services/dashboardService";
import {
  asPropertiesQuery,
  usePropertiesSelectionQueryParam,
} from "../../utils/useQueryParams";
import { useLoadilyFadily } from "../common/allFadily";

const formatDashboardUrl = (
  dashboard: Dashboard,
  linkPrefix: string,
  selection: PropertiesSelection | undefined,
) => `${linkPrefix}/${dashboard.identifier}?${asPropertiesQuery(selection)}`;

interface DashboardSwitcherProps {
  activeDashboardId?: number;
  dashboards: Dashboard[];
  linkPrefix: string;
  allTheMore?: boolean;
}

const DashboardSwitcher = ({
  allTheMore,
  dashboards,
  activeDashboardId,
  linkPrefix,
}: DashboardSwitcherProps) => {
  const [numberToShow, setNumberToShow] = useState(5);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selection] = usePropertiesSelectionQueryParam();

  const breakpoint = useBreakpoint();

  const toggle = () => setDropdownOpen(!dropdownOpen);

  const fadily = useLoadilyFadily(dashboards.length > 0);

  useEffect(() => {
    switch (breakpoint) {
      case "xs":
        setNumberToShow(0);
        break;
      case "sm":
        setNumberToShow(1);
        break;
      default:
        setNumberToShow(2);
        break;
    }
  }, [breakpoint]);

  const mainDashboards = allTheMore ? [] : dashboards.slice(0, numberToShow);

  const moreDashboards = allTheMore
    ? dashboards
    : dashboards.slice(numberToShow);

  const activeMoreDashboard = moreDashboards.find(
    (dashboard) => dashboard.id === activeDashboardId,
  );

  return (
    <Nav
      pills
      justified={breakpoint === "sm"}
      className="justify-content-end dashboard-switcher"
      style={fadily}
    >
      {mainDashboards.map((dashboard) => (
        <NavItem key={dashboard.id} active={dashboard.id === activeDashboardId}>
          <NavLink
            className="nav-link"
            to={formatDashboardUrl(dashboard, linkPrefix, selection)}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {dashboard.name}
          </NavLink>
        </NavItem>
      ))}
      <Dropdown
        nav
        isOpen={dropdownOpen}
        toggle={toggle}
        active={activeMoreDashboard !== undefined}
      >
        <DropdownToggle
          nav
          caret
          style={
            breakpoint === "xs" || breakpoint === "sm"
              ? {
                  maxWidth: 150,
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }
              : undefined
          }
        >
          More{activeMoreDashboard && `: ${activeMoreDashboard.name}`}
        </DropdownToggle>
        <DropdownMenu end>
          {moreDashboards.map((dashboard) => (
            <DropdownItem
              key={dashboard.id}
              disabled={dashboard.id === activeDashboardId}
              tag={Link}
              to={formatDashboardUrl(dashboard, linkPrefix, selection)}
            >
              {dashboard.name}
            </DropdownItem>
          ))}
          <DropdownItem
            tag={Link}
            to={
              linkPrefix.startsWith("/dash")
                ? "/dashboards"
                : "/properties/dashboards"
            }
          >
            View all dashboards
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Nav>
  );
};

export default DashboardSwitcher;
