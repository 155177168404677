import "./tableWithSelection.css";

import {
  faSortAmountDown,
  faSortAmountUp,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { compare as naturally } from "natural-orderby";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Table } from "reactstrap";

import RowWithSelection from "./RowWithSelection";

const naturallyCompare = naturally();

export interface Selectable {
  id: number | string;
}

export interface KeyValue<T> {
  key: string; // keyof T;
  title: string;
  toValue?: (t: T, idx: number) => string | ReactNode;
  sortValue?: (t: T) => string | number | null | undefined;
  sortable?: boolean;
}

interface TableWithSelectionProps<T extends Selectable> {
  selected?: T;
  onSelectedChange: (selected?: T) => void;
  columns: Array<KeyValue<T>>;
  rows?: Array<T>;
  sortColumn?: string;
  sortDirection?: "asc" | "desc";
  singleLineRows?: boolean;
  rowStyle?: React.CSSProperties;
  moar?: () => void;
  onScrollEnd?: () => void;
  onSortChanged?: (field: string, dir: string) => void;
}

function TableWithSelection<T extends Selectable>({
  selected,
  onSelectedChange,
  columns,
  rows,
  sortColumn,
  sortDirection,
  singleLineRows,
  rowStyle = {},
  moar,
  onScrollEnd,
  onSortChanged,
}: TableWithSelectionProps<T>) {
  const [sortCol, setSortColumn] = useState(
    Math.max(
      0,
      columns.findIndex((c) => c.key === sortColumn),
    ),
  );
  const [ascending, setSortAscending] = useState(sortDirection !== "desc");

  const lastRowRef = useRef(null);

  useEffect(() => {
    const handleLastRowVisible = (entries: any) => {
      entries.forEach((entry: { isIntersecting: any }) => {
        if (entry.isIntersecting && onScrollEnd) {
          onScrollEnd();
        }
      });
    };

    const observer = new IntersectionObserver(handleLastRowVisible, {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    });

    const target = lastRowRef.current;
    if (target) observer.observe(target);
    return () => {
      if (target) observer.unobserve(target);
    };
  }, [onScrollEnd]);

  const sortClicked = (idx: number) => {
    const isAsc = sortCol === idx ? !ascending : true;
    setSortColumn(idx);
    setSortAscending(isAsc);
    if (onSortChanged) onSortChanged(columns[idx].key, isAsc ? "asc" : "desc");
  };

  const headers = columns.map((col, idx) => {
    const thProps =
      col.sortable === false
        ? {}
        : { onClick: () => sortClicked(idx), style: { cursor: "pointer" } };
    return (
      <th key={`col-${col.key}`} {...thProps}>
        {col.title}
        {idx === sortCol ? (
          <FontAwesomeIcon
            icon={ascending ? faSortAmountUp : faSortAmountDown}
            size="sm"
            color="#666"
            style={{ marginLeft: ".33em" }}
            aria-label={ascending ? "Ascending" : "Descending"}
          />
        ) : null}
      </th>
    );
  });

  const defaultCellValue = useCallback((wor: T, col: KeyValue<T>): string => {
    const row = wor as any;
    return row[col.key] !== undefined && row[col.key] !== null
      ? String(row[col.key])
      : "";
  }, []);

  const toVal = (row: T) => {
    const col = columns[sortCol];
    if (col.sortValue) {
      return col.sortValue(row);
    } else if (col.toValue) {
      const val = col.toValue(row, -1);
      if (typeof val === "string") return val; // could be react node
    }
    return defaultCellValue(row, col);
  };

  const sortedRows = rows?.sort((a, b) => {
    const av = toVal(a),
      bv = toVal(b);
    let cmp: number;
    if (av == null) {
      if (bv == null) cmp = 0;
      else cmp = -1;
    } else if (bv == null) {
      cmp = 1;
    } else if (typeof av === "number" && typeof bv === "number") {
      cmp = av - bv;
    } else {
      cmp = naturallyCompare(av, bv);
    }
    if (cmp === 0) cmp = naturallyCompare(a.id, b.id);
    return ascending ? cmp : -cmp;
  });

  const onRowClick = useCallback(
    (row?: T) => {
      if (!!selected && row?.id === selected.id) {
        onSelectedChange(undefined);
      } else {
        onSelectedChange(row);
      }
    },
    [selected, onSelectedChange],
  );

  const valueFromId = useCallback(
    (rowId: number | string, key: string, idx: number) => {
      const row = sortedRows?.find((row) => row.id === rowId);
      const col = columns.find((col) => col.key === key);
      if (col && row)
        return col.toValue ? col.toValue(row, idx) : defaultCellValue(row, col);
    },
    [sortedRows, defaultCellValue, columns],
  );

  const memoizedRows = useMemo(() => {
    return sortedRows?.map((row, i) => {
      const isLastRow = i === (sortedRows?.length ?? 1) - 1;
      return (
        <RowWithSelection
          key={`rowWithSelection-${row.id}`}
          style={rowStyle}
          ref={isLastRow ? lastRowRef : null}
          rowId={row.id}
          columns={columns.map((col) => col.key)}
          isSelected={selected?.id === row.id}
          singleLineRows={singleLineRows}
          onRowClick={(rowId) =>
            onRowClick(sortedRows.find((r: any) => r.id === rowId))
          }
          valueFromId={valueFromId}
        />
      );
    });
  }, [
    sortedRows,
    columns,
    selected,
    singleLineRows,
    onRowClick,
    valueFromId,
    rowStyle,
  ]);
  const noResult = (
    <tr>
      <td
        className="jh-table-with-selection-no-results"
        colSpan={columns.length + 1}
      >
        {memoizedRows === undefined ? "Loading..." : "No Results"}
      </td>
    </tr>
  );

  return (
    <div className="jh-table-wrapper">
      <Table className="jh-with-selection-table">
        <thead>
          <tr>{headers}</tr>
        </thead>
        <tbody>
          {memoizedRows?.length ? memoizedRows : noResult}
          {moar ? (
            <tr>
              <td colSpan={columns.length} style={{ padding: 0 }}>
                <VisibilitySensor
                  onChange={(visible: boolean) => visible && moar?.()}
                >
                  <div style={{ height: "4px" }} />
                </VisibilitySensor>
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </div>
  );
}

export default TableWithSelection;
