import { uniq } from "lodash";

import { getOrElseUpdate } from "./map";

// same as misc module's `groupBy`, but results in a Map instead of Record
// also, for brevity, doesn't do misc module's forced `mapValues` behavior
export const groupByForMap = <A, B extends string | number>(
  as: readonly A[],
  f: (a: A) => B,
): Map<B, A[]> => {
  const bcs = new Map<B, A[]>();
  for (const a of as) {
    const b = f(a);
    const cs = getOrElseUpdate(bcs, b, () => []);
    cs.push(a);
  }
  return bcs;
};

/** Combines a map and filter to remove undefined values.
 * This is a bit unsound but intended for object dereferencing that can silently return undefined. */
export const mapDefined = <A, B>(
  as: readonly A[],
  f: (a: A, i: number) => B | undefined,
): B[] => {
  const bs: B[] = [];
  let i = 0;
  for (const a of as) {
    const b = f(a, i++);
    if (b !== undefined) bs.push(b);
  }
  return bs;
};

/** Finds the first defined value from mapping an array of As to a B. */
export const findMap = <A, B>(
  as: readonly A[],
  f: (a: A, i: number) => B | undefined,
): B | undefined => {
  let i = 0;
  for (const a of as) {
    const b = f(a, i++);
    if (b !== undefined) return b;
  }
  return undefined;
};

export const countWhere = <A>(
  as: readonly A[],
  f: (a: A, i: number) => any,
): number => {
  let i = 0,
    n = 0;
  for (const a of as) {
    if (f(a, i++)) ++n;
  }
  return n;
};

export const findIndices = <A>(
  as: readonly A[],
  f: (a: A, i: number) => any,
): number[] => {
  const indices = new Array<number>();
  let i = 0;
  for (const a of as) {
    if (f(a, i)) indices.push(i);
    ++i;
  }
  return indices;
};

export const pickProp = <A, B extends keyof A>(
  as: readonly A[],
  b: B,
): Array<A[B]> => {
  return as.map((a) => a[b]);
};

export const uniqProp = <A, B extends keyof A>(
  as: readonly A[],
  b: B,
): Array<A[B]> => {
  return uniq(pickProp(as, b));
};

export const minProp = <A extends Record<any, any>, B extends keyof A>(
  as: readonly A[],
  b: B,
): A[B] | undefined => {
  let min: A[B] | undefined = undefined;
  for (const a of as) {
    const prop = a[b];
    if (min === undefined || prop < min) min = prop;
  }
  return min;
};

export const maxProp = <A extends Record<any, any>, B extends keyof A>(
  as: readonly A[],
  b: B,
): A[B] | undefined => {
  let max: A[B] | undefined = undefined;
  for (const a of as) {
    const prop = a[b];
    if (max === undefined || prop > max) max = prop;
  }
  return max;
};

export const minMaxProp = <A extends Record<any, any>, B extends keyof A>(
  as: readonly A[],
  b: B,
): [A[B], A[B]] | undefined => {
  let min: A[B] | undefined = undefined;
  let max: A[B] | undefined = undefined;
  for (const a of as) {
    const prop = a[b];
    if (min === undefined || prop < min) min = prop;
    if (max === undefined || prop > max) max = prop;
  }
  return min === undefined || max === undefined ? undefined : [min, max];
};

export const notNull = <A>(a: A | null): a is A => a != null;
