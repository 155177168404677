import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "reactstrap";

import {
  RealPageConfiguration,
  RealPageSyncType,
} from "../../../../services/integrationsService";
import { IntegrationFormProps } from "./common";
import RealPageFTPForm from "./RealPageFTPForm";
import RealPageWSForm from "./RealPageWSForm";

const RealPageForm: React.FC<IntegrationFormProps> = ({
  isCreate,
  validationError,
  setConfiguration,
  setConfigurationValidation,
  configuration,
}) => {
  const [type, setType] = useState<RealPageSyncType>();

  useEffect(() => {
    const config = configuration as RealPageConfiguration;
    setType(config.type);
    setConfiguration({ ...config, type: config.type });
  }, [isCreate, setConfiguration]);

  return (
    <>
      <Row>
        <Col>
          <Button
            block
            color="primary"
            outline
            active={type === "ftp"}
            size="lg"
            onClick={() => setType("ftp")}
          >
            FTP
          </Button>
        </Col>
        <Col>
          <Button
            block
            color="primary"
            outline
            active={type === "ws"}
            size="lg"
            onClick={() => setType("ws")}
          >
            Web Services
          </Button>
        </Col>
      </Row>
      <Row style={{ paddingTop: "1em" }}>
        <Col>
          {type === "ftp" && (
            <RealPageFTPForm
              isCreate={isCreate}
              setConfiguration={setConfiguration}
              setConfigurationValidation={setConfigurationValidation}
              validationError={validationError}
              configuration={configuration}
            />
          )}
          {type === "ws" && (
            <RealPageWSForm
              isCreate={isCreate}
              setConfiguration={setConfiguration}
              setConfigurationValidation={setConfigurationValidation}
              validationError={validationError}
              configuration={configuration}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default RealPageForm;
