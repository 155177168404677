import {
  faBolt,
  faPencilAlt,
  faPlus,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ColumnValue,
  PropertiesSelection,
  defaultSortedPropertyColumns,
  exhaustiveCheck,
  getCustomColumnName,
  isAllProperties,
  isPropertiesByColumns,
  isPropertiesByGroup,
  isPropertiesById,
  isPropertiesByIds,
  isPropertiesByMetadata,
  isPropertiesComparison,
} from "@joyhub-integration/shared";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { deleteRole, getRoleById } from "../../../services/rolesService";
import { expressionLabel } from "../../../services/selection";
import { Role, getRoles } from "../../../services/usersService";
import PlatformContext from "../../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ActionBar from "../../common/button/ActionBar";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";
import JhCrumbar from "../../navbar/JhCrumbar";
import DeleteModal from "../common/DeleteModal";
import AddEditRoleModal from "./AddEditRoleModal";

const RolesAdminPage: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const [roles, setRoles] = useState<Array<Role>>();
  const [addingRole, setAddingRole] = useState(false);
  const [editingRole, setEditingRole] = useState(false);
  const [deletingRole, setDeletingRole] = useState(false);
  const [selected, setSelected] = useState<Role>();
  const [loaded, setLoaded] = useState(false);
  const { organization, propertiesMap, propertyGroups } =
    useContext(PlatformContext).platform!;
  const { configuration } = organization;

  const attributeNames = useMemo(
    () =>
      Object.fromEntries(
        defaultSortedPropertyColumns(configuration.customColumns).map((col) => [
          col.columnKey,
          getCustomColumnName(col),
        ]),
      ),
    [configuration],
  );

  const fetchRoles = useCallback(async () => {
    return getRoles()
      .then((res) => {
        res.sort((a, b) => a.name.localeCompare(b.name));
        setRoles(res);
      })
      .catch(onUnexpectedError);
  }, [onUnexpectedError]);

  useEffect(() => {
    fetchRoles();
    setLoaded(true);
  }, [fetchRoles]);

  const onAddEditRoleModalSubmit = (role: Role) => {
    fetchRoles().then(() => {
      const message = addingRole
        ? `Role ${role.name} added successfully.`
        : `Role ${role.name} edited successfully.`;
      setAlert(message, true);
      setSelected(undefined);
      onAddEditRoleModalClose();
    });
  };

  const onAddEditRoleModalClose = () => {
    setAddingRole(false);
    setEditingRole(false);
  };

  const onDeleteRoleModalSubmit = (role: Role) => {
    fetchRoles().then(() => {
      setAlert(`Role ${role.name} deleted successfully`, true);
      setSelected(undefined);
      onDeleteRoleModalClose();
    });
  };

  const onDeleteRoleModalClose = () => {
    setDeletingRole(false);
  };

  const renderPermissionsSummary = (sel: PropertiesSelection | null) => {
    let summary;
    if (sel == null) {
      summary = "No properties";
    } else if (isAllProperties(sel)) {
      summary = "All properties";
    } else if (isPropertiesById(sel)) {
      const propertyName = propertiesMap[sel.toString()]?.property_name;
      summary = propertyName ?? "Unknown property";
    } else if (isPropertiesByIds(sel)) {
      summary = `${sel.ids.length} propert${sel.ids.length > 1 ? "ies" : "y"}`;
    } else if (isPropertiesByColumns(sel)) {
      summary = (
        <span>
          Properties with attributes
          <ul className="list-unstyled mb-0">
            {Object.entries(sel.columns).map(([key, values]) => (
              <li key={key}>
                <span className="text-muted me-1">{attributeNames[key]}:</span>
                {renderMetadataValues(values)}
              </li>
            ))}
          </ul>
        </span>
      );
    } else if (isPropertiesByGroup(sel)) {
      const groupName = propertyGroups.find((g) => g.id === sel.group)?.name;
      summary = `Properties in group ${groupName ?? "Unknown Group"}`;
    } else if (isPropertiesByMetadata(sel)) {
      summary = "Properties by metadata (unsupported)";
    } else if (isPropertiesComparison(sel)) {
      summary = "Invalid property comparison";
    } else {
      summary = exhaustiveCheck(sel);
    }

    return summary;
  };

  const renderMetadataValues = (values: ColumnValue[]) => {
    const nodes = values.map((value, i) => {
      if (typeof value === "string") {
        return <React.Fragment key={i}>{value}</React.Fragment>;
      } else {
        return (
          <React.Fragment key={i}>
            <FontAwesomeIcon icon={faBolt} className="me-1" />
            {expressionLabel(value)}
          </React.Fragment>
        );
      }
    });

    // poor man's intercalate
    if (nodes.length) {
      return nodes.flatMap((n) => [", ", n]).slice(1);
    } else {
      return nodes;
    }
  };

  const buttonProps = [
    {
      label: "Add Property Access",
      icon: faPlus,
      onClick: () => setAddingRole(true),
      className: "jh-btn-primary",
    },
    {
      icon: faPencilAlt,
      disabled: !selected,
      onClick: () => setEditingRole(true),
      tooltip: "Edit Role",
    },
    {
      icon: faTrash,
      disabled: !selected,
      onClick: () => setDeletingRole(true),
      tooltip: "Delete Role",
    },
  ];

  const tableCols: Array<KeyValue<Role>> = [
    {
      key: "name",
      title: "Name",
    },
    {
      key: "permissions",
      title: "Permissions",
      toValue: (role) => renderPermissionsSummary(role.properties_selection),
    },
  ];

  return (
    <LoadilyFadily loaded={loaded} className="jh-page-layout">
      <JhCrumbar
        primary="Administration"
        primaryPath="/admin"
        secondary="Manage Property Access"
      />
      <div className="jh-page-content admin-page">
        <ActionBar buttonProps={buttonProps} />
        <TableWithSelection<Role>
          selected={selected}
          onSelectedChange={setSelected}
          columns={tableCols}
          rows={roles}
        />
        {(addingRole || editingRole) && (
          <AddEditRoleModal
            id={editingRole && selected ? selected.id : undefined}
            onSubmit={onAddEditRoleModalSubmit}
            onClose={onAddEditRoleModalClose}
          />
        )}
        {deletingRole && selected && (
          <DeleteModal<Role>
            id={selected.id}
            entityName="Role"
            identificationKey="name"
            onSubmit={onDeleteRoleModalSubmit}
            onClose={onDeleteRoleModalClose}
            getEntity={getRoleById}
            deleteEntity={deleteRole}
          />
        )}
      </div>
    </LoadilyFadily>
  );
};

export default withAlertModal(RolesAdminPage);
