import { CardType } from "../../type";
import { cardItems } from "../../utils";
import { CardMenuItem } from "./CardMenuItem";

interface CardMenuPropsType {
  updateCardType: (value: CardType) => void;
}

export const CardMenu = (props: CardMenuPropsType) => {
  const { updateCardType } = props;

  return (
    <div className="d-flex flex-column  gap-3 p-3 w-100">
      {cardItems.map((item) => (
        <CardMenuItem key={item.title} item={item} onClick={updateCardType} />
      ))}
    </div>
  );
};
