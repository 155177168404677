import {
  GenericReportData,
  GenericReportDefinition,
  PureDate,
} from "@joyhub-integration/shared";
import { useEffect, useMemo, useState } from "react";
import { Alert, Card, Nav, NavItem, NavLink } from "reactstrap";
import { StringParam, useQueryParam } from "use-query-params";

import dashboardService from "../../services/dashboardService";
import {
  getActualReport,
  SingleReportResult,
} from "../../services/reportsService";
import { User } from "../../services/usersService";
import { usePropertiesSelectionQueryParam } from "../../utils/useQueryParams";
import withAlertModal, {
  WithAlertModalProps,
} from "../common/alert/withAlertModal";
import { LoadilyFadily } from "../common/allFadily";
import { getReportData } from "../craport/Craport";
import Table from "./table/Table";

const reportKey = "propertyOverview";

interface RegionalDirectorDashboardProps {
  person: User;
  date: Date | null;
  data?: GenericReportData;
  setData: (data: GenericReportData<boolean> | undefined) => void;
}

const RegionalDirectorDashboard = ({
  setAlert,
  onUnexpectedError,
  person,
  date,
  data,
  setData,
}: WithAlertModalProps & RegionalDirectorDashboardProps) => {
  const [selection] = usePropertiesSelectionQueryParam();
  const [sheetName, setSheet] = useQueryParam("sheet", StringParam);

  const [info, setInfo] = useState<SingleReportResult>();
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState<string>();

  const reportDefinition = info?.definition as GenericReportDefinition;
  const sheets = reportDefinition?.sheets ?? [];
  const sheetDefinition = sheets.find((s) => s.name === sheetName) ?? sheets[0];
  const sheet = sheetDefinition?.name;
  const sheetData = data?.sheets?.[0];

  useEffect(() => {
    getActualReport(reportKey)
      .then((report) => {
        setInfo(report);
        dashboardService.viewDashboard(report.id);
        try {
          window.analytics.track("Report Viewed", {
            reportName: report.name,
            reportId: report.id,
            userId: person.id,
          });
        } catch (e) {
          console.error("Unable to fire segment track event", e);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.status === 404) {
          setAlert(`No report found with identifier '${reportKey}'.`, false);
        } else {
          onUnexpectedError(err);
        }
      });
  }, [setAlert, onUnexpectedError, person]);

  const viewConfiguration = useMemo(() => {
    const asOf = PureDate.of(date ?? undefined);
    return { properties: selection ?? {}, asOf };
  }, [selection, date]);

  useEffect(() => {
    setData(undefined);
    setError(undefined);
    if (sheet != null) {
      setLoaded(false);
      getReportData(reportKey, { sheet, ...viewConfiguration })
        .then((data) => {
          setData(data);
          setLoaded(true);
        })
        .catch(() => {
          setError(`Error loading report: ${reportKey} (${sheet})`);
        });
    }
  }, [sheet, viewConfiguration, setData]);

  return (
    <div className="py-3 px-4">
      <div className="d-flex justify-content-between">
        <Nav pills className="d-print-none">
          {sheets.length > 1
            ? sheets.map((sheet, index) => {
                const active = sheet.name === sheetData?.name;
                return (
                  <NavItem key={index} active={active}>
                    <NavLink
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        setSheet(index === 0 ? undefined : sheet.name);
                      }}
                    >
                      {sheet.name}
                    </NavLink>
                  </NavItem>
                );
              })
            : null}
        </Nav>
      </div>
      {error && <Alert color="danger">{error}</Alert>}

      <Card className="p-4 jh-chart-content">
        <LoadilyFadily loaded={loaded || error !== undefined}>
          {sheetData && <Table data={sheetData} />}
        </LoadilyFadily>
      </Card>
    </div>
  );
};

export default withAlertModal(RegionalDirectorDashboard);
