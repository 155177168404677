import { FrontEndUnit } from "../services/insightsService";

export type CountOfTotal = { count: number; total: number };

export function epsilonZero(value: number) {
  return Math.abs(value) < 0.001;
}

export function epsilonToZero(value: number) {
  return epsilonZero(value) ? 0 : value;
}

export function safeDivide(count?: number, total?: number): number | undefined {
  if (total == null || count == null) {
    return undefined;
  } else if (total === 0) {
    return Number.NaN;
  } else if (count === 0) {
    return 0;
  }
  return count / total;
}

export function safeSubtract(
  minuend?: number,
  subtrahend?: number,
): number | undefined {
  if (minuend == null || subtrahend == null) return undefined;
  return minuend - subtrahend;
}

const percent = (n: CountOfTotal): number => {
  return 100.0 * safeDivide(n.count, n.total)!;
};

function formattedValue(
  n: number,
  delta: boolean,
  formatter: (n: number) => string,
): string {
  const zn = epsilonToZero(n);
  if (delta) {
    return (zn === 0 ? "" : zn > 0 ? "+" : "") + formatter(zn);
  } else {
    return formatter(zn);
  }
}

const percentageFormatter = (places: number) => (n: number) => {
  const floorN = Math.floor(n);
  if (epsilonZero(n - floorN)) {
    return floorN + "%";
  } else {
    return n.toFixed(places) + "%";
  }
};

export function toPercentage(
  n: CountOfTotal | undefined,
  delta: boolean = false,
  numberOfDecimal: number = 1,
): string {
  if (
    n === undefined ||
    !n.total ||
    Number.isNaN(n.count) ||
    Number.isNaN(n.total)
  ) {
    return "–";
  }
  const percentage = percent(n);
  return formattedValue(
    percentage,
    delta,
    percentageFormatter(numberOfDecimal),
  );
}

export function toPercentageNum(n: CountOfTotal): number {
  return parseFloat(percent(n).toFixed(1));
}

export function ratioToNumber(n: CountOfTotal, unit: FrontEndUnit): number {
  const fixedPoints = unit === "Dollar" ? 2 : 1;
  return parseFloat(safeDivide(n.count, n.total)!.toFixed(fixedPoints));
}

/*
 * if unit is percent, then safely divide `n`, multiply it by 100, and round it to 1 decimal place
 * if unit is not percent, then safely divide 'n' and round it to 1 or 2 decimal places depending on if unit is Dollar
 */
export function yetAnotherRatioToNumber(
  n: CountOfTotal,
  unit: FrontEndUnit,
): number {
  return unit === "Percent" ? toPercentageNum(n) : ratioToNumber(n, unit);
}

export function formatted(
  n: number | undefined,
  unit: FrontEndUnit = "Number",
  delta: boolean = false,
): string {
  if (n === undefined || Number.isNaN(n)) {
    return "–";
  }
  const unitFormatter =
    unit === "Dollar"
      ? formattedDollar
      : unit === "Month"
        ? formatMonths
        : unit === "Year"
          ? (n: number) => n.toFixed(0)
          : (n: number) => n.toLocaleString();
  return formattedValue(n, delta, unitFormatter);
}

export function formattedPercentage(
  n: number | undefined,
  delta: boolean = false,
): string {
  if (n === undefined || Number.isNaN(n)) {
    return "–";
  }
  return formattedValue(n, delta, percentageFormatter(1));
}

function formatDollarHelper(
  absoluteValue: number,
  kmb: boolean = false,
): string {
  // This is wrong, a column should define whether the values are whole or fractional. Otherwise lease
  // trade out varies between 0 and 2 decimal places. Really we just want Rent PSF to have decimals
  // and none of the others.
  if (absoluteValue < 100) {
    return absoluteValue.toFixed(2);
  } else {
    const rounded = Math.round(absoluteValue);
    if (kmb && rounded >= 1000000000) {
      return `${rounded / 1000000000}B`;
    } else if (kmb && rounded >= 1000000) {
      return `${rounded / 1000000}M`;
    } else if (kmb && rounded >= 10000) {
      return `${rounded / 1000}K`;
    } else {
      return rounded.toLocaleString();
    }
  }
}

export function formattedDollar(
  n: number | undefined,
  kmb: boolean = false,
): string {
  if (n === undefined || Number.isNaN(n)) {
    return "–";
  }
  const absValue = Math.abs(n);
  if (n < 0) {
    return `-$${formatDollarHelper(absValue, kmb)}`;
  } else {
    return `$${formatDollarHelper(n, kmb)}`;
  }
}

export function formatMonths(n: number | undefined) {
  if (n === undefined || Number.isNaN(n)) {
    return "–";
  }
  return `${n.toLocaleString("en-US", { maximumFractionDigits: 2 })}mo`;
}

// hours really makes no sense; measuring in anything less than whole
// days is junk
export function formatDays(n: number | undefined) {
  if (n === undefined || Number.isNaN(n)) {
    return "–";
  }
  const hours = Math.round(n * 24);
  const days = Math.round(n);
  if (hours > 0 && hours < 24) {
    return `${hours} Hours`;
  } else if (days === 1) {
    return "1 Day";
  } else {
    return `${days} Days`;
  }
}

export function kmbFormatted(n: number | undefined): string {
  if (n === undefined || Number.isNaN(n)) {
    return "–";
  }
  const absolute = Math.abs(n);
  if (absolute >= 1000000000) {
    return `${(n / 1000000000).toPrecision(4)}B`;
  } else if (absolute >= 1000000) {
    return `${(n / 1000000).toPrecision(4)}M`;
  } else {
    return n.toLocaleString();
  }
}

export function fromFormattedNumber(numStr: string): number {
  const clean = numStr.replace(/,|%|\$/g, "");
  return parseFloat(clean);
}

export function addCountOfTotal(
  a: CountOfTotal,
  b: CountOfTotal,
): CountOfTotal {
  return {
    count: a.count + b.count,
    total: a.total + b.total,
  };
}

export function subtractCountOfTotal(
  a: CountOfTotal,
  b: CountOfTotal,
): CountOfTotal {
  return {
    count: a.count * b.total - b.count * a.total,
    total: a.total * b.total,
  };
}

export function divideCountOfTotal(
  a: CountOfTotal,
  b: CountOfTotal,
): CountOfTotal {
  return {
    count: a.count * b.total,
    total: a.total * b.count,
  };
}

/**
 * For non percentage numbers only because percent difference for percentages
 * can just be calculated by subtracting those percentages
 * */
export function percentDifference(
  a: number | CountOfTotal,
  b: number | CountOfTotal,
): CountOfTotal {
  if (typeof a !== typeof b) {
    return {
      count: 0,
      total: 0,
    };
  } else if (typeof a === "number" && typeof b === "number") {
    return {
      count: a - b,
      total: b,
    };
  } else {
    const aCountOfTotal = a as CountOfTotal;
    const bCountOfTotal = b as CountOfTotal;
    const count = subtractCountOfTotal(aCountOfTotal, bCountOfTotal);
    return divideCountOfTotal(count, bCountOfTotal);
  }
}

export function compareCountOfTotal(
  a: CountOfTotal,
  b: CountOfTotal,
  multiplier: 1 | -1,
): number {
  const aQuotient = a.count / a.total;
  const bQuotient = b.count / b.total;
  return (aQuotient - bQuotient) * multiplier;
}

export const formatFileSize = (size: number) => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${parseFloat((size / 1024 ** i).toFixed(2))} ${
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][i]
  }`;
};

const FullDateAndTimeFmt = new Intl.DateTimeFormat("en-US", {
  dateStyle: "medium",
  timeStyle: "long",
});

export const formatFullDateAndTime = (date: string) => {
  return FullDateAndTimeFmt.format(new Date(date));
};
