import "./tableWithSelection.css";

import {
  faSortAmountDown,
  faSortAmountUp,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { compare as naturally } from "natural-orderby";
import { ReactNode, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Table } from "reactstrap";

const naturallyCompare = naturally();

interface Unselectable {
  id: number;
}

export interface KeyValue<T> {
  key: string; // keyof T;
  title: string;
  toValue?: (t: T, idx: number) => string | ReactNode;
  sortValue?: (t: T) => string | number | null | undefined;
  sortable?: boolean;
}

interface TableWithoutSelectionProps<T> {
  columns: Array<KeyValue<T>>;
  rows?: Array<T>;
  sortColumn?: string;
  sortDirection?: "asc" | "desc";
  moar?: () => void;
}

function TableWithoutSelection<T extends Unselectable>(
  props: TableWithoutSelectionProps<T>,
) {
  const [sortColumn, setSortColumn] = useState(
    Math.max(
      0,
      props.columns.findIndex((c) => c.key === props.sortColumn),
    ),
  );
  const [ascending, setSortAscending] = useState(
    props.sortDirection !== "desc",
  );

  function defaultCellValue(wor: T, col: KeyValue<T>): string {
    const row = wor as any;
    return row[col.key] !== undefined && row[col.key] !== null
      ? String(row[col.key])
      : "";
  }

  const sortClicked = (idx: number) => {
    if (sortColumn === idx) {
      setSortAscending(!ascending);
    } else {
      setSortColumn(idx);
      setSortAscending(true);
    }
  };

  const headers = props.columns.map((col, idx) => {
    const thProps =
      col.sortable === false
        ? {}
        : { onClick: () => sortClicked(idx), style: { cursor: "pointer" } };
    return (
      <th key={`col-${col.key}`} {...thProps}>
        {col.title}
        {idx === sortColumn ? (
          <FontAwesomeIcon
            icon={ascending ? faSortAmountUp : faSortAmountDown}
            size="sm"
            color="#666"
            style={{ marginLeft: ".33em" }}
            aria-label={ascending ? "Ascending" : "Descending"}
          />
        ) : null}
      </th>
    );
  });

  const toVal = (row: T) => {
    const col = props.columns[sortColumn];
    if (col.sortValue) {
      return col.sortValue(row);
    } else if (col.toValue) {
      const val = col.toValue(row, -1);
      if (typeof val === "string") return val; // could be react node
    }
    return defaultCellValue(row, col);
  };

  const sortedRows = props.rows?.sort((a, b) => {
    const av = toVal(a),
      bv = toVal(b);
    let cmp: number;
    if (av == null) {
      if (bv == null) cmp = 0;
      else cmp = -1;
    } else if (bv == null) {
      cmp = 1;
    } else if (typeof av === "number" && typeof bv === "number") {
      cmp = av - bv;
    } else {
      cmp = naturallyCompare(av, bv);
    }
    if (cmp === 0) cmp = a.id - b.id;
    return ascending ? cmp : -cmp;
  });

  const rows = sortedRows?.map((row) => (
    <tr className={"jh-table-without-selection-row"} key={row.id}>
      {props.columns.map((col, idx) => (
        <td key={`cell-${col.key}-${row.id}`}>
          {col.toValue ? col.toValue(row, idx) : defaultCellValue(row, col)}
        </td>
      ))}
    </tr>
  ));
  const noResult = (
    <tr>
      <td
        className="jh-table-with-selection-no-results"
        colSpan={props.columns.length + 1}
      >
        {rows === undefined ? "Loading..." : "No Results"}
      </td>
    </tr>
  );

  return (
    <div className="jh-table-wrapper">
      <Table className="jh-with-selection-table">
        <thead>
          <tr>{headers}</tr>
        </thead>
        <tbody>
          {rows?.length ? rows : noResult}
          {props.moar ? (
            <tr>
              <td colSpan={props.columns.length} style={{ padding: 0 }}>
                <VisibilitySensor
                  onChange={(visible: boolean) => visible && props.moar?.()}
                >
                  <div style={{ height: "4px" }} />
                </VisibilitySensor>
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
    </div>
  );
}

export default TableWithoutSelection;
