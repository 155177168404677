import {
  faCogs,
  faDatabase,
  faDownload,
  faFolderTree,
  faRedo,
} from "@fortawesome/pro-light-svg-icons";
import {
  CauseEntity,
  dateStr,
  durationOf,
  notNull,
} from "@joyhub-integration/shared";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { keyBy } from "lodash";
import { getCauses } from "../../../services/causeService";
import {
  getIntegrationById,
  getSyncHistory,
  Integration,
  reingestSyncFiles,
  syncDownloadUrl,
  SyncHistoryEntity,
} from "../../../services/integrationsService";
import { dateOf } from "../../../utils/date";
import PlatformContext from "../../app/PlatformContext";
import withAlertModal, {
  WithAlertModalProps,
} from "../../common/alert/withAlertModal";
import { LoadilyFadily } from "../../common/allFadily";
import ActionBar from "../../common/button/ActionBar";
import { ButtonWithIconProps } from "../../common/button/ButtonWithIcon";
import TableWithSelection, {
  KeyValue,
} from "../../common/table/TableWithSelection";
import JhCrumbar from "../../navbar/JhCrumbar";
import { tooltippedCause } from "./ComputeHistoryPage";

type SyncHistoryParams = {
  id: string;
};

const SyncHistoryPage: React.FC<WithAlertModalProps> = ({
  setAlert,
  onUnexpectedError,
}) => {
  const params = useParams<SyncHistoryParams>();
  const [syncHistory, setSyncHistory] = useState<Array<SyncHistoryEntity>>();
  const [causes, setCauses] = useState<Record<number, CauseEntity>>({});
  const [selected, setSelected] = useState<SyncHistoryEntity>();
  const [integration, setIntegration] = useState<Integration>();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const systemId = parseInt(params.id!);
  const { admin, organization, superAdmin } =
    useContext(PlatformContext).platform!;
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    Promise.all([getSyncHistory(systemId), getIntegrationById(systemId)])
      .then(([history, integration]) => {
        setSyncHistory(history);
        setIntegration(integration);
        return admin
          ? getCauses(history.map((h) => h.cause_id).filter(notNull))
          : [];
      })
      .then((newCauses) => {
        setCauses(keyBy(newCauses, "id"));
      })
      .catch(onUnexpectedError)
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  }, [systemId, onUnexpectedError, refresh]);

  const buttonProps: ButtonWithIconProps[] = [
    {
      label: "File List",
      icon: faFolderTree,
      onClick: () => navigate(`files/${selected!.id}`),
      className: "jh-btn-primary",
      disabled: !selected,
    },
    {
      label: "Download Files",
      icon: faDownload,
      href: selected ? `${syncDownloadUrl(systemId, selected!.id)}` : undefined,
      className: "jh-btn-primary",
      disabled: !selected,
      download: true,
    },
    ...(!superAdmin
      ? []
      : [
          {
            label: "Reingest Failing Files",
            icon: faCogs,
            onClick: () =>
              reingestSyncFiles(systemId, selected!.id)
                .then(() => setAlert("Ingest started", true))
                .catch(onUnexpectedError),
            className: "jh-btn-primary",
            disabled: !selected,
            download: true,
          },
        ]),
  ];

  const rightButtonProps: ButtonWithIconProps[] = [
    {
      icon: faRedo,
      onClick: () => setRefresh((r) => 1 + r),
      outline: true,
      tooltip: "Refresh",
      disabled: loading,
    },
    {
      label: "Logs",
      icon: faDatabase,
      outline: true,
      onClick: () => navigate(`ecsSync`),
      tooltip: "Database Restore Events",
    },
  ];

  const causeCols: Array<KeyValue<SyncHistoryEntity>> = admin
    ? [
        {
          key: "cause",
          title: "Cause",
          // PROBLEM: I'm pretty sure this is an update fail because causes
          // gets locked in here, or something.  It didn't work in
          // ComputeHistoryPage anyway, but this page doesn't ever update so
          // maybe it doesn't matter.
          toValue: ({ id, cause_id }) => tooltippedCause(id, causes[cause_id]),
        },
      ]
    : [];
  const tableCols: Array<KeyValue<SyncHistoryEntity>> = [
    {
      key: "started",
      title: "Started",
      toValue: (s) => dateStr(s.started),
      sortValue: (s) => dateOf(s.started)?.getTime(),
    },
    {
      key: "finished",
      title: "Duration",
      toValue: (s) => durationOf(s.started, s.finished),
    },
    {
      key: "dataDate",
      title: "Data from",
      toValue: (s) => s.data_date, // ISO, could be localised
    },
    { key: "success_count", title: "Sync Successes" },
    { key: "failure_count", title: "Sync Failures" },
    { key: "file_success_count", title: "Import Successes" },
    { key: "file_failure_count", title: "Import Failures" },
    ...causeCols,
  ];

  return (
    <LoadilyFadily loaded={loaded} className="jh-page-layout">
      <JhCrumbar
        back
        primary="Manage Integrations"
        primaryPath="/admin/integrations"
        secondary={`"${integration?.name ?? "?"}" Sync History for ${
          organization?.name ?? "Unknown org"
        } (${integration?.vendor ?? "?"})`}
      />
      <div className="jh-page-content sync-history-admin-page admin-page">
        <ActionBar
          buttonProps={buttonProps}
          rightButtonProps={rightButtonProps}
        />
        <TableWithSelection<SyncHistoryEntity>
          selected={selected}
          onSelectedChange={(selected) => setSelected(selected)}
          columns={tableCols}
          rows={syncHistory}
          sortColumn="started"
          sortDirection="desc"
        />
      </div>
    </LoadilyFadily>
  );
};

export default withAlertModal(SyncHistoryPage);
