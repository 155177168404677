import React from "react";

import PortfolioOverviewCard from "../../components/common/portfolioOverview/PortfolioOverviewCard";
import EmbeddedReport from "../../components/craport/EmbeddedReport";
import EmailInsightCard from "../../components/EmailInsightCard";
import GenericTableChart from "../../components/genericTableChart/GenericTableChart";
import HtmlInsightCard from "../../components/HtmlInsightCard";
import IframeInsightCard from "../../components/IframeInsightCard";
import ImageCard from "../../components/ImageCard";
import { IndependentImageInsightCard } from "../../components/IndependentImageInsightCard";
import PerformanceVsGoals from "../../components/performanceVsGoals/PerformanceVsGoals";
import PropertyInformation from "../../components/PropertyInformation";
import PropertyOverview from "../../components/propertyOverview/PropertyOverview";
import UnicardZombie from "../../components/unicard/UnicardZombie";
import UnitMapCard from "../../components/unitMap/UnitMapCard";
import WeeklySummary from "../../components/weeklySummary/WeeklySummary";
import { InsightCustomComponentProps } from "../models";
import {
  EmailInsightId,
  EmbeddedReportId,
  HtmlInsightId,
  IframeInsightId,
  ImageInsightId,
  IndependentImageInsightId,
  PropertyInformationInsightId,
  UnicardInsightId,
} from "./insightDefinitions";

export const insightComponents: Record<
  number,
  React.FC<InsightCustomComponentProps & any>
> = {
  [IframeInsightId]: IframeInsightCard,
  [EmailInsightId]: EmailInsightCard,
  [IndependentImageInsightId]: IndependentImageInsightCard,
  [HtmlInsightId]: HtmlInsightCard,
  [ImageInsightId]: ImageCard,
  [UnicardInsightId]: UnicardZombie,
  [PropertyInformationInsightId]: PropertyInformation,
  [EmbeddedReportId]: EmbeddedReport,
  44: GenericTableChart,
  55: PortfolioOverviewCard,
  56: PerformanceVsGoals,
  57: WeeklySummary,
  58: PropertyOverview,
  62: GenericTableChart,
  64: UnitMapCard,
};
