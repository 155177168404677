import {
  AccountTreeEntity,
  FinanceBook,
  financeBookNames,
  findMap,
} from "@joyhub-integration/shared";
import React, { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { Button, ButtonGroup, FormGroup, Input, Label } from "reactstrap";

import {
  Integration,
  getIntegrations,
} from "../../../services/integrationsService";
import { useOnUnexpectedError } from "../../common/alert/withAlertModal";
import { jhItem } from "../../common/JhSelect/JhSelect";
import { reactSelectBootstrapStyle } from "../../craport/edit/GenericReportSheetSettings";
import { findOption } from "../../craport/editUtil";
import { getAllGlTrees } from "../../finance/financeService";

export type AddFinancialImportState = {
  tree_code: string;
  book: FinanceBook;
  file: File;
  description: string;
};

const AddFinancialImportForm: React.FC<{
  setState: (state: AddFinancialImportState) => void;
}> = ({ setState }) => {
  const onUnexpectedError = useOnUnexpectedError();
  const [trees, setTrees] = useState<AccountTreeEntity[]>();
  const [integrations, setIntegrations] = useState<Integration[]>();
  const [tree, setTree] = useState<string>();
  const [description, setDescription] = useState("");
  const [book, setBook] = useState<FinanceBook>(FinanceBook.Accrual);
  const [file, setFile] = useState<File>();

  // load all gl trees
  useEffect(() => {
    getAllGlTrees().then(setTrees).catch(onUnexpectedError);
  }, [onUnexpectedError]);

  useEffect(() => {
    getIntegrations().then(setIntegrations).catch(onUnexpectedError);
  }, [onUnexpectedError]);

  // when we have the trees, pick the configured actuals tree, ysi_is, or the first tree
  useEffect(() => {
    if (trees && integrations) {
      // Find any integration that has a configured actuals tree. This is kinda wrong for people
      // with multiple integrations that have GL, we have to figure out how to support unified GL
      // account trees.
      const systemTree =
        findMap(integrations, (i) => i.insight_config.actual?.tree) ?? "ysi_is";
      setTree(
        (trees.find((t) => t.tree_code === systemTree) ?? trees[0])?.tree_code!,
      );
    }
  }, [trees, integrations]);

  // when all options are set, tell the modal
  useEffect(() => {
    if (tree != null && file != null)
      setState({
        tree_code: tree,
        book,
        file,
        description: description || file.name.replace(/^.*\//, ""),
      });
  }, [tree, book, file, description, setState]);

  // memoize the tree options
  const treeOptions = useMemo(
    () =>
      (
        trees?.map((tree) =>
          jhItem(tree.tree_code!, `${tree.tree_name} (${tree.tree_code})`),
        ) ?? []
      ).concat(
        integrations?.map((system) =>
          jhItem(`common:${system.id}`, `Common Financials – ${system.name}`),
        ) ?? [],
      ),
    [trees, integrations],
  );

  return (
    <>
      <FormGroup>
        <Label>Financial Book</Label>
        <ButtonGroup className="w-100">
          {Object.entries(financeBookNames).map(([id, name]) => (
            <Button
              key={id}
              active={book === parseInt(id)}
              outline={book !== parseInt(id)}
              onClick={() => setBook(parseInt(id) as FinanceBook)}
            >
              {name}
            </Button>
          ))}
        </ButtonGroup>
      </FormGroup>
      <FormGroup>
        <Label>Account Tree</Label>
        <Select
          id="account-tree"
          options={treeOptions}
          value={findOption(treeOptions, tree)}
          onChange={(option) => setTree(option!.value)}
          isSearchable={true}
          isDisabled={trees == null}
          styles={reactSelectBootstrapStyle}
        />
      </FormGroup>
      <FormGroup>
        <Label>Financial Statement</Label>
        <Input
          id="import-file"
          type="file"
          accept=".xlsx,.csv"
          onChange={(e) => setFile(e.target.files?.[0])}
        />
      </FormGroup>
      <FormGroup>
        <Label>Description</Label>
        <Input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder={file?.name}
        />
      </FormGroup>
    </>
  );
};

export default AddFinancialImportForm;
