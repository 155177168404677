import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  Label,
} from "reactstrap";

import {
  FTPProtocols,
  RealPageFTPConfiguration,
} from "../../../../services/integrationsService";
import { getOrganizationsForPropertyConnect } from "../../../../services/organizationService";
import { Platform } from "../../../../services/platformService";
import { Organization } from "../../../../services/usersService";
import PlatformContext from "../../../app/PlatformContext";
import { IntegrationFormProps, ValidationError } from "./common";

const RealPageFTPForm: React.FC<IntegrationFormProps> = (props) => {
  const [realPageConfig, setRealPageConfig] =
    useState<RealPageFTPConfiguration>({
      type: "ftp",
      protocol: "FTP",
      server: "",
      username: "",
      path: "",
      delete: false,
      ignoreCsv: true,
    });
  const [propertyConnectOrganizations, setPropertyConnectOrganizations] =
    useState<Organization[]>();
  const { isCreate, validationError, setConfigurationValidation } = props;
  const platform = useContext(PlatformContext).platform as Platform;

  const validate = useCallback(
    (
      newConfig: RealPageFTPConfiguration,
      isCreate: boolean,
    ): ValidationError => {
      const isPcApp = platform.organization.application === "PC";
      if (isPcApp && !newConfig.client) {
        return { message: "Client is required.", field: "client" };
      }
      return {};
    },
    [platform.organization.application],
  );

  useEffect(() => {
    setConfigurationValidation(validate(realPageConfig, isCreate));
  }, [realPageConfig, setConfigurationValidation, isCreate, validate]);

  useEffect(() => {
    if (props.isCreate) {
      props.setConfiguration(realPageConfig);
    } else if (props.configuration && Object.keys(props.configuration).length) {
      const config = props.configuration as RealPageFTPConfiguration;
      config.type = "ftp";
      setRealPageConfig({ ...realPageConfig, ...config });
    }
  }, [props.configuration]);

  useEffect(() => {
    const isPcApp = platform.organization.application === "PC";
    if (isPcApp) {
      getOrganizationsForPropertyConnect().then((organizations) => {
        setPropertyConnectOrganizations(organizations);
      });
    }
  }, [platform.organization.application]);

  function onConfigChange(
    e: ChangeEvent<HTMLInputElement>,
    key: keyof RealPageFTPConfiguration,
  ) {
    const newConfig = {
      ...realPageConfig,
      [key]: e.target.value,
    };
    setRealPageConfig(newConfig);
    props.setConfiguration(newConfig);
  }

  return (
    <>
      <FormGroup>
        <Label>FTP Server</Label>
        <InputGroup>
          <Input
            type="text"
            value={realPageConfig.server}
            onChange={(e) => onConfigChange(e, "server")}
            invalid={validationError.field === "server"}
            autoComplete="off"
          />
          <Input
            type="select"
            className="custom-select"
            onChange={(e) => onConfigChange(e, "protocol")}
            value={realPageConfig.protocol}
          >
            {FTPProtocols.map((protocol) => (
              <option key={protocol} value={protocol}>
                {protocol}
              </option>
            ))}
          </Input>
          {validationError.field === "server" && validationError.message ? (
            <FormFeedback>{validationError.message}</FormFeedback>
          ) : null}
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <Label>Username</Label>
        <Input
          type="text"
          value={realPageConfig.username}
          onChange={(e) => onConfigChange(e, "username")}
          invalid={validationError.field === "username"}
          autoComplete="off"
        />
        {validationError.field === "username" && validationError.message ? (
          <FormFeedback>{validationError.message}</FormFeedback>
        ) : null}
      </FormGroup>
      <FormGroup>
        <Label>Password</Label>
        <Input
          type="text"
          value={realPageConfig.secret}
          onChange={(e) => onConfigChange(e, "secret")}
          invalid={validationError.field === "password"}
          autoComplete="off"
          className="secret-blur"
        />
        {validationError.field === "password" && validationError.message ? (
          <FormFeedback>{validationError.message}</FormFeedback>
        ) : null}
        <FormText color="muted">
          * Credentials stored securely in AWS Secrets Manager.
        </FormText>
      </FormGroup>
      <FormGroup>
        <Label>Path to files</Label>
        <Input
          type="text"
          value={realPageConfig.path}
          onChange={(e) => onConfigChange(e, "path")}
          invalid={validationError.field === "path"}
          autoComplete="off"
        />
        {validationError.field === "path" && validationError.message ? (
          <FormFeedback>{validationError.message}</FormFeedback>
        ) : null}
      </FormGroup>
      <FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              checked={realPageConfig.delete}
              onChange={() => {
                const newConfig = {
                  ...realPageConfig,
                  delete: !realPageConfig.delete,
                };
                setRealPageConfig(newConfig);
                props.setConfiguration(newConfig);
              }}
            />{" "}
            Delete files after retrieval
          </Label>
        </FormGroup>
      </FormGroup>
      <FormGroup>
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              checked={realPageConfig.ignoreCsv}
              onChange={() => {
                const newConfig = {
                  ...realPageConfig,
                  ignoreCsv: !realPageConfig.ignoreCsv,
                };
                setRealPageConfig(newConfig);
                props.setConfiguration(newConfig);
              }}
            />{" "}
            Ignore CSV files
          </Label>
        </FormGroup>
      </FormGroup>
      {propertyConnectOrganizations && propertyConnectOrganizations.length ? (
        <FormGroup>
          <Label>Revolution RE Client</Label>
          <Input
            type="select"
            className="custom-select"
            value={realPageConfig.client}
            onChange={(e) => {
              const client = e.target.value
                ? parseInt(e.target.value, 10)
                : undefined;
              setRealPageConfig({ ...realPageConfig, client: client });
            }}
          >
            <option value=""></option>
            {propertyConnectOrganizations.map((org) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
          </Input>
          {validationError.field === "client" && validationError.message ? (
            <FormFeedback className="jh-block">
              {validationError.message}
            </FormFeedback>
          ) : null}
        </FormGroup>
      ) : null}
    </>
  );
};

export default RealPageFTPForm;
