import "./unsubscribe.css";

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useLocation } from "react-router-dom";
import { Alert, Button, Navbar, NavbarBrand } from "reactstrap";

import { ReactComponent as Logo } from "../../images/logo-w.svg";
import {
  EmailInfo,
  getEmailInfo,
  setEmailOptOut,
  setEmailUnsubscribed,
} from "../../services/emailService";
import { environment } from "../../services/platformService";
import { apiUrl } from "../../utils/api";
import Loading from "../app/Loading";

const UnsubscribeNav: React.FC<{
  guid: string;
  name: string;
  theme: string;
  logoType?: string;
}> = ({ guid, name, theme, logoType }) => {
  return (
    <Navbar
      className="jh-navbar"
      color={theme}
      dark={theme === "dark"}
      light={theme === "light"}
      expand="lg"
    >
      <NavbarBrand tag={NavLink} to="/" className="jh-navbar-brand">
        {logoType ? (
          <img src={apiUrl(`/emails/${guid}/logo.${logoType}`)} alt={name} />
        ) : (
          <Logo />
        )}
      </NavbarBrand>
      {environment !== "Prod" ? (
        <div className={`env-sash env-${environment.toLowerCase()}`}>
          <div className="inner">{environment}</div>
        </div>
      ) : null}
    </Navbar>
  );
};

const Unsubscribe: React.FC = () => {
  const [unknown, setUnknown] = useState(false);
  const [error, setError] = useState(false);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [optedOut, setOptedOut] = useState(false);
  const [emailInfo, setEmailInfo] = useState<EmailInfo>();
  const location = useLocation();
  const guid = location.pathname.replace(/.*\//, "");
  useEffect(() => {
    getEmailInfo(guid)
      .then(setEmailInfo)
      .catch(() => setUnknown(true));
  }, [guid]);
  const unsubscribeFrom = (unsubscribe: boolean) => () =>
    setEmailUnsubscribed(guid, unsubscribe)
      .then(() => setUnsubscribed(true))
      .catch(() => setError(true));
  const optOutFromAll = (optOut: boolean) => () =>
    setEmailOptOut(guid, optOut)
      .then(() => setOptedOut(true))
      .catch(() => setError(true));
  const title = `${emailInfo?.organization.name} - Revolution RE`;
  return (
    <>
      {unknown ? (
        <div className="unsubscribe-main">
          <p>The link you followed was not recognized by this system.</p>
        </div>
      ) : !emailInfo ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <UnsubscribeNav
            guid={guid}
            name={emailInfo.organization.name}
            theme={emailInfo.organization.configuration.navbarTheme ?? "dark"}
            logoType={emailInfo.organization.configuration.logo?.replace(
              /.*\./,
              "",
            )}
          />
          <div className="unsubscribe-main">
            {error && (
              <Alert color="danger">An unexpected error occurred.</Alert>
            )}
            <h1>{title}</h1>

            {emailInfo.emailAddress.opt_out ? (
              <>
                <h3 className="pt-4">Already unsubscribed</h3>
                <p className="pt-3">
                  Your email address
                  <span className="email">
                    {" "}
                    {emailInfo.emailAddress.email}{" "}
                  </span>
                  has already been unsubscribed from this system.
                </p>
                <h4 className="pt-3">Resubscribe to Emails</h4>
                <p>
                  If you wish to resubscribe to the system and start receiving
                  emails again then please click the Resubscribe button below.
                </p>
                <p>
                  <Button
                    color="primary"
                    onClick={optOutFromAll(false)}
                    disabled={optedOut}
                  >
                    {optedOut ? "You have been resubscribed" : "Resubscribe"}
                  </Button>
                </p>
              </>
            ) : (
              <>
                <h3 className="pt-4">Unsubscribe</h3>
                <p className="pt-3">
                  You have followed a link to unsubscribe your email address
                  <span className="email">
                    {" "}
                    {emailInfo.emailAddress.email}{" "}
                  </span>{" "}
                  from emails delivered by this system. If this is not your
                  email address then please ignore this process, the email was
                  probably forwarded to you.
                </p>
                {emailInfo.subscribed ? (
                  <>
                    <h4 className="pt-3">
                      Unsubscribe from Report / Dashboard
                    </h4>
                    <p>
                      If you wish to unsubscribe from just this one report /
                      dashboard, then please click the Unsubscribe from Report /
                      Dashboard button below.
                    </p>
                    <p>
                      <Button
                        color="primary"
                        onClick={unsubscribeFrom(true)}
                        disabled={unsubscribed}
                      >
                        {unsubscribed
                          ? "You have been unsubscribed"
                          : "Unsubscribe from Report / Dashboard"}
                      </Button>
                    </p>
                  </>
                ) : emailInfo.report ? (
                  <>
                    <h4 className="pt-3">Resubscribe to Report / Dashboard</h4>
                    <p>
                      You have been unsubscribed from this report / dashboard.
                      If you wish to resubscribe then please click the
                      Resubscribe to Report / Dashboard button below.
                    </p>
                    <p>
                      <Button
                        color="primary"
                        onClick={unsubscribeFrom(false)}
                        disabled={unsubscribed}
                      >
                        {unsubscribed
                          ? "You have been resubscribed"
                          : "Resubscribe to Report / Dashboard"}
                      </Button>
                    </p>
                  </>
                ) : null}
                <h4 className="pt-3">Unsubscribe from All Emails</h4>
                <p>
                  If you wish to unsubscribe from all emails sent by this system
                  then please click the Unsubscribe from All Emails button
                  below.
                </p>
                <p>
                  <Button
                    color="primary"
                    onClick={optOutFromAll(true)}
                    disabled={optedOut}
                  >
                    {optedOut
                      ? "You have been unsubscribed"
                      : "Unsubscribe from All Emails"}
                  </Button>
                </p>
              </>
            )}
            <p className="pt-4">
              If you have any questions please contact us at{" "}
              <a href="mailto:support@revolutionre.com" className="email">
                support@revolutionre.com
              </a>
              .
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default Unsubscribe;
